import { FunctionComponent, useMemo, useState } from "react";
import { LicenseAssessmentReport, LicenseAssessmentResponse } from "../../../models/licenseAssessment";
import { AssessmentLine, ProjectAssessmentMeta } from "./projectAssessment";

export interface ProjectConfigurationProps {
  groupedAssessments: { [key: string]: LicenseAssessmentResponse[] };
  selectedModel?: string;
  licenseAssessmentReports: LicenseAssessmentReport[];
  selectedAssessments: LicenseAssessmentResponse[];
  selectedReports: LicenseAssessmentReport[];

  projectAssessmentMeta?: { [key: Uuid]: ProjectAssessmentMeta | undefined };
  onMetaDataChange?: (value: { [key: Uuid]: ProjectAssessmentMeta | undefined }) => void;

  onSelectedAssessmentsChange: (assessments?: LicenseAssessmentResponse[]) => void;
  onSelectedReportsChange: (reports?: LicenseAssessmentReport[]) => void;
}

export const ProjectConfiguration: FunctionComponent<ProjectConfigurationProps> = ({
  groupedAssessments,
  selectedModel,
  licenseAssessmentReports,
  selectedAssessments,
  projectAssessmentMeta,
  selectedReports,
  onMetaDataChange,
  onSelectedAssessmentsChange,
  onSelectedReportsChange,
}) => {

  return (
    <div>
      <div className="text-content-regular mt-14 p-5">
        Activez les évaluations et les rapports souhaités qui seront envoyés aux répondants
      </div>
      {/* should create new components */}
      <div className="p-5 rounded-3xl flex flex-col gap-5">
        {Object.entries(groupedAssessments).map(([model, assessments]) => (
          <div className={selectedModel && selectedModel !== model ? "opacity-30 pointer-events-none" : undefined}>
            <div className="flex justify-center items-center mb-2">
              <div className="w-full border-b border-border-lighter"></div>
              <div className="min-w-max px-4">{model}</div>
              <div className="w-full border-b border-border-lighter"></div>
            </div>
            {
              assessments.map((assess) =>
                <AssessmentLine
                  key={assess.id}
                  assessment={assess}
                  selectedAssessments={selectedAssessments}
                  onMetaDataChange={(v) => onMetaDataChange?.({ ...(projectAssessmentMeta || {}), [assess.id]: v })}
                  projectAssessmentMeta={projectAssessmentMeta?.[assess.id]}
                  onSelectedChange={(v) => {
                    if (v) {
                      onSelectedAssessmentsChange([assess, ...(selectedAssessments || [])]);
                    } else {
                      onSelectedAssessmentsChange(selectedAssessments?.filter((a) => a.id !== assess.id));
                      onSelectedReportsChange(selectedReports?.filter((r) => r.license_assessment_id !== assess.id));
                    }
                  }}
                  selectedReports={selectedReports}
                  onSelectedReportChange={(report, v) => {
                    if (v) {
                      onSelectedReportsChange([report, ...(selectedReports || [])]);
                    } else {
                      onSelectedReportsChange(selectedReports?.filter((r) => r.id !== report.id));
                    }
                  }}
                  reports={licenseAssessmentReports}
                />
              )
            }
          </div>
        ))}
      </div>
    </div >
  );
};
