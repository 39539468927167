import { Button } from "@getprorecrutement/getpro-design";
import { FunctionComponent } from "react";
import store, { Stored } from "../services/store";

export const ImpersonateIndicator: FunctionComponent<{}> = () => {
  const logout = () => {
    store.update(Stored.ImpersonatedJWT, undefined);
    store.update(Stored.RawImpersonatedJWT, undefined);
    window.location.reload();
  };

  return (
    <div className="fixed md:top-3 bottom-5 h-fit w-full flex justify-center items-center text-white md:text-content-darker pointer-events-none z-20">
      <div className="md:w-fit w-3/4 flex justify-between md:bg-white bg-background-darker px-5 py-2.5 rounded-lg gap-10 pointer-events-auto">
        <div className="flex items-center gap-2">
          <div className="hidden md:block h-2 w-2 rounded-full bg-success-medium"></div>
          <div>
            Vous êtes connecté en tant que{" "}
            <span className="font-bold">
              {store.state.ImpersonatedJWT?.first_name} {store.state.ImpersonatedJWT?.last_name}
            </span>
          </div>
        </div>
        <Button title="Se déconnecter" className="text-red-500 p-0" kind="light" onClick={logout} />
      </div>
    </div>
  );
};
