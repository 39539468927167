import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export interface StoredFile {
  id: Uuid;
  name: string;
}

export const createFile = (file: File) =>
  httpClient.req(ROUTES.CREATE_FILE(file)).then((res) => {
    toast.success("Fichier enregistré avec succès");
    return res;
  });
export const deleteFile = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_FILE(id)).then((res) => {
    toast.success("Fichier supprimé avec succès");
    return res;
  });
