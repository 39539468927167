import { DraggablePortal, ModalPortal, SelectPortal } from "@getprorecrutement/getpro-design";
import * as Sentry from "@sentry/react";
import "@getprorecrutement/getpro-design/dist/getpro-design.cjs.production.min.css";

import { BrowserTracing } from "@sentry/tracing";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import "./index.css";
import { ImpersonateIndicator } from "./modules/impersonateIndicator";
import { Layout } from "./modules/layout";
import { NavigationHeader } from "./modules/navigationHeader";
import { AssessmentInfosPage } from "./pages/assessments/infos";
import { ProjectPage as Project } from "./pages/projectPage";
import { Home } from "./pages/home";
import { LicensePage } from "./pages/licensePage";
import { LicensesPage } from "./pages/licensesPage";
import { LoginPage } from "./pages/login";
import { ModelsPage } from "./pages/modelsPage";
import { ResetPasswordPage } from "./pages/resetPassword";
import { UsersPage } from "./pages/usersPage";
import store, { Message, Stored } from "./services/store";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { ReportsPage } from "./pages/reportsPage";
import { ReportPage } from "./pages/reportPage";
import { ParticipantsPage } from "./pages/participantsPage";
import { ParticipantPage } from "./pages/participantPage";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale("fr");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.STAGING ? "staging" : process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["localhost", "ed209.getpro.co"],
      }),
    ],
    release: process.env.npm_package_version,
    tracesSampleRate: 1,
    attachStacktrace: true,
  });
}

const AdminRoute = ({ page }: { page: JSX.Element }) => {
  let jwt = store.state.ImpersonatedJWT || store.state.JWT;
  if (jwt && !jwt.admin) {
    return <Navigate to="/" replace />;
  } else {
    return page;
  }
};

function App() {
  const [authed, setAuthed] = useState<boolean>(!!store.state.RefreshToken);
  const [impersonating, setImpersonating] = useState<boolean>(!!store.state.ImpersonatedJWT);

  useEffect(() => store.listen(Message.NeedAuth, () => setAuthed(false)), []);
  useEffect(() => store.listen(Stored.RefreshToken, (e) => setAuthed(!!e)), []);
  useEffect(() => store.listen(Stored.ImpersonatedJWT, (e) => setImpersonating(!!e)));

  if (!authed)
    return (
      <Layout>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/reset_password/:token" element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </Layout>
    );

  return (
    <Layout>
      <Router>
        {impersonating && <ImpersonateIndicator />}
        <div className="flex flex-col h-full">
          <div>
            <NavigationHeader />
          </div>
          <div className="flex-grow">
            <Routes>
              <Route path="/assessments" element={<AdminRoute page={<ModelsPage />} />} />,
              <Route path="/assessments/:id" element={<AdminRoute page={<AssessmentInfosPage />} />} />,
              <Route path="/licenses" element={<AdminRoute page={<LicensesPage />} />} />,
              <Route path="/licenses/:id" element={<AdminRoute page={<LicensePage />} />} />,
              <Route path="/users" element={<UsersPage />} />
              <Route path="/participants" element={<ParticipantsPage />} />
              <Route path="/participants/:id" element={<ParticipantPage />} />
              <Route path="/reports" element={<AdminRoute page={<ReportsPage />} />} />
              <Route path="/reports/:id" element={<AdminRoute page={<ReportPage />} />} />
              <Route path="/projects/:id" element={<Project />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
      </Router>
    </Layout>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <div className="h-full">
    <ModalPortal />
    <DraggablePortal />
    <SelectPortal />
    <Toaster position="top-center" reverseOrder={false} />
    <App />
  </div>
);
