import httpClient, { loadable } from "../services/httpClient";
import { TextInput, Button } from "@getprorecrutement/getpro-design";
import ROUTES from "../services/routes";
import { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

export const ResetPasswordPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const resetPassword = (e: FormEvent) => {
    if (password && token) {
      e.preventDefault();
      loadable(
        httpClient.req(ROUTES.RESET_PASSWORD(token, { password })).then(() => {
          toast.success("Mot de passe enregistré avec succès");
          navigate("/");
        }),
        setLoading
      );
    }
  };

  return (
    <div className="flex items-center justify-end h-full p-32">
      <form onSubmit={resetPassword}>
        <div className="w-[480px] px-8 py-12 flex flex-col bg-white rounded-2xl">
          <div className="text-2xl mb-8 font-bold text-center">Définissez votre mot de passe</div>
          <div className="mb-8 bg-white">
            <TextInput
              label="Mot de passe"
              type="password"
              placeholder="Mot de passe"
              value={password}
              light
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </div>
          <div className="mb-2 self-end">
            <Button type="submit" loading={loading} kind="outline" title="Confirmer" />
          </div>
        </div>
      </form>
    </div>
  );
};
