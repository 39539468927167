import { Button, ColorType, Divider, RangePicker, Textarea, TextInput } from "@getprorecrutement/getpro-design"
import { FunctionComponent, useState } from "react";
import { CultureFitDescription, CultureFitDomain } from "../../../../models/domainReportDescription";

export interface Props {
  onFinish: (updateData: CultureFitDescription) => void;
  domainDescription?: CultureFitDomain;
}

export const EditDomainCultureFit: FunctionComponent<Props> = ({ domainDescription, onFinish }) => {
  const [description, setDescription] = useState(domainDescription?.descriptions.description || "");

  const [neutralFitBound, setNeutralFitBound] = useState(domainDescription?.descriptions?.neutral_bound || 20);
  const [lowFitBound, setLowFitBound] = useState(domainDescription?.descriptions?.low_fit_bound || 50);

  const isValid = () => !(!description || lowFitBound < neutralFitBound);

  const finish = () => {

    if (!isValid()) return;
    console.log("oh zebu")
    onFinish({

      description,
      low_fit_bound: lowFitBound,
      neutral_bound: neutralFitBound
    })
  }

  return <div className="bg-white w-[600px]">

    <div className="text-center font-bold text-xl mb-5">{domainDescription?.label}</div>
    <Textarea placeholder="Description du domaine" value={description} onChange={setDescription} label="Description" rows={5} />

    <div className="py-5 mb-10 bg-inherit">
      <div className="font-bold text-xl mb-5">Bornes</div>
      <TextInput type="number" className="mb-5" value={neutralFitBound.toString()} onChange={({ target }) => parseInt(target.value) <= 100 && setNeutralFitBound(parseInt(target.value))} placeholder="20%" label="Seuil top fit" />
      <TextInput type="number" value={lowFitBound.toString()} onChange={({ target }) => parseInt(target.value) <= 100 && setLowFitBound(parseInt(target.value))} placeholder="50%" label="Seuil fit neutre" />
    </div>

    <div className="flex justify-end">
      <Button title="Confirmer" disabled={!isValid()} onClick={finish} colorType={ColorType.Content} />
    </div>

  </div>
}
