import { Button, ColorType, Divider, Textarea, TextInput } from "@getprorecrutement/getpro-design"
import { FunctionComponent, useState } from "react";
import { CompanyCultureFitDomain, CultureFitCompanyDescription, CultureFitDescription } from "../../../../models/domainReportDescription";

export interface Props {
  onFinish: (updateData: CultureFitCompanyDescription) => void;
  domainDescription?: CompanyCultureFitDomain;
}

export const EditDomainCompanyCultureFit: FunctionComponent<Props> = ({ onFinish, domainDescription }) => {
  const [description, setDescription] = useState(domainDescription?.descriptions.description || "");


  const isValid = () => !!description;

  const finish = () => {

    if (!isValid()) return;
    onFinish({
      description,
    })
  }

  return <div className="bg-white w-[600px]">

    <div className="text-center font-bold text-xl mb-5">{domainDescription?.label}</div>
    <Textarea placeholder="Description du domaine" value={description} onChange={setDescription} label="Description" rows={5} />
    <div className="flex justify-end">
      <Button title="Confirmer" disabled={!isValid()} onClick={finish} colorType={ColorType.Content} />
    </div>

  </div>
}
