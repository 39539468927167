import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { PaginationParams } from "./paginated";

export interface UserResponse {
  id: Uuid;
  first_name: string;
  last_name: string;
  email: string;
  license_id: Uuid;
  created_at: string;
  updated_at: string;
}

export interface UserUpdate {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
}

export interface NewUser {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  license_id: Uuid;
}

export type UserFilters = {
  license_id?: Uuid;
} & PaginationParams;

export const fetchUsers = (filters: UserFilters) => httpClient.req(ROUTES.FETCH_USERS(filters));

export const updateUser = (data: { id: Uuid } & UserUpdate) =>
  httpClient.req(ROUTES.UPDATE_USER(data)).then((res) => {
    toast.success("Utilisateur mis à jour avec succès");
    return res;
  });

export const createUser = (payload: NewUser) =>
  httpClient.req(ROUTES.CREATE_USER(payload)).then((res) => {
    toast.success("Utilisateur créé avec succès");
    return res;
  });

export const impersonateUser = (userId: Uuid) => httpClient.req(ROUTES.IMPERSONATE(userId));
