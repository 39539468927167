import { ColorType, Pagination, Table, Tooltip } from "@getprorecrutement/getpro-design";
import { EyeIcon, PencilAltIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { Paginated } from "../models/paginated";
import { UserFilters, UserResponse } from "../models/user";

export interface UserTableProps {
  users?: Paginated<UserResponse>;
  onEdit: (val: Partial<UserResponse>) => void;
  filters: UserFilters;
  setFilters: (filter: UserFilters) => void;
  onImpersonate?: (userId: Uuid) => void;
}

export const UserTable: FunctionComponent<UserTableProps> = ({ users, onEdit, filters, setFilters, onImpersonate }) => {
  return (
    <div className="w-full max-w-[1600px] p-8">
      <Table
        colorType={ColorType.Content}
        light
        dataSource={{ data: users?.data || [], total: users?.total || 0 }}
        columns={[
          {
            title: "Prénom",
            dataIndex: "first_name",
            key: "first_name",
          },
          {
            title: "Nom",
            dataIndex: "last_name",
            key: "last_name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (value, r) => (
              <div>
                <Tooltip title={value} className="w-full md:hidden">
                  <div className="py-5 text-ellipsis overflow-hidden">{value}</div>
                </Tooltip>
                <div>
                  <div className="py-5 hidden md:block">{value}</div>
                </div>
              </div>
            ),
          },
          {
            title: "Date de céation",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, r) => <div>{dayjs.utc(value).local().format("DD MMM YYYY")}</div>,
          },
          {
            title: "Actions",
            dataIndex: "email",
            key: "actions",
            render: (value, record) => (
              <div className="flex gap-2 justify-end">
                <Tooltip title="Editer" className="cursor-pointer">
                  <PencilAltIcon width={24} onClick={() => onEdit(record)} />
                </Tooltip>
                {onImpersonate && (
                  <Tooltip title="Se connecter en tant que" className="cursor-pointer">
                    <EyeIcon
                      width={24}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onImpersonate(record.id);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
      <div className="flex justify-end mt-4 ">
        <Pagination
          light
          pageSize={filters.per_page}
          page={filters.page}
          onChange={(page) => setFilters({ ...filters, page })}
          total={users?.total || 0}
          pageParamsActions={[
            {
              action: () => setFilters({ ...filters, per_page: 10, page: 1 }),
              title: "Afficher par 10",
            },
            {
              action: () => setFilters({ ...filters, per_page: 20, page: 1 }),
              title: "Afficher par 20",
            },
            {
              action: () => setFilters({ ...filters, per_page: 30, page: 1 }),
              title: "Afficher par 30",
            },
          ]}
        />
      </div>
    </div>
  );
};
