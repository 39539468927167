import { MoreActions } from "@getprorecrutement/getpro-design";
import { DownloadIcon, FolderIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateTmpToken } from "../models/assessment";
import { JobTitle } from "../models/jobTitle";
import { AssessmentReportTarget, fetchLicenseOwnedAssessmentReports, LicenseAssessmentReport } from "../models/licenseAssessment";
import {
  findParticipant,
  findParticipantJobTitle,
  getParticipantAssessments,
  getParticipantProjects,
  Participant,
  ParticipantAssessmentSummary,
  ParticipantProjectsSummary,
} from "../models/particiapnt";

import { downloadReport } from "../models/report"
import { PageHeader } from "../modules/pageHeader";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import download from "../utils/download";

export const ParticipantPage: FunctionComponent<{}> = () => {
  const { id } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [projects, setProjects] = useState<ParticipantProjectsSummary[]>();
  const [assessments, setAssessments] = useState<ParticipantAssessmentSummary[]>();
  const [reports, setReports] = useState<LicenseAssessmentReport[]>();
  const [jobTitle, setJobTitle] = useState<JobTitle>();
  const navigate = useNavigate();

  // const fetchInitialData = useCallback(async () => {
  //   if (id) {
  //     const assessments = await getParticipantAssessments(id);
  //     const projects = await getParticipantProjects(id);
  //     const participant = await findParticipant(id);

  //     if (participant.job_title_id) {
  //     }
  //   }
  // }, [id]);

  useEffect(() => {
    if (id) {
      getParticipantAssessments(id).then(setAssessments);
      getParticipantProjects(id).then(setProjects);
      findParticipant(id).then((p) => {
        if (p.job_title_id) {
          findParticipantJobTitle(p.id).then(setJobTitle);
        }
        setParticipant(p);
      });
      fetchLicenseOwnedAssessmentReports().then(setReports);
    }
  }, [id]);



  return (
    <div className="flex flex-col h-full">
      <PageHeader title={"Fiche participant"} />
      <div className="bg-white flex-grow flex justify-center">
        <div className=" p-8 max-w-[1200px] w-full bg-inherit">
          <div className="border-border-lightest rounded-3xl border p-10">
            <div className="flex">
              <div className="w-1/4">
                <div className="text-content-regular">Prénom</div>
                <div className="text-content-darker font-semibold">{participant?.first_name || "-"}</div>
              </div>
              <div className="w-1/4">
                <div className="text-content-regular">Nom</div>
                <div className="text-content-darker font-semibold">{participant?.last_name || "-"}</div>
              </div>
              <div className="w-1/4">
                <div className="text-content-regular">Date de naissance</div>
                <div className="text-content-darker font-semibold">
                  {participant?.birth_date ? dayjs(participant?.birth_date).format("DD/MM/YYYY") : "-"}
                </div>
              </div>
              <div className="w-1/4 flex justify-end">
                <div
                  className={`text-2xl w-[50px] h-[50px] flex justify-center items-center rounded-full ${participant?.gender === "male"
                    ? "bg-background-darker text-white"
                    : "bg-background-lightest text-content-darker"
                    }`}
                >
                  H
                </div>
                <div
                  className={`text-2xl w-[50px] h-[50px] flex justify-center items-center rounded-full ${participant?.gender === "female"
                    ? "bg-background-darker text-white"
                    : "bg-background-lightest text-content-darker"
                    }`}
                >
                  F
                </div>
              </div>
            </div>
            <div className="my-7 border-b border-border-lightest"></div>
            <div className="flex">
              <div className="w-1/2">
                <div className="text-content-regular">Poste</div>
                <div className="text-content-darker font-semibold">{jobTitle ? jobTitle.title : "-"}</div>
              </div>
              <div className="w-1/2">
                <div className="text-content-regular">Adresse mail</div>
                <div className="text-content-darker font-semibold">{participant?.email}</div>
              </div>
            </div>
          </div>
          <div className="border-border-lightest rounded-3xl border p-10 mt-10">
            <div className="text-content-darker text-2xl font-bold">Projets</div>
            <div className="md:grid md:grid-cols-3 gap-5 mt-5">
              {projects?.map((project) => (
                <div
                  key={project.id}
                  className="border rounded-xl p-5 cursor-pointer"
                  onClick={() => {
                    navigate(`/projects/${project.id}`);
                  }}
                >
                  <div className="flex gap-5">
                    <FolderIcon height={24} width={24} fill="#8bccf0" className="text-primary-medium" />
                    <div className="text-primary-darker font-semibold">{project.name}</div>
                  </div>
                  <div className="flex gap-5 w-full items-center mt-3">
                    <div className="bg-background-lightest flex-grow rounded-full overflow-hidden h-2">
                      <div
                        className="bg-green-400 h-full"
                        style={{
                          width: `${Math.round(100 * (project.finished_count / (project.assessment_count || 1)))}%`,
                        }}
                      ></div>
                    </div>
                    <div>{`${project.finished_count} /${project.assessment_count}`}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="border-border-lightest rounded-3xl border p-10 mt-10">
            <div className="text-content-darker text-2xl font-bold">Évaluations</div>
            <div className="md:grid md:grid-cols-3 gap-5 mt-5">
              {assessments?.map((assessment) => (
                <div className="border rounded-xl p-5" key={assessment.id}>
                  <div className="flex gap-5 items-center">
                    <div
                      className={`h-2 w-2 rounded-full ${assessment.finished_at ? "bg-green-400" : "bg-blue-400"}`}
                    ></div>
                    <div className={`font-semibold ${assessment.finished_at ? "text-green-400" : "text-blue-400"}`}>
                      {assessment.finished_at ? "Terminé" : "En cours"}
                    </div>
                  </div>
                  <div className="mt-5 text-primary-darker font-semibold">{assessment.name}</div>
                  {assessment.finished_at && reports && (
                    <div className="flex justify-center mt-6">
                      <MoreActions
                        dark
                        actions={reports
                          .filter((r) => !r.target?.includes(AssessmentReportTarget.Global) && r.assessment_id === assessment.id && assessment.report_ids.includes(r.id))
                          .map((report) => {
                            return {
                              title: report.name,
                              action: () => {
                                downloadReport(assessment.project_id, report.id, assessment.project_participant_id);
                              },
                            };
                          })}
                      >
                        <div className="bg-background-dark flex gap-2 items-center py-1 px-3 rounded-full">
                          <DownloadIcon width={20} height={20} />
                          <div className="text-white ">Rapports</div>
                        </div>
                      </MoreActions>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
