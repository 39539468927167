import axios, { AxiosResponse } from "axios";
import ROUTES, { Method, Route, URLS } from "./routes";
import { AuthResponse } from "../models/auth";
import store, { Message, Stored } from "./store";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";

interface Request<T> {
  resolve: (_: unknown) => unknown;
  reject: () => unknown;
  route: Route<T>;
}

type ErrorHandler = (err: string | undefined, code?: number) => void;

class HttpClient {
  public pendingRequests: Request<unknown>[];
  public isRefreshingAuth?: boolean;

  constructor() {
    this.pendingRequests = [];
  }

  public req<T>(route: Route<T>, errorHandler?: ErrorHandler): Promise<T> {
    const { params, method, auth, file } = route;
    let { data } = route;
    const headers = route.headers || {};

    const state = store.state;
    const [jwt, rawJwt, refreshToken, rawImpersonatedJWT] = [
      state[Stored.JWT],
      state[Stored.RawJWT],
      state[Stored.RefreshToken],
      state[Stored.RawImpersonatedJWT],
    ];

    if (auth) {
      if (this.isRefreshingAuth) return this.setPendingRequest(route);

      if (!refreshToken) {
        store.notify(Message.NeedAuth);
        return Promise.reject("Merci de vous connecter avant de continuer");
      }
      if (!jwt || jwt.exp <= new Date().getTime() / 1000 + 60) {
        const pendingRequest = this.setPendingRequest(route);

        this.isRefreshingAuth = true;
        this.refreshJwt();
        return pendingRequest;
      }
      headers.Authorization = `Bearer ${rawImpersonatedJWT || rawJwt}`;
    }
    if (file) {
      let tmp = new FormData();
      tmp.append("file", file);

      if (data) {
        let blob = new Blob([JSON.stringify(data)], { type: "application/json" });
        tmp.append("data", blob, "");
      }

      data = tmp;
    }

    Sentry.configureScope((scope) => {
      let path = route.path.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, ":id");
      if (auth) {
        scope.setUser({ email: store.state.JWT?.email });
      }

      scope.setTransactionName(`${method.toUpperCase()} ${path}`);
    });

    const opts = {
      // paramsSerializer: function (params: any) {
      //   return qs.stringify(params, { allowDots: true });
      // },
    };
    return [Method.Get, Method.Delete].includes(method)
      ? (axios as any)
          [method](this.genUrl(route), {
            params,
            headers,
            ...opts,
          })
          .then((e: any) => e.data)
          .catch((err: { response?: AxiosResponse }) => this.handleError(err, errorHandler))
      : (axios as any)
          [method](this.genUrl(route), data, {
            params,
            headers,
            ...opts,
          })
          .then((e: any) => e.data)
          .catch((err: { response?: AxiosResponse }) => this.handleError(err, errorHandler));
  }

  private handleError(error: { response?: AxiosResponse }, handler?: ErrorHandler): void {
    const err = error?.response?.data?.error || undefined;

    if (handler) handler(err, error.response?.status);
    else {
      toast.error(err || "Erreur de communication avec le serveur");
    }

    if (error.response?.status === 401) {
      let impersonateRawJwt = store.state.RawImpersonatedJWT;
      if (impersonateRawJwt) {
        store.update(Stored.ImpersonatedJWT, undefined);
        store.update(Stored.RawImpersonatedJWT, undefined);
        window.location.reload();
      } else {
        store.update(Stored.RefreshToken, undefined);
        store.notify(Message.NeedAuth);
      }
    }

    throw error;
  }

  private refreshJwt(): void {
    const token = store.state[Stored.RefreshToken];
    if (!token) {
      return store.notify(Message.NeedAuth);
    }

    this.req<AuthResponse>(ROUTES.REFRESH_JWT(token))
      .then((res) => {
        store.setCredentials(res);
        this.isRefreshingAuth = false;
        this.pendingRequests.forEach((r) => {
          this.req(r.route).then(r.resolve).catch(r.reject);
        });
      })
      .catch(() => {
        this.pendingRequests.forEach((r) => {
          r.reject();
        });
        this.pendingRequests = [];
        store.notify(Message.NeedAuth);
      });
  }

  public genUrl<T>(route: Route<T>): string {
    const urlReg = /^(?:https?:\/\/)?([0-9a-z-.:]+)\/?/i;
    const baseUrl = URLS[route.api]?.replace(
      urlReg,
      process.env.NODE_ENV === "development" ? "http://$1/" : "https://$1/"
    );
    return baseUrl + route.path;
  }

  public genUrlWithParams<T>(route: Route<T>): string {
    let url = this.genUrl(route);
    if (route.params) {
      const params = new URLSearchParams(route.params);
      url = `${url}?${params}`;
    }
    return url;
  }

  private setPendingRequest<T>(route: Route<T>): Promise<T> {
    const promise = new Promise((resolve, reject) => {
      this.pendingRequests.push({ resolve, reject, route });
    });

    return promise as Promise<T>;
  }
}

export const loadable = <T>(res: Promise<T>, setLoading: (val: boolean) => void): Promise<T> => {
  setLoading(true);
  return res.finally(() => setLoading(false));
};

const httpClient = new HttpClient();

export default httpClient;
