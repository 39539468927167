import { Button, ColorType, Radio, Stepper, Textarea, TextInput } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  BigFiveClientDescription,
  BigFiveClientDomain,
  ScoreBasedDescription,
} from "../../../../models/domainReportDescription";

export interface EditBigFiveDomainClientDescriptionsFormProps {
  domainDescription?: BigFiveClientDomain;
  onFinish: (updateData: BigFiveClientDescription) => void;
}

interface EditScoreBasedDescriptionProps {
  onFinish?: () => void;
  onCancel?: () => void;
  onEdit: () => void;
  onComplete: (description: ScoreBasedDescription) => void;
  domainDescription?: ScoreBasedDescription;
}

interface GenderBasedFieldPreviewProps {
  data: string;
  label: string;
}

export const GenderBasedFieldPreview: FunctionComponent<GenderBasedFieldPreviewProps> = ({ data, label }) => {
  const [transformedData, setTransformedData] = useState<string>(
    // eslint-disable-next-line
    data.replaceAll(/\[\[([^\|]+)?\|([^\]]+)?\]\]/g, "$1").replaceAll(/\{\{([^}]+)\}\}/g, "Clément")
  );
  const [selectedGender, setSelectedGender] = useState<"male" | "female">("male");

  useEffect(() => {
    setSelectedGender("male");
  }, []);

  useEffect(() => {
    setTransformedData(
      data
        // eslint-disable-next-line
        .replaceAll(/\[\[([^\|]+)?\|([^\]]+)?\]\]/g, selectedGender === "male" ? "$1" : "$2")
        .replaceAll(/\{\{([^}]+)\}\}/g, selectedGender === "male" ? "Clément" : "Clotilde")
    );
  }, [data, selectedGender]);

  return (
    <div>
      <div className="relative border p-5 rounded-xl overflow-visible">
        <div className="absolute top-0 w-full flex justify-between items-center px-5 -translate-y-1/2 z-20">
          <div className="bg-white px-5 font-semibold text-content-dark">{label}</div>
          <Radio
            className="mr-10 bg-white"
            light
            optionType="button"
            onChange={(v) => setSelectedGender(v as "male" | "female")}
            value={selectedGender}
            options={[
              { label: "Homme", value: "male" },
              { label: "Femme", value: "female" },
            ]}
          />
        </div>
        {transformedData && <ReactMarkdown className="px-5 [&>ul]:list-disc">{transformedData}</ReactMarkdown>}
      </div>
    </div>
  );
};

export const EditScoreBasedDescription: FunctionComponent<EditScoreBasedDescriptionProps> = ({
  domainDescription,
  onComplete,
  onEdit,
  onFinish,
}) => {
  const [description, setDescription] = useState<ScoreBasedDescription>(
    domainDescription || {
      description: "",
      potential: "",
      risk: "",
    }
  );
  const [showPreview, setShowPreview] = useState<boolean>(true);

  useEffect(() => {
    setDescription(
      domainDescription || {
        description: "",
        potential: "",
        risk: "",
      }
    );
  }, [domainDescription]);

  const onUpdate = (update: Partial<ScoreBasedDescription>) => {
    if (!description) return;
    setDescription({ ...description, ...update });
  };

  useEffect(() => {
    if (!showPreview) onEdit();
  }, [showPreview, onEdit]);

  return showPreview && description ? (
    <div className="flex flex-col gap-7 w-full pt-5 h-full px-5">
      <GenderBasedFieldPreview data={description.description} label="Description" />
      <GenderBasedFieldPreview data={description.potential} label="Potentialités" />
      <GenderBasedFieldPreview data={description.risk} label="Risques" />
      <div className="flex justify-end gap-5">
        <Button
          colorType={ColorType.Content}
          title="Editer"
          onClick={() => {
            setShowPreview(false);
          }}
        />
        <Button
          colorType={ColorType.Content}
          title="Continuer"
          onClick={() => {
            onFinish?.();
          }}
        />
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-5 pt-5 bg-white h-full w-full">
      <Textarea
        light
        className="w-full"
        label="Description"
        value={description?.description}
        onChange={(v) => onUpdate({ description: v })}
        placeholder={""}
        rows={3}
      />
      <Textarea
        light
        label="Potentialités"
        value={description?.potential}
        onChange={(v) => onUpdate({ potential: v })}
        placeholder={""}
        rows={3}
      />
      <Textarea
        light
        label="Risques"
        value={description?.risk}
        onChange={(v) => onUpdate({ risk: v })}
        placeholder={""}
        rows={3}
      />

      <div className="flex justify-end">
        <Button
          disabled={!description}
          colorType={ColorType.Content}
          title="Prévisualiser"
          onClick={() => {
            if (description) {
              setShowPreview(true);
              onComplete(description);
            }
          }}
        />
      </div>
    </div>
  );
};

export const EditBigFiveDomainClientDescriptionsForm: FunctionComponent<
  EditBigFiveDomainClientDescriptionsFormProps
> = ({ domainDescription, onFinish }) => {
  const [scoreBasedDescriptions, setScoreBasedDescriptions] = useState<ScoreBasedDescription[]>(
    domainDescription?.descriptions.score_based_descriptions || Array(4).fill(undefined)
  );
  const [description, setDescription] = useState<Omit<BigFiveClientDescription, "score_based_descriptions">>({
    natural_label: "",
    inverse_label: "",
    description: "",
    short_description: "",
  });

  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setScoreBasedDescriptions(domainDescription?.descriptions.score_based_descriptions || Array(4).fill(undefined));
    if (domainDescription?.descriptions) setDescription(domainDescription?.descriptions);
  }, [domainDescription]);

  const isFormValid = (): boolean => {
    if (editing) return false;
    if (
      !(
        description &&
        description.description.length > 0 &&
        description.inverse_label.length > 0 &&
        description.natural_label.length > 0
      )
    )
      return false;
    if (domainDescription?.parent_template_id) {
      return (
        scoreBasedDescriptions.length === 4 &&
        scoreBasedDescriptions.reduce((acc, next) => {
          return acc && !!next && !!next.description && !!next.potential && !!next.risk;
        }, true)
      );
    } else {
      return (description.short_description?.length || 0) > 0;
    }
  };

  const onEditWrapper = useCallback(() => {
    setEditing(true);
  }, []);

  const onCompleteWrapper = useCallback(
    (description: ScoreBasedDescription, index: number) => {
      setScoreBasedDescriptions([
        ...scoreBasedDescriptions.slice(0, index),
        description,
        ...scoreBasedDescriptions.slice(index + 1, scoreBasedDescriptions.length),
      ]);
      setEditing(false);
    },
    [scoreBasedDescriptions]
  );

  const steps = useMemo(() => {
    return ["0 - 30%", "31 - 50%", "51 - 70%", "71 - 100%"].map((elem, index) => ({
      Component: EditScoreBasedDescription,
      title: elem,
      key: elem,
      props: {
        domainDescription: scoreBasedDescriptions[index],
        onEdit: onEditWrapper,
        onComplete: (desc: ScoreBasedDescription) => onCompleteWrapper(desc, index),
      },
      is_valid: !editing && !!scoreBasedDescriptions[index],
    }));
  }, [editing, scoreBasedDescriptions, onEditWrapper, onCompleteWrapper]);

  return (
    <div className="max-h-[80vh] max-w-3xl p-3 overflow-auto">
      <div className="font-bold text-xl text-content-darker text-center">
        Big five - Rapport client - {domainDescription?.name}
      </div>
      <div className="text-xs flex flex-col gap-10 bg-inherit bg-white">
        <div className="flex justify-between gap-5 bg-white mt-10">
          <TextInput
            light
            value={description?.natural_label}
            placeholder={""}
            label="Sens naturel"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, natural_label: target.value });
            }}
          />
          <TextInput
            light
            value={description?.inverse_label}
            placeholder={""}
            label="Sens inverse"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, inverse_label: target.value });
            }}
          />
        </div>
        <Textarea
          light
          placeholder="Description"
          rows={5}
          label="Description"
          value={description?.description}
          onChange={(value) => {
            description &&
              setDescription({
                ...description,
                description: value,
              });
          }}
        />
        {!domainDescription?.parent_template_id && (
          <Textarea
            light
            placeholder="Synthèse"
            rows={5}
            label="Synthèse"
            value={description?.short_description}
            onChange={(value) => {
              description &&
                setDescription({
                  ...description,
                  short_description: value,
                });
            }}
          />
        )}
      </div>
      {domainDescription?.parent_template_id && (
        <Stepper
          position="top"
          className="h-full w-full"
          customRender={(v, focused, focus) => {
            const classes = classNames("rounded-3xl p-2 px-3 cursor-pointer", {
              "text-white bg-background-darker": focused,
              "text-content-medium bg-white border border-border-light": !focused,
            });
            return (
              <div className={classes} onClick={focus} key={"header_" + v.key}>
                {v?.title}
              </div>
            );
          }}
          headerClasses="justify-center gap-5"
          values={steps}
        />
      )}
      <div className="flex justify-end mt-5 pr-4 w-full">
        <Button
          colorType={ColorType.Content}
          title="Enregistrer"
          disabled={!isFormValid()}
          onClick={() => {
            !editing &&
              description &&
              scoreBasedDescriptions &&
              onFinish({
                ...description,
                score_based_descriptions: scoreBasedDescriptions,
              });
          }}
        />
      </div>
    </div>
  );
};
