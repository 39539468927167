import { Button, ColorType, TextInput } from "@getprorecrutement/getpro-design";
import { useState } from "react";
import { UserResponse, updateUser, createUser } from "../models/user";
import store from "../services/store";

export interface Props {
  user?: Partial<UserResponse>;
  licenseId?: Uuid;
  onFinish?: () => void;
}

export const UserForm = ({ user, licenseId, onFinish }: Props) => {
  const [firstName, setFirstName] = useState<string | undefined>(user?.first_name || "");
  const [lastName, setLastName] = useState<string | undefined>(user?.last_name || "");
  const [email, setEmail] = useState<string | undefined>(user?.email || "");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const update = async () => {
    if (firstName && lastName && email && user?.id) {
      setLoading(true);
      try {
        await updateUser({
          id: user.id,
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
        });
        onFinish?.();
      } finally {
        setLoading(false);
      }
    }
  };

  const create = async () => {
    const license_id = licenseId || (store.state.ImpersonatedJWT || store.state.JWT)?.license_id;

    if (firstName && lastName && email && password && license_id) {
      setLoading(true);
      try {
        await createUser({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          license_id,
        });
        onFinish?.();
      } finally {
        setLoading(false);
      }
    }
  };

  const formCompleted = firstName && lastName && email && (password || !!user?.id) ? true : false;

  return (
    <div className="bg-inherit">
      <div className="bg-inherit">
        <TextInput
          placeholder="Prénom"
          label="Prénom"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          light
        />
      </div>
      <div className="mt-6 bg-inherit">
        <TextInput
          placeholder="Nom"
          label="Nom"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
          light
        />
      </div>
      <div className="mt-6 bg-inherit">
        <TextInput
          placeholder="Email"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          light
        />
      </div>
      <div className="mt-6 bg-inherit">
        <TextInput
          placeholder="Mot de passe"
          label="Mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          light
        />
      </div>
      <div className="mt-6 flex justify-end w-full">
        <Button
          colorType={ColorType.Content}
          loading={loading}
          title={user?.id ? "Enregistrer les modifications" : "Créer l'utilisateur"}
          onClick={user?.id ? update : create}
          disabled={!formCompleted}
        />
      </div>
    </div>
  );
};
