import { Modal } from "@getprorecrutement/getpro-design";
import { useCallback, useEffect, useState } from "react";
import {
  AssessmentResponse,
  AssessmentType,
  AssessmentUpdate,
  createNewAssessment,
  createNewAssessmentVersion,
  fetchAssessments,
  GroupedAssessment,
  NewAssessmentWithVersion,
  updateAssessment,
} from "../models/assessment";
import { ModelAssessments } from "../modules/assessments/modelAssessments";
import NewAssessmentForm from "../modules/forms/newAssessmentForm";
import { PageHeader } from "../modules/pageHeader";
import { EditAssessmentForm } from "../modules/forms/editAssessmentForm";
import { fetchModels } from "../models/model";

const groupAssessmentByModelId = (assessments: AssessmentResponse[]): GroupedAssessment[] => {
  let assessments_grouped: { [key: Uuid]: GroupedAssessment } = assessments.reduce((acc, next) => {
    if (acc[next.model_id]) {
      acc[next.model_id].assessments.push(next);
    } else {
      acc[next.model_id] = {
        model_id: next.model_id,
        model_name: next.model_name,
        assessments: [next],
      };
    }
    return acc;
  }, {} as { [key: Uuid]: GroupedAssessment });
  return Object.values(assessments_grouped);
};

export const ModelsPage = () => {
  const [groupedAssessments, setGroupedAssessments] = useState<GroupedAssessment[]>();
  const [newAssessment, setNewAssessment] = useState<{ model_id: Uuid }>();
  const [editingAssessment, setEditingAssessment] = useState<AssessmentResponse>();

  const fetchInitialData = useCallback(async () => {
    const models = await fetchModels();
    const assessments = await fetchAssessments();
    setGroupedAssessments(models.map((model) => ({
      model_id: model.id,
      model_name: model.name,
      assessments: assessments.filter((a) => a.model_id === model.id)
    })));

  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const onNewAssessmentCreated = async (assessmentData: NewAssessmentWithVersion) => {
    if (!newAssessment) return;
    const assessment = await createNewAssessment({
      name: assessmentData.name,
      label: assessmentData.label,
      ...newAssessment,
    });
    await createNewAssessmentVersion(assessment.id, {
      questions_per_page: assessmentData.questions_per_page,
      assessment_type: assessmentData.assessment_type,
    });
    const models = await fetchModels();
    const assessments = await fetchAssessments();
    setGroupedAssessments(models.map((model) => ({
      model_id: model.id,
      model_name: model.name,
      assessments: assessments.filter((a) => a.model_id === model.id)
    })));

    setNewAssessment(undefined);
  };

  const onEditAssessment = async (data: AssessmentUpdate) => {
    if (!editingAssessment) return;
    await updateAssessment(editingAssessment?.id, data);
    await fetchInitialData();
    setEditingAssessment(undefined);
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Hello la team Sciences,"
        subTitle="Ceci est votre plateforme d'admin pour gérer vos modèles et vos questionnaires."
      />
      <div className="bg-white  flex-grow flex justify-center">
        <div className="flex gap-8 p-8 flex-wrap justify-center bg-inherit max-w-[1600px] w-full">
          {groupedAssessments?.map((groupedAssessment, index) => (
            <ModelAssessments
              key={index}
              onEditAssessnent={setEditingAssessment}
              groupedAssessments={groupedAssessment}
              onCreateNewAssessment={() => {
                setNewAssessment({
                  model_id: groupedAssessment.model_id,
                });
              }}
            />
          ))}
        </div>
      </div>
      <Modal className="bg-white" show={!!newAssessment} onClose={() => setNewAssessment(undefined)}>
        <NewAssessmentForm
          modelId={newAssessment?.model_id}
          onCancel={() => setNewAssessment(undefined)}
          onFinish={onNewAssessmentCreated}
        />
      </Modal>
      <Modal className="bg-white" show={!!editingAssessment} onClose={() => setEditingAssessment(undefined)}>
        <EditAssessmentForm assessment={editingAssessment} onFinish={onEditAssessment} />
      </Modal>
    </div>
  );
};
