import { FunctionComponent, useEffect, useState } from "react";
import { GroupedAssessment, AssessmentResponse } from "../../models/assessment";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { AssessmentCard } from "./card";

export interface ModelAssessmentsProps {
  groupedAssessments: GroupedAssessment;
  onCreateNewAssessment: () => void;
  onEditAssessnent: (assessement: AssessmentResponse) => void;
}

export const ModelAssessments: FunctionComponent<ModelAssessmentsProps> = ({
  groupedAssessments,
  onCreateNewAssessment,
  onEditAssessnent,
}) => {
  const [assessments, setAssessments] = useState<AssessmentResponse[]>();

  useEffect(() => {
    setAssessments(groupedAssessments.assessments);
  }, [groupedAssessments]);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center gap-2">
        <div className="whitespace-nowrap text-2xl font-bold text-content-darker">{groupedAssessments.model_name}</div>
        <div className="w-full border-b ml-5"></div>
      </div>
      <div className="md:grid md:grid-cols-3 flex flex-col gap-2 mt-10 w-full min-h-[180px]">
        {assessments?.map((assessment) => (
          <AssessmentCard
            key={assessment.id}
            assessment={assessment}
            onEdit={() => onEditAssessnent(assessment)}
            onCreateNewVersion={() => {}}
            onDelete={() => {}}
          />
        ))}
        <div
          className="flex justify-center items-center border border-dashed border-slate-300 cursor-pointer rounded-xl"
          onClick={onCreateNewAssessment}
        >
          <div className="flex flex-col gap-3 justify-center items-center text-slate-300 min-h-[164px]">
            <PlusCircleIcon height={34} width={34} />
            <div>Ajouter une évaluation</div>
          </div>
        </div>
      </div>
    </div>
  );
};
