import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useMemo, useState } from "react";
import { LicenseAssessmentReport, LicenseAssessmentResponse } from "../../../models/licenseAssessment";
import { QuickInvitePayload } from "../../../models/project";
import { verifyMail } from "../../../utils/string";
import { ProjectConfiguration } from "./projectConfiguration";

export interface QuickInviteFormProps {
  // may want to embed reports in LicenseAssessment
  licenseAssessments: LicenseAssessmentResponse[];
  licenseAssessmentReports: LicenseAssessmentReport[];
  onCreate: (project: QuickInvitePayload) => void;
}

export const QuickInviteForm: FunctionComponent<QuickInviteFormProps> = ({
  licenseAssessmentReports,
  licenseAssessments,
  onCreate,
}) => {
  const [input, setInput] = useState<string>("");
  const [selectedAssessments, setSelectedAssessments] = useState<LicenseAssessmentResponse[]>();
  const [selectedReports, setSelectedReports] = useState<LicenseAssessmentReport[]>();

  const isValid = () => {
    return (
      input.length > 0 &&
      verifyMail(input) &&
      selectedReports &&
      selectedReports.length > 0 &&
      selectedAssessments &&
      selectedAssessments?.length > 0
    );
  };

  const onFinish = () => {
    if (!isValid() || !selectedReports || !selectedAssessments || !input) return;

    let groupedReportsIds = selectedReports.reduce((acc, next) => {
      (acc[next.license_assessment_id] = acc[next.license_assessment_id] || []).push(next.id);
      return acc;
    }, {} as { [key: Uuid]: Uuid[] });

    let projectAssessments = selectedAssessments.map((assessment) => ({
      assessment_id: assessment.assessment_id,
      report_ids: groupedReportsIds[assessment.id] || [],
    }));

    let newProject = {
      name: projectAssessments.map((a) => a.assessment_id).join(","),
      assessments: projectAssessments,
    };

    let payload = {
      email: input,
      project_data: newProject,
    };

    onCreate(payload);
  };


  const groupedAssessments = useMemo(() => {
    return licenseAssessments.reduce((acc, next) => {
      (acc[next.model_name] = acc[next.model_name] || []).push(next);
      return acc;
    }, {} as { [key: string]: LicenseAssessmentResponse[] })
  }, [licenseAssessments])


  return (
    <div className="max-w-xl overflow-auto">
      <div className="flex items-center justify-center w-full">
        <div className="text-content-darker font-semibold text-xl">Invitation Rapide</div>
      </div>
      <TextInput
        type="text"
        className="bg-white mt-10 p-5"
        placeholder="Email"
        label="Email"
        value={input}
        onChange={(ev) => setInput(ev.target.value)}
      />
      <div>
        <ProjectConfiguration
          groupedAssessments={groupedAssessments}
          selectedModel="Big Five"
          licenseAssessmentReports={licenseAssessmentReports || []}
          selectedAssessments={selectedAssessments || []}
          selectedReports={selectedReports || []}
          onSelectedAssessmentsChange={setSelectedAssessments}
          onSelectedReportsChange={setSelectedReports}
        />
      </div>
      <div className="justify-end flex mt-10">
        <Button title="Envoyer l'invitation" disabled={!isValid()} onClick={onFinish} />
      </div>
    </div>
  );
};
