import { Button, ColorType, Stepper, Textarea, TextInput } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { OnePagerDescription, OnePagerDomain, ScoreBasedDescription } from "../../../../models/domainReportDescription";
import { EditScoreBasedDescription } from "./editDomainClientDescriptionsForm";

export interface EditOnePagerDescriptionsFromProps {
  domainDescription?: OnePagerDomain;
  onFinish: (updateData: OnePagerDescription) => void;
}

export const EditOnePagerDescriptionsFrom: FunctionComponent<EditOnePagerDescriptionsFromProps> = ({
  domainDescription,
  onFinish,
}) => {
  const [scoreBasedDescriptions, setScoreBasedDescriptions] = useState<ScoreBasedDescription[]>(
    domainDescription?.descriptions.score_based_descriptions || Array(4).fill(undefined)
  );
  const [description, setDescription] = useState<Omit<OnePagerDescription, "score_based_descriptions">>({
    natural_label: "",
    inverse_label: "",
    description: "",
  });

  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setScoreBasedDescriptions(domainDescription?.descriptions.score_based_descriptions || Array(4).fill(undefined));
    if (domainDescription?.descriptions) setDescription(domainDescription?.descriptions);
  }, [domainDescription]);

  const isFormValid = (): boolean => {
    if (editing) return false;
    if (
      !(
        description &&
        description.description.length > 0 &&
        description.inverse_label.length > 0 &&
        description.natural_label.length > 0
      )
    )
      return false;
    if (domainDescription?.parent_template_id) {
      return (
        scoreBasedDescriptions.length === 4 &&
        scoreBasedDescriptions.reduce((acc, next) => {
          return acc && !!next && !!next.description && !!next.potential && !!next.risk;
        }, true)
      );
    } else {
      return (description.description?.length || 0) > 0;
    }
  };

  const onEditWrapper = useCallback(() => {
    setEditing(true);
  }, []);

  const onCompleteWrapper = useCallback(
    (description: ScoreBasedDescription, index: number) => {
      setScoreBasedDescriptions([
        ...scoreBasedDescriptions.slice(0, index),
        description,
        ...scoreBasedDescriptions.slice(index + 1, scoreBasedDescriptions.length),
      ]);
      setEditing(false);
    },
    [scoreBasedDescriptions]
  );

  const steps = useMemo(() => {
    return (
      domainDescription?.parent_template_id
        ? ["0 - 30%", "31 - 50%", "51 - 69%", "70 - 100%"]
        : ["0 - 30%", "70 - 100%"]
    ).map((elem, index) => ({
      Component: EditScoreBasedDescription,
      title: elem,
      key: elem,
      props: {
        domainDescription: scoreBasedDescriptions[index],
        onEdit: onEditWrapper,
        onComplete: (desc: ScoreBasedDescription) => onCompleteWrapper(desc, index),
      },
      is_valid: !editing && !!scoreBasedDescriptions[index],
    }));
  }, [editing, scoreBasedDescriptions, domainDescription, onEditWrapper, onCompleteWrapper]);

  return (
    <div className="max-h-[80vh] max-w-3xl p-3 overflow-auto">
      <div className="font-bold text-xl text-content-darker text-center">
        Big five - One Pager - {domainDescription?.name}
      </div>
      <div className="text-xs flex flex-col gap-10 bg-inherit bg-white">
        <div className="flex justify-between gap-5 bg-white mt-10">
          <TextInput
            light
            value={description?.natural_label}
            placeholder={""}
            label="Sens naturel"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, natural_label: target.value });
            }}
          />
          <TextInput
            light
            value={description?.inverse_label}
            placeholder={""}
            label="Sens inverse"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, inverse_label: target.value });
            }}
          />
        </div>
        {!domainDescription?.parent_template_id && <Textarea
          light
          placeholder="Description"
          rows={5}
          label="Description"
          value={description?.description}
          onChange={(value) => {
            description &&
              setDescription({
                ...description,
                description: value,
              });
          }}
        />}
      </div>
      <Stepper
        position="top"
        className="h-full w-full"
        customRender={(v, focused, focus) => {
          const classes = classNames("rounded-3xl p-2 px-3 cursor-pointer", {
            "text-white bg-background-darker": focused,
            "text-content-medium bg-white border border-border-light": !focused,
          });
          return (
            <div className={classes} onClick={focus} key={"header_" + v.key}>
              {v?.title}
            </div>
          );
        }}
        headerClasses="justify-center gap-5"
        values={steps}
      />
      <div className="flex justify-end mt-5 pr-4 w-full">
        <Button
          colorType={ColorType.Content}
          title="Enregistrer"
          disabled={!isFormValid()}
          onClick={() => {
            !editing &&
              description &&
              scoreBasedDescriptions &&
              onFinish({
                ...description,
                score_based_descriptions: scoreBasedDescriptions,
              });
          }}
        />
      </div>
    </div>
  );
};
