interface Props {
  children: JSX.Element | JSX.Element[];
}

export const Layout = (props: Props) => (
  <div className="relative h-full w-full bg-background-darker overflow-hidden">
    <svg
      className="absolute bottom-0 left-0"
      width="1048"
      height="1073"
      viewBox="0 0 1048 1073"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M178.586 126.45L913.5 751.134L-11.6727 1072.03L178.586 126.45Z"
        stroke="#BAE6FD"
        strokeWidth="135"
      />
    </svg>
    <svg
      className="absolute top-0 right-0"
      width="601"
      height="545"
      viewBox="0 0 601 545"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M504.666 -137.548L813.513 469.897L123 426.994L504.666 -137.548Z"
        stroke="#BAE6FD"
        strokeWidth="135"
      />
    </svg>
    <div
      className="absolute w-[100vw] h-[100vw] rounded-full top-0 right-0 translate-x-1/2 -translate-y-1/2"
      style={{
        background:
          "radial-gradient(41.4% 41.4% at 42.39% 50.04%, rgba(30, 64, 175, 0.25) 0%, rgba(30, 64, 175, 0) 100%)",
      }}
    />
    <div
      className="absolute w-[100vw] h-[100vw] rounded-full bottom-0 left-0 -translate-x-1/2 translate-y-1/2"
      style={{
        background:
          "radial-gradient(41.4% 41.4% at 42.39% 50.04%, rgba(30, 64, 175, 0.25) 0%, rgba(30, 64, 175, 0) 100%)",
      }}
    />
    <div className="relative h-full w-full z-10 overflow-y-auto">{props.children}</div>
  </div>
);
