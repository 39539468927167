import { Tab } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store, { Stored } from "../services/store";
import { Omnisearch } from "./omnisearch";

enum Page {
  Assessments = "assessments",
  Users = "users",
  Licenses = "licenses",
  Home = "home",
  Reports = "reports",
  Participants = "participants",
}

const TABS = {
  [Page.Assessments]: "Évaluations",
  [Page.Users]: "Utilisateurs",
  [Page.Licenses]: "Licences",
  [Page.Home]: "Accueil",
  [Page.Reports]: "Rapports",
  [Page.Participants]: "Participants",
};

export const NavigationHeader: FunctionComponent<{}> = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<Page>();
  const [tabs, setTabs] = useState<Page[]>();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTabs(
      (store.state.ImpersonatedJWT || store.state.JWT)?.admin
        ? [Page.Home, Page.Participants, Page.Assessments, Page.Licenses, Page.Users, Page.Reports]
        : [Page.Home, Page.Participants, Page.Users]
    );
    return store.listen(Stored.ImpersonatedJWT, (jwt) => {
      if ((jwt || store.state.JWT)?.admin) {
        setTabs([Page.Home, Page.Participants, Page.Assessments, Page.Licenses, Page.Users, Page.Reports]);
      } else {
        setTabs([Page.Home, Page.Participants, Page.Users]);
      }
    });
  }, []);

  useEffect(
    () =>
      store.listen(Stored.JWT, (e) => {
        if ((store.state.ImpersonatedJWT || e)?.admin) {
          setTabs([Page.Home, Page.Participants, Page.Assessments, Page.Licenses, Page.Users, Page.Reports]);
        } else {
          setTabs([Page.Home, Page.Participants, Page.Users]);
        }
      }),
    []
  );

  useEffect(() => {
    let path = location.pathname.split("/")[1] || "";
    const page = Object.values(Page).includes(path as Page) ? (path as Page) : (Page.Home as Page);
    if (tabs) {
      if (tabs?.includes(page)) {
        setCurrentTab(page);
      } else {
        setCurrentTab(Page.Home);
      }
    }
  }, [location, tabs]);

  const onChange = (page: Page) => {
    setCurrentTab(page);
    switch (page) {
      case Page.Home:
        return navigate("/");
      case Page.Assessments:
        return navigate("/assessments");
      case Page.Licenses:
        return navigate("/licenses");
      case Page.Users:
        return navigate("/users");
      case Page.Reports:
        return navigate("/reports");
      case Page.Participants:
        return navigate("/participants");
    }
  };

  return (
    <div className="border-b border-border-medium flex items-center justify-center">
      <div className="flex items-center gap-6 max-w-[1600px] w-full px-10">
        <div className="py-4">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#BAE6FD" />
            <path d="M17.5001 8.57129L26.0716 23.1427H8.92871L17.5001 8.57129Z" stroke="#0F133F" strokeWidth="2.4" />
          </svg>
        </div>
        <Tab
          className="self-stretch mb-[-1px] pb-[1px]"
          dark
          selected={currentTab}
          activeColor="#bae6fe"
          values={tabs || []}
          onChange={onChange}
          customRenderer={(value, onClick, ref) => {
            const classes = classNames("px-4 h-full flex justify-center items-center cursor-pointer ", {
              "text-primary-light font-bold": value === currentTab,
              "text-white": value !== currentTab,
            });
            return (
              <div ref={ref} key={value} className={classes} onClick={onClick}>
                {TABS[value]}
              </div>
            );
          }}
          getKey={(v: string) => v}
        />
        <Omnisearch />
      </div>
    </div>
  );
};
