import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { LicenseAssessmentReport } from "./licenseAssessment";
import { PaginationParams } from "./paginated";

export interface ParticipantResponseProject {
  hidden: boolean;
  id: Uuid;
  name: string;
  started_at?: string;
  invited_at?: string;
  finished_at?: string;
}

export interface ParticipantResponseAssessment {
  id: Uuid;
  name: string;
  started_at?: string;
  invited_at?: string;
  finished_at?: string;
}

export interface ParticipantResponse {
  id: Uuid;
  first_name?: string;
  last_name?: string;
  email: string;
  birth_date?: string;
  gender?: string;
  job_title_id?: Uuid;
  projects: ParticipantResponseProject[];
  assessments: ParticipantResponseAssessment[];
  total: number;
}

export interface ParticipantResponseFilters {
  email?: string;
  first_name?: string;
}

export interface ParticipantProjectsSummary {
  id: Uuid;
  name: string;
  assessment_count: number;
  finished_count: number;
}

export interface Participant {
  id: Uuid;
  first_name?: string;
  last_name?: string;
  email: string;
  birth_date?: string;
  gender?: string;
  job_title_id?: Uuid;
  created_at: string;
  updated_at: string;
}

export interface ParticipantAssessmentSummary {
  id: Uuid;
  name: string;
  project_participant_id: Uuid;
  project_id: Uuid;
  finished_at?: string;
  report_ids: Uuid[];
}

export const getParticipantsResponse = (filters: PaginationParams & ParticipantResponseFilters) =>
  httpClient.req(ROUTES.FETCH_PARTICIPANTS(filters));
export const findParticipant = (id: Uuid) => httpClient.req(ROUTES.FIND_PARTICIPANT(id));
export const findParticipantJobTitle = (id: Uuid) =>
  httpClient.req(ROUTES.FIND_PARTICIPANT_JOB_TITLE(id), (err, code) => {
    if (code !== 404) toast.error(err || "Erreur de communication avec le serveur");
  });
export const getParticipantAssessments = (id: Uuid) => httpClient.req(ROUTES.GET_PARTICIPANT_ASSESSMENTS(id));
export const getParticipantProjects = (id: Uuid) => httpClient.req(ROUTES.GET_PARTICIPANT_PROJECTS(id));
