import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export interface Answer {
  questions: Array<Uuid>;
}

export interface QuestionResponse {
  id: Uuid;
  title: string;
  reversed: boolean;
  page?: number;
  domain_id: Uuid;
  assessment_id: Uuid;
  created_at: string;
}

export interface NewQuestion {
  title: string;
  reversed: boolean;
  page?: number;
  domain_id: Uuid;
  assessment_version_id: Uuid;
}

export interface QuestionData {
  title?: string;
  reversed?: boolean;
  page?: number;
  domain_id?: Uuid;
}

export interface QuestionImport {
  name: string;
  domain_name: string;
  page: number;
  reversed: boolean;
}

export const fetchAssessmentVersionQuestions = (id: Uuid) =>
  httpClient.req(ROUTES.FETCH_ASSESSMENT_VERSION_QUESTIONS(id));

export const createQuestion = (data: NewQuestion) =>
  httpClient.req(ROUTES.CREATE_QUESTION(data)).then((res) => {
    toast.success("Question crée avec succès");
    return res;
  });

export const updateQuestion = (data: QuestionData & { id: Uuid }) =>
  httpClient.req(ROUTES.UPDATE_QUESTION(data)).then((res) => {
    toast.success("Question mises à jour avec succès");
    return res;
  });

export const deleteQuestion = (id: Uuid) =>
  httpClient.req(ROUTES.REMOVE_QUESTION(id)).then((res) => {
    toast.success("Question supprimée avec succès");
    return res;
  });

export const deletePage = (assessmentId: Uuid, page: number) =>
  httpClient.req(ROUTES.DELETE_PAGE(assessmentId, page)).then((res) => {
    toast.success("La question á bien été supprimée de la page");
    return res;
  });

export const unassignQuestion = (questionId: Uuid) => httpClient.req(ROUTES.UNASSIGN_QUESTION(questionId));
