import { ColorType, Pagination, Table, Tooltip } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paginated, PaginationParams } from "../models/paginated";
import { getParticipantsResponse, ParticipantResponse, ParticipantResponseFilters } from "../models/particiapnt";
import { PageHeader } from "../modules/pageHeader";
import { loadable } from "../services/httpClient";

export const ParticipantsPage: FunctionComponent = () => {
  const [filters, setFilters] = useState<PaginationParams & ParticipantResponseFilters>({ per_page: 10, page: 1 });
  const [participants, setParticipants] = useState<Paginated<ParticipantResponse>>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    loadable(getParticipantsResponse(filters).then(setParticipants), setLoading);
  }, [filters]);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <PageHeader title={"Participants"} />
      <div className="bg-white flex-grow flex justify-center">
        <div className=" p-8 max-w-[1600px] w-full bg-inherit">
          <Table
            loading={loading}
            colorType={ColorType.Content}
            light
            filter={{
              filters: {
                email: filters?.email,
                first_name: filters?.first_name,
              },
              onFilterChange: (key, value) => {
                if (key === "email") {
                  setFilters({ ...(filters || {}), email: value });
                }
                if (key === "first_name") {
                  setFilters({ ...(filters || {}), first_name: value });
                }
              },
            }}
            dataSource={{ data: participants?.data || [], total: participants?.total || 0 }}
            columns={[
              {
                title: "Nom",
                dataIndex: "first_name",
                key: "first_name",
                filterBy: "text",
                render: (_, record) => (
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => navigate(`/participants/${record.id}`)}
                  >
                    <div className="ml-4">
                      <div className="font-bold text-lg text-content-darker">
                        {record.first_name ? `${record.first_name} ${record.last_name}` : "-"}
                      </div>
                      <div className="text-slate-400 text-sm hidden md:block">
                        {record.birth_date ? dayjs.utc(record.birth_date).local().format("DD MMM YYYY") : "-"}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: "Email",
                dataIndex: "email",
                filterBy: "text",
                key: "email",
              },
              {
                title: "Projets",
                dataIndex: "projects",
                key: "projects",
                render: (_, record) => {
                  const filterdProjects = record.projects.filter((p) => !p.hidden);
                  return (
                    <div className="flex items-center gap-2">
                      {filterdProjects.slice(0, 2).map((project) => (
                        <Tooltip
                          key={project.id}
                          dark
                          position="bottom"
                          customRenderer={() => (
                            <div>
                              <div className="p-2">
                                <div className="text-blue-400 text-center mb-2 font-semibold">
                                  {project.finished_at ? "Fini le" : project.started_at ? "Commencé le" : "Invité le"}
                                </div>
                                <div className="text-white">
                                  {dayjs
                                    .utc(project.finished_at || project.started_at || project.invited_at)
                                    .format("DD MMM YYYY")}
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          <div
                            title={project.name}
                            className="border-border-lightest rounded-md cursor-pointer border py-1 px-2 truncate max-w-[150px]"
                            onClick={() => navigate(`/projects/${project.id}`)}
                          >
                            {project.name}
                          </div>
                        </Tooltip>
                      ))}
                      {filterdProjects.length > 2 && (
                        <div
                          className="cursor-help"
                          title={filterdProjects
                            .slice(2)
                            .map((e) => e.name)
                            .join(", ")}
                        >
                          ...
                        </div>
                      )}
                    </div>
                  );
                },
              },
              {
                title: "Évaluations",
                dataIndex: "assessments",
                key: "assessments",
                render: (_, record) => (
                  <div className="flex items-center gap-2">
                    {record.assessments?.slice(0, 2).map((assessment) => {
                      const classes = classNames("rounded-full px-3 py-1 font-bold truncate max-w-[150px]", {
                        "text-blue-500 bg-blue-100": !!assessment.started_at && !assessment.finished_at,
                        "text-green-500 bg-green-100": !!assessment.started_at && !!assessment.finished_at,
                      });

                      return (
                        <Tooltip
                          key={assessment.id}
                          dark
                          position="bottom"
                          customRenderer={() => (
                            <div>
                              <div className="p-2">
                                <div className="text-blue-400 text-center mb-2 font-semibold">
                                  {assessment.finished_at ? "Terminé" : "En cours"}
                                </div>
                                <div className="text-white">
                                  {dayjs.utc(assessment.finished_at || assessment.started_at).format("DD MMM YYYY")}
                                </div>
                              </div>
                            </div>
                          )}
                        >
                          <div title={assessment.name} className={classes}>
                            {assessment.name}
                          </div>
                        </Tooltip>
                      );
                    })}
                    {record.assessments?.length > 2 && <div>...</div>}
                  </div>
                ),
              },
            ]}
          />
          <div className="flex justify-end mt-4 ">
            <Pagination
              pageSize={filters.per_page}
              page={filters.page}
              onChange={(page) => setFilters({ ...(filters || {}), page: page })}
              total={participants?.total || 0}
              light
              pageParamsActions={[
                {
                  action: () => setFilters({ ...(filters || {}), per_page: 10 }),
                  title: "Afficher par 10",
                },
                {
                  action: () => setFilters({ ...(filters || {}), per_page: 20 }),
                  title: "Afficher par 20",
                },
                {
                  action: () => setFilters({ ...(filters || {}), per_page: 30 }),
                  title: "Afficher par 30",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
