import { Button, Modal } from "@getprorecrutement/getpro-design";
import { useEffect, useState } from "react";
import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { Paginated } from "../models/paginated";
import { fetchUsers, UserFilters, UserResponse } from "../models/user";
import { PageHeader } from "../modules/pageHeader";
import { UserForm } from "../modules/userForm";
import { UserTable } from "../modules/users";

export const UsersPage = () => {
  const [users, setUsers] = useState<Paginated<UserResponse>>();
  const [userForm, setUserForm] = useState<Partial<UserResponse>>();
  const [filters, setFilters] = useState<UserFilters>({ page: 1, per_page: 10 });

  useEffect(() => {
    fetchUsers(filters).then(setUsers);
  }, [filters]);

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Utilisateurs"
        subTitle="Gestion des utilisateurs"
        rightSide={
          <Button
            title="Ajouter un utilisateur"
            icon={<PlusCircleIcon />}
            onClick={() => setUserForm({})}
            dark
            kind="outline"
            size="small"
          />
        }
      />
      <div className="bg-white p-8 flex-grow">
        <div className="flex gap-8 flex-wrap justify-center bg-inherit">
          <UserTable users={users} filters={filters} setFilters={setFilters} onEdit={setUserForm} />
        </div>
      </div>
      <Modal
        className="bg-white"
        show={!!userForm}
        onClose={() => setUserForm(undefined)}
        style={{ width: "fit-content" }}
      >
        <div className="border-border-lighter rounded-3xl bg-inherit w-[300px] md:w-[600px]">
          <div className="flex justify-between">
            <ArrowLeftIcon
              className="text-slate-400 cursor-pointer"
              onClick={() => setUserForm(undefined)}
              width={24}
              height={24}
            />
            <div className="-ml-6 text-xl text-content-darker font-semibold">
              {userForm?.id ? "Editer un utilisateur" : "Créer un utilisateur"}
            </div>
            <div />
          </div>
          <div className="mt-6 bg-inherit">
            <UserForm
              user={userForm}
              onFinish={() => {
                fetchUsers(filters).then(setUsers);
                setUserForm(undefined);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
