import { DomainBase } from "./../models/domain";
import { DomainBase as Domain } from "../models/domain";
import { QuestionResponse } from "../models/question";

export interface DomainWithSubs<T extends DomainBase> {
  domain: T;
  sub_domains: T[];
}

export const getDomainsWithSubs = <T extends DomainBase>(domains: T[]): DomainWithSubs<T>[] => {
  let res = domains.reduce((acc, next: Domain) => {
    if (!next.parent_id) {
      acc[next.id] = {
        ...(acc[next.id] || {}),
        domain: next,
      };
    } else {
      acc[next.parent_id] = {
        ...(acc[next.parent_id] || {}),
        sub_domains: [...(acc[next.parent_id]?.sub_domains || []), next],
      };
    }
    return acc;
  }, {} as any);
  return Object.values(res);
};

export interface DomainWithSubsFull<T extends DomainBase> {
  domain: T;
  sub_domains: { sub_domain: T; questions: QuestionResponse[] }[];
}

export const getDomainsWithSubsFull = <T extends DomainBase>(
  domains: T[],
  questions: QuestionResponse[]
): DomainWithSubsFull<T>[] => {
  let res = domains.reduce((acc, next: T) => {
    if (!next.parent_id) {
      acc[next.id] = {
        ...(acc[next.id] || {}),
        domain: next,
      };
    } else {
      const sub_domains = acc[next.parent_id]?.sub_domains || [];
      const domain_questions = questions.filter((q) => q.domain_id === next.id);

      sub_domains.push({
        sub_domain: next,
        questions: domain_questions,
      });
      acc[next.parent_id] = {
        ...(acc[next.parent_id] || {}),
        sub_domains: sub_domains,
      };
    }
    return acc;
  }, {} as any);
  return Object.values(res);
};
