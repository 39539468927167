import { Button, Modal } from "@getprorecrutement/getpro-design";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { useDeepCompareEffect } from "react-use";
import { fetchLicense, License } from "../models/license";
import { Paginated } from "../models/paginated";
import { fetchUsers, impersonateUser, UserFilters, UserResponse } from "../models/user";
import { PageHeader } from "../modules/pageHeader";
import { UserForm } from "../modules/userForm";
import { UserTable } from "../modules/users";
import store, { Stored } from "../services/store";
import { JWT } from "../models/auth";
import JwtDecode from "jwt-decode";

export const LicensePage = () => {
  const { id } = useParams();
  const [license, setLicense] = useState<License>();
  const [users, setUsers] = useState<Paginated<UserResponse>>();
  const [userForm, setUserForm] = useState<Partial<UserResponse>>();
  const [filters, setFilters] = useState<UserFilters>({ license_id: id, page: 1, per_page: 10 });

  useEffect(() => {
    if (id) {
      fetchLicense(id).then(setLicense);
      setFilters((f) => ({ ...f, license_id: id }));
    }
  }, [id]);

  useDeepCompareEffect(() => {
    fetchUsers(filters).then(setUsers);
  }, [filters]);

  const impersonate = async (userId: Uuid) => {
    const token = await impersonateUser(userId);
    store.update(Stored.ImpersonatedJWT, JwtDecode(token) as JWT);
    store.update(Stored.RawImpersonatedJWT, token);
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={license?.name}
        rightSide={
          <Button
            title="Ajouter un utilisateur"
            icon={<PlusCircleIcon />}
            onClick={() => setUserForm({})}
            dark
            kind="outline"
            size="small"
          />
        }
      />
      <div className="bg-white p-8 flex-grow">
        <div className="flex gap-8 flex-wrap justify-center bg-inherit">
          <UserTable
            users={users}
            filters={filters}
            setFilters={setFilters}
            onEdit={setUserForm}
            onImpersonate={impersonate}
          />
        </div>
      </div>
      <Modal
        className="bg-white"
        show={!!userForm}
        onClose={() => setUserForm(undefined)}
        style={{ width: "fit-content" }}
      >
        <div className="border-border-lighter rounded-3xl bg-inherit w-[600px]">
          <div className="flex justify-between">
            <ArrowLeftIcon
              className="text-slate-400 cursor-pointer"
              onClick={() => setUserForm(undefined)}
              width={24}
              height={24}
            />
            <div className="-ml-6 text-xl text-content-darker font-semibold">
              {userForm?.id ? "Editer un utilisateur" : "Créer un utilisateur"}
            </div>
            <div />
          </div>
          <div className="mt-6 bg-inherit">
            <UserForm
              user={userForm}
              licenseId={id}
              onFinish={() => {
                fetchUsers(filters).then(setUsers);
                setUserForm(undefined);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
