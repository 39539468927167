import { Button, ColorType, Modal, MoreActions, Pagination, Radio } from "@getprorecrutement/getpro-design";
import { ChevronDownIcon, DownloadIcon, LinkIcon, PaperAirplaneIcon, ReplyIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AssessmentResultFilter } from "../models/assessmentResult";
import { AssessmentReportTarget, LicenseAssessmentReport } from "../models/licenseAssessment";
import { Paginated, PaginationParams } from "../models/paginated";
import {
  fetchProjectAssessments,
  findProject,
  generatePublicToken,
  Project,
  ProjectAssessmentsResponse,
} from "../models/project";
import {
  fetchParticipants,
  ProjectParticipantReminderFilters,
  ProjectParticipantResponse,
  ProjectParticipantStatus,
  sendBatchReminder,
  sendReminder,
} from "../models/projectParticipant";
import { downloadReport } from "../models/report";
// import { InvitationTable } from "../modules/assessments/invitation";
import { ProjectParticipantsTable } from "../modules/assessments/projectParticipantTable";
import { InvitationForm } from "../modules/forms/invitationForm";
import { PageHeader } from "../modules/pageHeader";
import { loadable } from "../services/httpClient";
import store from "../services/store";

enum Tabs {
  FINISHED = "finished",
  PROGRESS = "progress",
  INVITATIONS = "invitations",
}

export const ProjectPage = () => {
  const [resultPage, setResultPage] = useState<PaginationParams>({
    page: 1,
  });
  const [project, setProject] = useState<Project>();
  const [participants, setParticipants] = useState<Paginated<ProjectParticipantResponse>>();
  const [projectAssessments, setProjectAssessments] = useState<ProjectAssessmentsResponse[]>();
  const [invitationModal, setInvitationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState<Tabs>();
  const [status, setStatus] = useState<ProjectParticipantStatus>();
  const [resultFilter, setResultFilter] = useState<AssessmentResultFilter>();

  const [selected, setSelected] = useState<Uuid[]>([]);
  const [manualySelected, setManualySelected] = useState<Uuid[]>();
  const [manualyExcluded, setManualyExcluded] = useState<Uuid[]>();
  const [multiSelectionPeriod, setMultiSelectionPeriod] = useState<{ min?: number; max?: number }>();

  const [selectedPopulation, setSelectedPopulation] = useState<"candidate" | "reference">("candidate");

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isCultureFit = useMemo(() => {
    return projectAssessments?.some((a) => a.assessment_type === "culture_fit");
  }, [projectAssessments]);

  useEffect(() => {
    if (multiSelectionPeriod) {
      setManualyExcluded(undefined);
      setManualySelected(undefined);
    }
  }, [multiSelectionPeriod]);

  useEffect(() => {
    if (participants) {
      let res: ProjectParticipantResponse[] = [];
      if (multiSelectionPeriod) {
        let filteredParticpants = [...participants.data];
        filteredParticpants = filteredParticpants.filter((e) => {
          let day_elapsed = dayjs().set("hour", 0).diff(dayjs.utc(e.invitation_sent_at).local().set("hour", 0), "days");
          if (multiSelectionPeriod.min && multiSelectionPeriod.min > day_elapsed) return false;
          if (multiSelectionPeriod.max && multiSelectionPeriod.max < day_elapsed) return false;
          return true;
        });
        res = res.concat(filteredParticpants);
      }
      let selected = res.map((p) => p.id).concat(manualySelected || []);
      setSelected(selected.filter((selected) => !manualyExcluded?.includes(selected)));
    }
  }, [manualySelected, multiSelectionPeriod, participants, manualyExcluded]);

  useEffect(() => {
    const tab = location.hash.slice(1);
    if (!Object.values(Tabs).includes(tab as Tabs)) {
      return navigate("#finished");
    }
    let selectedTab = tab as Tabs;
    setSelectedTab(selectedTab);
    let status =
      selectedTab === Tabs.FINISHED
        ? ProjectParticipantStatus.Finished
        : selectedTab === Tabs.PROGRESS
        ? ProjectParticipantStatus.Pending
        : ProjectParticipantStatus.Invited;
    setStatus(status);
  }, [location, navigate]);

  const fetchData = useCallback(async () => {
    if (id) {
      const [project, projectAssessments] = await Promise.all([findProject(id), fetchProjectAssessments(id)]);
      setProject(project);
      setProjectAssessments(projectAssessments);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setMultiSelectionPeriod(undefined);
  }, [status]);

  const onUpdate = useCallback(() => {
    if (project && status) {
      loadable(
        fetchParticipants(
          {
            status: status,
            ...resultPage,
            ...resultFilter,
            reference_participant: selectedPopulation === "reference",
          },
          project.id
        ).then(setParticipants),
        setLoading
      );
    }
  }, [project, resultPage, status, resultFilter, selectedPopulation]);

  useEffect(() => {
    onUpdate();
  }, [onUpdate]);

  const onSendBatchRemonder = () => {
    if (!project || !status || !selected.length) return;
    let filters: ProjectParticipantReminderFilters = {
      status: status,
      excluded_ids: manualyExcluded,
      included_ids: manualySelected,
      min: multiSelectionPeriod?.min,
      max: multiSelectionPeriod?.max,
    };
    loadable(sendBatchReminder(project.id, filters), setLoading);
  };

  const actions = [
    {
      title: "Tous",
      action: () => setMultiSelectionPeriod({ min: 0 }),
    },
    {
      title: "Aucun",
      action: () => {
        setMultiSelectionPeriod(undefined);
        setSelected([]);
        setManualySelected(undefined);
      },
    },
    {
      title: "Inférieure à 5 jours",
      action: () => {
        setMultiSelectionPeriod({ max: 5 });
      },
    },
    {
      title: "Entre 5 et 15 jours",
      action: () => {
        setMultiSelectionPeriod({ min: 5, max: 15 });
      },
    },
    {
      title: "Supérieur à 15 jours",
      action: () => {
        setMultiSelectionPeriod({ min: 15 });
      },
    },
  ];

  const rightSideHeaderButtons = () => {
    if (!project) return [];
    let buttons: any[] = [
      <Button
        colorType={ColorType.Content}
        title="Envoyer des invitations"
        icon={<PaperAirplaneIcon className="rotate-90" />}
        dark
        size="small"
        onClick={() => setInvitationModal(true)}
      />,
    ];
    if ((store.state.ImpersonatedJWT || store.state.JWT)?.admin) {
      if (project.public_token) {
        buttons.push(
          <Button
            icon={<LinkIcon />}
            dark
            kind="outline"
            size="small"
            key="copy-public-link-btn"
            title={"Copier le lien public"}
            onClick={() => copyTokenLink(project.public_token as string)}
          />
        );
      } else {
        buttons.push(
          <Button
            icon={<LinkIcon />}
            key="public-link-btn"
            dark
            kind="outline"
            size="small"
            title={"Créer un lien public"}
            onClick={async () => {
              const p = await generatePublicToken(project.id);
              setProject(p);
              copyTokenLink(p.public_token as string);
            }}
          />
        );
      }
    }

    return buttons;
  };

  const setPagination = (pagination: Partial<PaginationParams>) => {
    setResultPage({ ...resultPage, ...pagination });
  };

  const onSendReminder = async (id: Uuid) => {
    await sendReminder(id);
    onUpdate();
  };

  const renderResults = project && (
    <ProjectParticipantsTable
      selected={selected}
      selectAll={() => {
        if (multiSelectionPeriod?.min === 0) {
          setMultiSelectionPeriod(undefined);
          setManualySelected(undefined);
        } else {
          setMultiSelectionPeriod({ min: 0 });
        }
      }}
      select={(item) => {
        if (manualySelected?.includes(item.id)) {
          setManualySelected(manualySelected.filter((s) => s !== item.id));
          setManualyExcluded([item.id, ...(manualyExcluded || [])]);
        } else if (selected.includes(item.id)) {
          setManualyExcluded([...(manualyExcluded || []), item.id]);
        } else {
          if (manualyExcluded?.includes(item.id)) {
            setManualyExcluded(manualyExcluded.filter((exluded) => exluded !== item.id));
          }
          setManualySelected([item.id, ...(manualySelected || [])]);
        }
      }}
      loading={loading}
      status={status}
      project={project}
      filters={resultFilter}
      assessments={projectAssessments || []}
      onRelauch={onSendReminder}
      onFilterChange={(filter) => setResultFilter(filter)}
      participants={participants}
      onDelete={async (_: Uuid) => {
        // await deleteAssessmentResult(id);
        // onUpdate();
      }}
    />
  );
  const copyTokenLink = (token: string) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_APP_URL}/invitation/${token}`);
    toast.success("Le lien public de l'évaluation à été copié dans votre presse papier");
  };
  const downloadGlobalReport = (licenseAssessmentReport: LicenseAssessmentReport) => {
    if (!project) return;
    downloadReport(project.id, licenseAssessmentReport.id);
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={project?.name}
        subTitle={
          <div className="flex flex-col justify-center items-center">
            <div className="text-white">{project && dayjs.utc(project.created_at).local().format("DD MMMM YYYY")}</div>
            <div className="flex justify-center items-center mt-5 gap-2">
              {projectAssessments?.map((assess) => (
                <div
                  key={`${project?.id}${assess.assessment_name}`}
                  className="bg-primary-lightest text-content-darker font-bold rounded-full px-2 py-1 text-xs"
                >
                  {assess.assessment_name}
                </div>
              ))}
            </div>
          </div>
        }
        leftSide={
          <Button
            title="Retour aux projets"
            icon={<ReplyIcon />}
            onClick={() => navigate(`/`)}
            dark
            kind="outline"
            size="small"
          />
        }
        rightSide={rightSideHeaderButtons()}
      />
      <div className="bg-white flex-grow flex justify-center">
        <div className=" p-8 max-w-[1600px] w-full">
          <div className="mb-4 flex justify-between md:flex-row flex-col">
            <Radio
              options={[
                { value: "finished", label: "Terminées" },
                { value: "progress", label: "En cours" },
                { value: "invitations", label: "En attente" },
              ]}
              onChange={(val?: string) => val && navigate(`#${val}`)}
              optionType="button"
              light
              value={selectedTab}
            />
            {isCultureFit && projectAssessments && (
              <div className="flex items-center gap-2">
                <MoreActions
                  icon={<DownloadIcon />}
                  actions={projectAssessments
                    .map((p) =>
                      p.reports
                        .filter((p) => p.target?.includes(AssessmentReportTarget.Global))
                        .map((report) => ({
                          action: () => downloadGlobalReport(report),
                          title: report.name,
                        }))
                    )
                    .flat()}
                />

                <Radio
                  options={[
                    { value: "reference", label: "Population Référence" },
                    { value: "candidate", label: "Candidats" },
                  ]}
                  onChange={(s) => s && setSelectedPopulation(s as "reference" | "candidate")}
                  optionType="button"
                  light
                  value={selectedPopulation}
                />
              </div>
            )}
          </div>
          {selectedTab !== Tabs.FINISHED && (
            <div className="flex justify-end gap-3 items-center">
              <MoreActions actions={actions} title="Relancer les participants">
                <ChevronDownIcon height={24} width={24} />
              </MoreActions>
              <Button
                disabled={!selected.length}
                onClick={onSendBatchRemonder}
                title="Relancer la sélection"
                kind="outline"
                size="small"
                colorType={ColorType.Content}
              />
            </div>
          )}
          {renderResults}
          <div className="flex justify-end mt-4 ">
            <Pagination
              pageSize={resultPage.per_page}
              page={resultPage.page}
              onChange={(page) => setPagination({ page: page })}
              total={participants?.total || 0}
              light
              pageParamsActions={[
                {
                  action: () => setPagination({ per_page: 10 }),
                  title: "Afficher par 10",
                },
                {
                  action: () => setPagination({ per_page: 20 }),
                  title: "Afficher par 20",
                },
                {
                  action: () => setPagination({ per_page: 30 }),
                  title: "Afficher par 30",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Modal show={!!(invitationModal && project?.id)} onClose={() => setInvitationModal(false)}>
        <InvitationForm
          isCultureFit={isCultureFit}
          projectId={project?.id as Uuid}
          onClose={() => setInvitationModal(false)}
        />
      </Modal>
    </div>
  );
};
