import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { ReportKind } from "./report";

export interface DomainReportDescriptionData<T> {
  readonly id: Uuid;
  readonly domain_template_id: Uuid;
  readonly report_id: Uuid;
  readonly created_at: string;
  readonly parent_template_id?: Uuid;
  readonly name: string;
  readonly label: string;
  descriptions: T;
}

export interface BigFiveCandidateDescription {
  natural_label: string;
  inverse_label: string;
  description: string;
  short_description?: string;
  high_score_description?: string;
  low_score_description?: string;
}

export interface BigFiveClientDescription {
  natural_label: string;
  inverse_label: string;
  description: string;
  short_description?: string;
  score_based_descriptions?: ScoreBasedDescription[];
}

export interface OnePagerDescription {
  natural_label: string;
  inverse_label: string;
  description: string;
  score_based_descriptions?: ScoreBasedDescription[];
}

export interface ScoreBasedDescription {
  description: string;
  potential: string;
  risk: string;
}

export interface CultureFitDescription {
  description: string,
  low_fit_bound?: number;
  neutral_bound?: number;
}

export interface CultureFitCompanyDescription {
  description: string;
}


export type BigFiveClientDomain = DomainReportDescriptionData<BigFiveClientDescription>;
export type BigFiveCandidatetDomain = DomainReportDescriptionData<BigFiveCandidateDescription>;
export type OnePagerDomain = DomainReportDescriptionData<OnePagerDescription>;
export type CultureFitDomain = DomainReportDescriptionData<CultureFitDescription>;
export type CompanyCultureFitDomain = DomainReportDescriptionData<CultureFitCompanyDescription>;

enum ReportDescriptionKind {
  BigFiveClient = "big_five_client",
  BigFiveCandidate = "big_five_candidate",
  BigFiveOnePager = "big_five_one_pager",
  CultureFit = "culture_fit",
  CompanyCultureFit = "company_culture_fit"
}

export interface DomainDescriptions {
  [ReportDescriptionKind.BigFiveClient]?: BigFiveClientDomain[];
  [ReportDescriptionKind.BigFiveCandidate]?: BigFiveCandidatetDomain[];
  [ReportDescriptionKind.BigFiveOnePager]?: OnePagerDomain[];
  [ReportDescriptionKind.CultureFit]?: CultureFitDomain[];
  [ReportDescriptionKind.CompanyCultureFit]?: CompanyCultureFitDomain[];
}

export interface DomainDescription {
  [ReportDescriptionKind.BigFiveClient]?: BigFiveClientDomain;
  [ReportDescriptionKind.BigFiveCandidate]?: BigFiveCandidatetDomain;
  [ReportDescriptionKind.BigFiveOnePager]?: OnePagerDomain;
  [ReportDescriptionKind.CultureFit]?: CultureFitDomain;
  [ReportDescriptionKind.CompanyCultureFit]?: CompanyCultureFitDomain;

}

export const get_description_kind = (kind: ReportKind): ReportDescriptionKind => {
  switch (kind) {
    case ReportKind.BigFiveCandidate:
      return ReportDescriptionKind.BigFiveCandidate;
    case ReportKind.BigFiveClient:
      return ReportDescriptionKind.BigFiveClient;
    case ReportKind.BigFiveCandidateOnePager:
      return ReportDescriptionKind.BigFiveOnePager;
    case ReportKind.BigFiveClientOnePager:
      return ReportDescriptionKind.BigFiveOnePager;
    case ReportKind.ClientCultureFit:
      return ReportDescriptionKind.CultureFit;
    case ReportKind.CandidateCultureFit:
      return ReportDescriptionKind.CultureFit;
    case ReportKind.CompanyCultureFit:
      return ReportDescriptionKind.CompanyCultureFit;

  }
};

export const fetchDescriptions = (reportId: Uuid) => httpClient.req(ROUTES.FETCH_DOMAIN_DESCRIPTIONS(reportId));
export const updateDescription = (reportId: Uuid, description: DomainDescription) =>
  httpClient.req(ROUTES.UPDATE_DOMAIN_DESCRIPTION(reportId, description)).then((res) => {
    toast.success("Descriptions mises à jour avec succès");
    return res;
  });
