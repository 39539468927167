import { Modal } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState } from "react";
import { AssessmentVersionResponse } from "../../../models/assessment";
import { Calibration, clearDomainCalibration, Domain, updateDomain } from "../../../models/domain";
import { QuestionResponse } from "../../../models/question";
import { DomainWithSubsFull } from "../../../utils/domains";
import { EditDomainCalibrationForm } from "../../forms/editDomainCalibrationForm";
import { DomainTree } from "../domain";

interface DomainsInfoProps {
  assessmentVersion: AssessmentVersionResponse;
  domainQuestions: DomainWithSubsFull<Domain>[];
  edition?: boolean;
  onUpdate: (domain: Domain) => void;
  setEditingQuestion: (question: QuestionResponse) => void;
  deleteUnassignedQuestion: (id: Uuid) => void;
}

export const DomainsInfo: FunctionComponent<DomainsInfoProps> = ({
  assessmentVersion,
  domainQuestions,
  edition,
  onUpdate,
  setEditingQuestion,
  deleteUnassignedQuestion,
}) => {
  const [domainToCalibrate, setDomainToCalibrate] = useState<Domain>();

  const onDomainCalibrationUpdate = async (data: Calibration) => {
    if (!domainToCalibrate) return;
    const updatedDomain = await updateDomain(domainToCalibrate.id, {
      ...domainToCalibrate,
      calibration: data,
    });
    setDomainToCalibrate(undefined);
    onUpdate(updatedDomain);
  };

  const onDomainCalibrationClear = async () => {
    if (!domainToCalibrate) return;
    const updatedDomain = await clearDomainCalibration(domainToCalibrate.id);
    setDomainToCalibrate(undefined);
    onUpdate(updatedDomain);
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        {domainQuestions
          ?.sort((a, b) => a.domain.name.localeCompare(b.domain.name))
          .map((domain, i) => (
            <DomainTree
              key={i}
              domain={domain}
              edition={edition}
              onEditCalibration={(domain) => setDomainToCalibrate(domain)}
              removeQuestion={(id) => deleteUnassignedQuestion(id)}
              onEditQuestion={(question) => setEditingQuestion(question)}
            />
          ))}
      </div>
      <Modal
        className="bg-white"
        show={!!domainToCalibrate}
        onClose={() => {
          setDomainToCalibrate(undefined);
        }}
      >
        <EditDomainCalibrationForm
          domain={domainToCalibrate}
          onFinish={(edition && onDomainCalibrationUpdate) || undefined}
          onClear={(edition && onDomainCalibrationClear) || undefined}
        />
      </Modal>
    </div>
  );
};
