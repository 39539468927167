import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { PaginationParams } from "./paginated";

export interface ProjectParticipantResponse {
  id: Uuid;
  participant_id: Uuid;
  first_name?: string;
  last_name?: string;
  email: string;
  birth_date?: string;
  gender?: string;
  invitation_sent: boolean;
  resend_invitation: boolean;
  invitation_sent_at?: string;
  started_at?: string;
  finished_at?: string;
  project_id: Uuid;
  created_at: string;
  updated_at: string;
  finished_count: number;
}

export enum ProjectParticipantStatus {
  Finished = "finished",
  Pending = "pending",
  Invited = "invited",
}

export interface ProjectParticipantFilters {
  status?: ProjectParticipantStatus;
  email?: string;
  name?: string;
  reference_participant?: boolean;
}

export interface ProjectParticipantReminderFilters {
  status: ProjectParticipantStatus;
  excluded_ids?: Uuid[];
  included_ids?: Uuid[];
  min?: number;
  max?: number;
}

export const fetchParticipants = (params: ProjectParticipantFilters & PaginationParams, projectId: Uuid) =>
  httpClient.req(ROUTES.FETCH_PROJECT_PARTICIPANTS(params, projectId));
export const sendReminder = (id: Uuid) =>
  httpClient.req(ROUTES.SEND_INVITATION_REMIDER(id)).then((res) => {
    toast.success("Utilisateur relancé avec succés");
    return res;
  });
export const sendBatchReminder = (projectId: Uuid, filters: ProjectParticipantReminderFilters) =>
  httpClient.req(ROUTES.SEND_BATCH_INVITATION_REMIDER(projectId, filters));

// export const sendReminder = (projectId: Uuid, id: Uuid) => httpClient.req(ROUTES.FETCH_PARTICIPANTS(params, projectId));
