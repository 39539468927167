import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export interface Model {
  id: Uuid;
  name: string;
  created_at: string;
  assessments_count: number;
}

export const fetchModel = (id: Uuid) => httpClient.req(ROUTES.FETCH_MODEL(id));

export const fetchModels = () => httpClient.req(ROUTES.FETCH_MODELS());
