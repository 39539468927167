import { Checkbox, ColorType, MoreActions, Pagination, Table } from "@getprorecrutement/getpro-design";
import { DotsVerticalIcon, EyeIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import dayjs from "dayjs";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssessmentResponse, fetchAssessments } from "../models/assessment";
import { LicenseFilters, LicenseResponse } from "../models/license";
import {
  AssessmentReportTarget,
  createLicenseAssessmentReport,
  deleteLicenseAssessmentReport,
  fetchLicenseAssessmentReports,
  LicenseAssessment,
  LicenseAssessmentReport,
  LicenseAssessmentStatus,
  LicenseAssessmentUpdate,
  NewLicenseAssessment,
  updateLicenseAssessmentReport,
} from "../models/licenseAssessment";
import { Paginated } from "../models/paginated";
import { fetchReports, reportLabel, ReportResponse } from "../models/report";

export interface LicenseTableProps {
  licenses?: Paginated<LicenseResponse>;
  onEdit: (val: Partial<LicenseResponse>) => void;
  onLicenseAssessmentCreate: (val: NewLicenseAssessment) => void;
  onLiceseAssessmentUpdate: (id: Uuid, val: LicenseAssessmentUpdate) => void;
  filters: LicenseFilters;
  setFilters: (filter: LicenseFilters) => void;
}

interface renderAssessmentListProps {
  licenseAssessments: LicenseAssessment[];
  assessments: AssessmentResponse[];
  reports: { [model_id: Uuid]: ReportResponse[] };
  licenseAssessmentReports: { [report_id: Uuid]: { [licenseAssessmentId: Uuid]: LicenseAssessmentReport } };
  onChange: (value: boolean, assessmentId: Uuid, licenseAssessment?: LicenseAssessment) => void;
  onLicenseAssessmentReportChange: (licenseAssessmentReport: LicenseAssessmentReport) => void;
  onLicenseAssessmentReportDelete: (licenseAssessmentReportId: Uuid) => void;
  onLicenseAssessmentReportCreate: (assessment_id: Uuid, licenseAssessmentId: Uuid, report: ReportResponse) => void;
}

const RenderAssessmentList = ({
  licenseAssessments,
  assessments,
  reports,
  licenseAssessmentReports,
  onChange,
  onLicenseAssessmentReportChange,
  onLicenseAssessmentReportDelete,
  onLicenseAssessmentReportCreate,
}: renderAssessmentListProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [enrichedAssessments, setEnrichedAssessments] = useState<
    { assessment: AssessmentResponse; licenseAssessment?: LicenseAssessment }[]
  >([]);

  useEffect(() => {
    let groupedLicenseAssessments: { [key: Uuid]: LicenseAssessment } = Object.fromEntries(
      licenseAssessments.map((elem) => [elem.assessment_id, elem])
    );
    setEnrichedAssessments(
      assessments.map((assessment) => {
        return {
          assessment,
          licenseAssessment: groupedLicenseAssessments[assessment.id] || undefined,
        };
      })
    );
  }, [licenseAssessments, assessments]);

  const assessmentClasses = classNames(
    "absolute top-10 left-0 p-5 bg-white rounded-xl shadow-lg z-10 text-content-darker text-xs flex flex-col gap-3",
    {
      hidden: !focused,
    }
  );
  return (
    <div
      className="relative cursor-pointer"
      onFocus={() => setFocused(true)}
      tabIndex={0}
      onBlur={() => setFocused(false)}
    >
      {licenseAssessments.filter((l) => l.status === LicenseAssessmentStatus.Active).length}
      <div className={assessmentClasses}>
        {enrichedAssessments.map((assessement, index) => {
          return (
            <div key={index}>
              <div className="flex justify-left items-center gap-2">
                <Checkbox
                  colorType={ColorType.Content}
                  value={assessement.licenseAssessment?.status === LicenseAssessmentStatus.Active}
                  onChange={(value) => onChange(value, assessement.assessment.id, assessement.licenseAssessment)}
                />
                <div>{assessement.assessment.name}</div>
              </div>
              {assessement.licenseAssessment?.status === LicenseAssessmentStatus.Active && (
                <div className="ml-5 flex flex-col gap-2 mt-3">
                  {reports[assessement.assessment.model_id]?.map((elem) => {
                    return (
                      <div key={elem.id}>
                        <div className="flex items-center gap-2">
                          <Checkbox
                            colorType={ColorType.Content}
                            value={
                              !!assessement.licenseAssessment &&
                              !!licenseAssessmentReports?.[elem.id]?.[assessement.licenseAssessment.id]
                            }
                            onChange={(v) => {
                              if (v) {
                                assessement.licenseAssessment &&
                                  onLicenseAssessmentReportCreate(
                                    assessement.assessment.id,
                                    assessement.licenseAssessment.id,
                                    elem
                                  );
                              } else {
                                assessement.licenseAssessment &&
                                  onLicenseAssessmentReportDelete(
                                    licenseAssessmentReports?.[elem.id]?.[assessement.licenseAssessment.id].id
                                  );
                              }
                            }}
                          />
                          <div>{reportLabel(elem)}</div>
                        </div>
                        {assessement.licenseAssessment &&
                          !!licenseAssessmentReports?.[elem.id]?.[assessement.licenseAssessment.id] && (
                            <div className="">
                              <div className=" my-2">Destinataire:</div>
                              <div className="ml-4 flex flex-col gap-2">
                                {Object.values(AssessmentReportTarget).map((target) => {
                                  return (
                                    <div className="flex items-center gap-2" key={target}>
                                      <Checkbox
                                        colorType={ColorType.Content}
                                        value={
                                          !!licenseAssessmentReports?.[elem.id]?.[
                                            assessement.licenseAssessment!.id // see line 113
                                          ].target?.includes(target as AssessmentReportTarget)
                                        }
                                        onChange={(v) => {
                                          let targets = v
                                            ? (
                                              licenseAssessmentReports?.[elem.id]?.[
                                                assessement.licenseAssessment!.id // see line 113
                                              ].target || []
                                            ).concat([target])
                                            : (
                                              licenseAssessmentReports?.[elem.id]?.[
                                                assessement.licenseAssessment!.id // see line 113
                                              ].target || []
                                            ).filter((t) => t !== target);
                                          onLicenseAssessmentReportChange({
                                            ...licenseAssessmentReports?.[elem.id]?.[assessement.licenseAssessment!.id],
                                            target: targets,
                                          });
                                        }}
                                      />
                                      {target === AssessmentReportTarget.Candidate && <div>candidat</div>}
                                      {target === AssessmentReportTarget.Client && <div>client</div>}
                                      {target === AssessmentReportTarget.Global && <div>global</div>}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LicenseTable: FunctionComponent<LicenseTableProps> = ({
  licenses,
  onEdit,
  filters,
  setFilters,
  onLicenseAssessmentCreate,
  onLiceseAssessmentUpdate,
}) => {
  const navigate = useNavigate();
  const [assessments, setAssessments] = useState<AssessmentResponse[]>();
  const [groupedReports, setGroupedReports] = useState<{ [model_id: Uuid]: ReportResponse[] }>({});
  const [groupedLicenseAssessmentReports, setGroupedLicenseAssessmentReports] = useState<{
    [reportId: Uuid]: { [licenseAssessmentId: Uuid]: LicenseAssessmentReport };
  }>({});

  const fetchData = useCallback(async () => {
    let assessments = await fetchAssessments();
    let reports = await fetchReports();
    let assessmentReports = await fetchLicenseAssessmentReports();

    setGroupedLicenseAssessmentReports(
      assessmentReports.reduce((acc, next) => {
        acc[next.report_id] = acc[next.report_id] || {};
        acc[next.report_id][next.license_assessment_id] = next;
        return acc;
      }, {} as { [reportId: Uuid]: { [licenseAssessmentId: Uuid]: LicenseAssessmentReport } })
    );
    setGroupedReports(
      reports.reduce((acc, next) => {
        (acc[next.model_id] = acc[next.model_id] || []).push(next);
        return acc;
      }, {} as { [model_id: Uuid]: ReportResponse[] })
    );
    setAssessments(assessments);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onLicenseAssessmentReportChange = async (licenseAssessmentReport: LicenseAssessmentReport) => {
    await updateLicenseAssessmentReport(licenseAssessmentReport.id, licenseAssessmentReport);
    await fetchData();
  };

  const onLicenseAssessmentReportDelete = async (licenseAssessmentReportId: Uuid) => {
    await deleteLicenseAssessmentReport(licenseAssessmentReportId);
    await fetchData();
  };

  const onLicenseAssessmentReportCreate = async (
    assessment_id: Uuid,
    licenseAssessmentId: Uuid,
    report: ReportResponse
  ) => {
    await createLicenseAssessmentReport({
      assessment_id,
      license_assessment_id: licenseAssessmentId,
      report_id: report.id,
      name: reportLabel(report),
    });
    await fetchData();
  };

  return (
    <div className="w-full max-w-[1600px] p-8">
      <Table
        colorType={ColorType.Content}
        light
        filter={{
          filters: { name: filters?.name },
          onFilterChange: (key, value) => {
            if (key === "name") {
              setFilters?.({ ...(filters || { page: 1 }), name: value });
            }
          },
        }}
        dataSource={{ data: licenses?.data || [], total: licenses?.total || 0 }}
        columns={[
          {
            title: "Nom",
            dataIndex: "name",
            key: "name",
            filterBy: "text",
            render: (value, record) => (
              <div className=" flex flex-col md:flex-row justify-left gap-2 items-center">
                <img
                  className="w-12 h-12 object-contain"
                  src={`${process.env.REACT_APP_GOOGLE_STORAGE_URL}/${record.logo_file_id}`}
                  alt="logo"
                />
                {value}
              </div>
            ),
          },
          {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (value) => (
              <div>
                <div className="py-5 hidden md:block">{dayjs.utc(value).local().format("DD MMMM YYYY")}</div>
                <div className="py-5 md:hidden">{dayjs.utc(value).local().format("DD/MM/YY")}</div>
              </div>
            ),
          },
          {
            title: "Évaluations",
            dataIndex: "license_assessments",
            key: "license_assessments",
            render: (value: LicenseAssessment[], record) => (
              <RenderAssessmentList
                onLicenseAssessmentReportChange={onLicenseAssessmentReportChange}
                reports={groupedReports}
                licenseAssessmentReports={groupedLicenseAssessmentReports}
                licenseAssessments={value}
                assessments={assessments || []}
                onChange={(value, assessmentId, licenseAssessment) => {
                  if (licenseAssessment) {
                    onLiceseAssessmentUpdate(licenseAssessment.id, {
                      ...licenseAssessment,
                      status: value ? LicenseAssessmentStatus.Active : LicenseAssessmentStatus.Cancelled,
                    });
                  } else {
                    onLicenseAssessmentCreate({
                      status: LicenseAssessmentStatus.Active,
                      assessment_id: assessmentId,
                      license_id: record.id,
                    });
                  }
                }}
                onLicenseAssessmentReportDelete={onLicenseAssessmentReportDelete}
                onLicenseAssessmentReportCreate={onLicenseAssessmentReportCreate}
              />
            ),
          },
          {
            title: "Utilisateurs",
            dataIndex: "users_count",
            key: "users_count",
            render: (value: number, record) => (
              <div className="rounded-lg group flex justify-left items-center gap-2">
                <div>{value}</div>
                <EyeIcon
                  height={24}
                  width={24}
                  className="p-1 rounded-full bg-background-bright cursor-pointer hidden group-hover:block"
                  onClick={() => navigate(`/licenses/${record.id}`)}
                />
              </div>
            ),
          },
          {
            title: "",
            dataIndex: "name",
            key: "actions",
            render: (value, record) => (
              <div className="flex justify-end">
                <MoreActions
                  icon={<DotsVerticalIcon />}
                  actions={[
                    {
                      action: () => onEdit({ id: record.id, name: record.name }),
                      title: "Éditer",
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <div className="flex justify-end mt-4 ">
        <Pagination
          light
          pageSize={filters.per_page}
          page={filters.page}
          onChange={(page) => setFilters({ ...filters, page })}
          total={licenses?.total || 0}
          pageParamsActions={[
            {
              action: () => setFilters({ ...filters, per_page: 10, page: 1 }),
              title: "Afficher par 10",
            },
            {
              action: () => setFilters({ ...filters, per_page: 20, page: 1 }),
              title: "Afficher par 20",
            },
            {
              action: () => setFilters({ ...filters, per_page: 30, page: 1 }),
              title: "Afficher par 30",
            },
          ]}
        />
      </div>
    </div>
  );
};
