import { Button, Modal, Tree } from "@getprorecrutement/getpro-design";
import { PencilIcon, ReplyIcon } from "@heroicons/react/outline";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BigFiveCandidateDescription,
  BigFiveClientDescription,
  DomainReportDescriptionData,
  OnePagerDescription,
  fetchDescriptions,
  get_description_kind,
  updateDescription,
  CultureFitDescription,
  CultureFitDomain,
  CultureFitCompanyDescription,
  BigFiveCandidatetDomain,
  BigFiveClientDomain,
  OnePagerDomain,
} from "../models/domainReportDescription";
import { findReport, ReportKind, reportLabel, ReportResponse } from "../models/report";
import { EditBigFiveDomainCandidateDescriptionsForm } from "../modules/forms/editDomainForms/BigFIve/editDomainCandidateDescriptionForm";
import { EditBigFiveDomainClientDescriptionsForm } from "../modules/forms/editDomainForms/BigFIve/editDomainClientDescriptionsForm";
import { PageHeader } from "../modules/pageHeader";
import { EditOnePagerDescriptionsFrom } from "../modules/forms/editDomainForms/BigFIve/editOnePagerDescriptionsFrom";
import { EditDomainCultureFit } from "../modules/forms/editDomainForms/CultureFit/editDomainCultureFit";
import { EditDomainCompanyCultureFit } from "../modules/forms/editDomainForms/CultureFit/editDomainCompanyCultureFit";

export interface ReportPageProps { }

type domainDescription = DomainReportDescriptionData<BigFiveClientDescription | CultureFitDescription | CultureFitDescription | BigFiveCandidateDescription>;

interface GroupedDomainDescription {
  parent: domainDescription;
  childs: domainDescription[];
}

interface DomainListProps {
  domain: GroupedDomainDescription;
  onEdit: (domain: domainDescription) => void;
}

export const DomainList: FunctionComponent<DomainListProps> = ({ domain, onEdit }) => {
  return (
    <div className="border border-border-lighter rounded-3xl p-5 w-1/4">
      <div>
        <div className="flex justify-left items-center gap-3 group">
          <div className="text-content-darker font-bold">
            {domain.parent.name} - {domain.parent.label}
          </div>
          <PencilIcon
            className="cursor-pointer hidden group-hover:block"
            height={18}
            width={18}
            onClick={() => onEdit(domain.parent)}
          />
        </div>
      </div>
      <Tree
        values={domain.childs}
        getKey={(v) => v.id}
        render={(elem) => {
          return (
            <div className="text-xs ml-2 flex justify-left items-center gap-3 group">
              <div>
                {elem.name} - {elem.label}
              </div>
              <PencilIcon
                className="cursor-pointer invisible group-hover:visible"
                height={12}
                width={12}
                onClick={() => onEdit(elem)}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

const EDIT_TEMPLATE = {
  [ReportKind.BigFiveCandidate]: EditBigFiveDomainCandidateDescriptionsForm,
  [ReportKind.BigFiveClient]: EditBigFiveDomainClientDescriptionsForm,
  [ReportKind.BigFiveCandidateOnePager]: EditOnePagerDescriptionsFrom,
  [ReportKind.BigFiveClientOnePager]: EditOnePagerDescriptionsFrom,
  [ReportKind.CompanyCultureFit]: EditDomainCompanyCultureFit,
  [ReportKind.CandidateCultureFit]: EditDomainCultureFit,
  [ReportKind.ClientCultureFit]: EditDomainCultureFit,
};


export const ReportPage: FunctionComponent<ReportPageProps> = () => {
  const { id } = useParams();
  const [report, setReport] = useState<ReportResponse>();
  const [domains, setDomains] = useState<GroupedDomainDescription[]>();
  const [editingTemplate, setEditingTemplate] =
    useState<
      DomainReportDescriptionData<BigFiveClientDescription | BigFiveCandidateDescription | OnePagerDescription | CultureFitDescription | CultureFitCompanyDescription>
    >();

  const navigate = useNavigate();

  const groupDescriptions = <T extends unknown>(vals: DomainReportDescriptionData<T>[]) => {
    let parents: DomainReportDescriptionData<T>[] = [];
    let childs = vals.reduce((acc, next) => {
      if (next.parent_template_id) {
        (acc[next.parent_template_id] = acc[next.parent_template_id] || []).push(next);
      } else {
        parents.push(next);
      }
      return acc;
    }, {} as { [key: Uuid]: DomainReportDescriptionData<T>[] });

    return parents
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((parent) => ({
        parent: parent,
        childs: childs[parent.domain_template_id].sort((a, b) => a.name.localeCompare(b.name)),
      }));
  };

  const onUpdate = useCallback(async () => {
    if (!id) return;
    let report = await findReport(id);
    let domains = await fetchDescriptions(report.id);
    const description_kind = get_description_kind(report.kind);
    let descriptions = domains[description_kind];
    if (descriptions) {
      setDomains(groupDescriptions(descriptions));
    }
    setReport(report);
  }, [id]);

  useEffect(() => {
    onUpdate();
  }, [onUpdate]);

  const EditComponent = report && EDIT_TEMPLATE[report.kind];

  const onDescriptionUpdate = async (
    description: BigFiveClientDescription | BigFiveCandidateDescription | CultureFitCompanyDescription | CultureFitDescription | OnePagerDescription
  ) => {
    if (!report) return;
    const description_kind = get_description_kind(report.kind);

    await updateDescription(report.id, { [description_kind]: { ...editingTemplate, descriptions: description } });
    setEditingTemplate(undefined);
    onUpdate();
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={report && `${report && reportLabel(report)} - ${report?.model_name}`}
        leftSide={
          <Button
            icon={<ReplyIcon />}
            onClick={() => navigate(`/reports`)}
            dark
            kind="outline"
            size="small"
            title="Retour aux rapports"
          />
        }
      />
      <div className="bg-white  flex-grow flex justify-center">
        <div className="p-8 max-w-[1600px] w-fit flex flex-wrap items-stretch gap-5">
          {domains?.map((domain, index) => (
            <DomainList domain={domain} key={index} onEdit={setEditingTemplate} />
          ))}
        </div>
      </div>
      <Modal className="bg-white" show={!!editingTemplate} onClose={() => setEditingTemplate(undefined)}>
        {EditComponent && <EditComponent onFinish={onDescriptionUpdate} domainDescription={editingTemplate as (CultureFitDescription & BigFiveCandidatetDomain & BigFiveClientDomain & OnePagerDomain & CultureFitDomain) | undefined} />}
      </Modal>
    </div>
  );
};
