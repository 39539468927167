import { Tree, Draggable, PopConfirm, ColorType } from "@getprorecrutement/getpro-design";
import { ChevronDownIcon, ChevronUpIcon, PencilIcon, TrashIcon, VariableIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Domain } from "../../models/domain";
import { QuestionResponse } from "../../models/question";

interface Props {
  domain: { domain: Domain; sub_domains: { sub_domain: Domain; questions: QuestionResponse[] }[] };
  reducible?: boolean;
  edition?: boolean;
  onEditQuestion?: (question: QuestionResponse) => void;
  onEditDescription?: (domain: Domain) => void;
  onEditCalibration?: (domain: Domain) => void;
  removeQuestion?: (id: Uuid) => void;
  onQuestionDragStart?: (question: QuestionResponse) => void;
  onQuestionDragEnd?: () => void;
}

export const DomainTree = ({
  domain,
  onEditQuestion,
  removeQuestion,
  onQuestionDragStart,
  onQuestionDragEnd,
  onEditDescription,
  onEditCalibration,
  reducible = true,
  edition = false,
}: Props) => {
  const [showTree, setShowTree] = useState<boolean>(!reducible);
  const [popConfirmState, setPopConfirmState] = useState<{ id: Uuid; show: boolean }[]>([]);

  useEffect(() => {
    let state: { id: Uuid; show: boolean }[] = [];

    domain.sub_domains.forEach((sub) => {
      sub.questions.forEach((q) => {
        state.push({ id: q.id, show: false });
      });
    });

    setPopConfirmState(state);
  }, [domain]);

  const showPopConfirm = (id: Uuid) => popConfirmState.find((q) => q.id === id)?.show || false;

  const renderElem = (val: { sub_domain: Domain; questions: QuestionResponse[] }): JSX.Element => {
    const size = domain.sub_domains.find((e) => e.sub_domain === val.sub_domain)?.questions.length;

    return (
      <div key={`tree-elem-${val.sub_domain.id}`} className="my-1 px-2 w-max rounded-full flex gap-2 items-center">
        <div className="text-content-darker text-sm">
          {val.sub_domain.name} - {val.sub_domain.label}
        </div>
        {onEditDescription && edition && (
          <PencilIcon
            className="cursor-pointer block"
            onClick={(ev) => {
              ev.stopPropagation();
              onEditDescription(val.sub_domain);
            }}
            height={20}
            width={20}
          />
        )}
        {onEditCalibration && (
          <VariableIcon
            className="cursor-pointer block"
            onClick={(ev) => {
              ev.stopPropagation();
              onEditCalibration(val.sub_domain);
            }}
            height={20}
            width={20}
          />
        )}
        <div className="ml-2 h-6 w-6 rounded-full bg-background-light text-xs flex justify-center items-center">
          {size}
        </div>
      </div>
    );
  };

  const renderSubElem = (val: { sub_domain: Domain; questions: QuestionResponse[] }): JSX.Element => {
    const questions = val.questions;

    return (
      <div
        key={`tree-sub-elem-${val.sub_domain.id}`}
        className="border border-solid border-border-lighter rounded-lg p-3 w-fit flex gap-3 flex-wrap"
      >
        {questions?.map((value) =>
          onQuestionDragStart && edition ? (
            <Draggable
              key={`tree-question-${value.id}`}
              onDragStart={() => onQuestionDragStart?.(value)}
              onDragEnd={() => onQuestionDragEnd?.()}
              optionalRenderer={() => {
                return <div className="bg-background-darker rounded-full p-2 text-white -rotate-6">{value.title}</div>;
              }}
            >
              {render_question(value, edition, onEditQuestion, removeQuestion)}
            </Draggable>
          ) : (
            <div key={`tree-question-${value.id}`}>
              {render_question(value, edition, onEditQuestion, removeQuestion)}
            </div>
          )
        )}
      </div>
    );
  };

  const render_question = (
    value: QuestionResponse,
    edition: boolean,
    onEditQuestion: ((question: QuestionResponse) => void) | undefined,
    removeQuestion: ((id: Uuid) => void) | undefined
  ): JSX.Element => {
    return (
      <div className="rounded-full px-4 py-2 bg-background-bright flex items-center group">
        <div className="text-sm">{value.title}</div>
        {edition && (
          <div className={`${showPopConfirm(value.id) ? "flex" : "hidden group-hover:flex"} cursor-pointer`}>
            {onEditQuestion && (
              <div
                className="ml-2"
                onClick={(ev) => {
                  onEditQuestion?.(value);
                }}
              >
                <PencilIcon width={16} />
              </div>
            )}
            {removeQuestion && (
              <div className="ml-2">
                <PopConfirm
                  light
                  title="Êtes-vous sûr de vouloir supprimer cette question ?"
                  colorType={ColorType.Content}
                  position="bottom-left"
                  onValidate={() => removeQuestion(value.id)}
                  onChange={(show) =>
                    setPopConfirmState((e) => [...e.filter((q) => q.id !== value.id), { id: value.id, show: show }])
                  }
                >
                  <TrashIcon width={16} />
                </PopConfirm>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={reducible ? "p-6 border border-solid border-border-lighter rounded-lg" : undefined}
      onClick={() => setShowTree(true)}
    >
      <div
        className="flex justify-between items-center cursor-pointer group"
        onClick={(e) => {
          e.stopPropagation();
          if (reducible) {
            setShowTree(!showTree);
          }
        }}
      >
        <div
          className={`flex items-center text-xl font-bold gap-2 group text-content-darker ${showTree ? "mb-4" : ""}`}
        >
          <div>
            {domain.domain.name} - {domain.domain.label}
          </div>
          {onEditDescription && (
            <PencilIcon
              className="cursor-pointer hidden group-hover:block"
              onClick={(ev) => {
                ev.stopPropagation();
                onEditDescription(domain.domain);
              }}
              height={20}
              width={20}
            />
          )}
          {onEditCalibration && (
            <VariableIcon
              className="cursor-pointer block"
              onClick={(ev) => {
                ev.stopPropagation();
                onEditCalibration(domain.domain);
              }}
              height={20}
              width={20}
            />
          )}
        </div>
        {reducible && (showTree ? <ChevronUpIcon width={20} className="-mt-5" /> : <ChevronDownIcon width={20} />)}
      </div>
      {showTree && (
        <Tree
          getKey={(d) => d.sub_domain.id}
          values={domain.sub_domains.sort((a, b) => a.sub_domain.name.localeCompare(b.sub_domain.name))}
          render={renderElem}
          renderSubElem={renderSubElem}
        />
      )}
    </div>
  );
};
