import { FunctionComponent, useEffect, useState } from "react";
import { AssessmentResponse, AssessmentUpdate } from "../../models/assessment";
import { Button, TextInput } from "@getprorecrutement/getpro-design";

export interface EditAssessmentFormProps {
  assessment?: AssessmentResponse;
  onFinish: (payload: AssessmentUpdate) => void;
}

export const EditAssessmentForm: FunctionComponent<EditAssessmentFormProps> = ({ assessment, onFinish }) => {
  const [label, setLabel] = useState<string>(assessment?.label || "");
  const [name, setName] = useState<string>(assessment?.name || "");
  useEffect(() => {
    if (assessment) {
      setLabel(assessment.label);
      setName(assessment.name);
    }
  }, [assessment]);

  const validate = () => {
    if (!label.length || !name.length) return;

    onFinish({ label, name });
  };

  return (
    <div className="bg-inherit">
      <div className="w-full flex justify-center items-center mb-10">
        <div className="font-bold text-xl text-content-darker">{assessment?.name}</div>
      </div>
      <TextInput
        light
        type="text"
        value={name}
        label="Nom de l'évaluation"
        placeholder="Nom de l'évaluation"
        onChange={({ target }) => {
          setName(target.value);
        }}
      />
      <TextInput
        light
        type="text"
        value={label}
        className="mt-5"
        label="Label de l'évaluation"
        placeholder="Label de l'évaluation"
        onChange={({ target }) => {
          setLabel(target.value);
        }}
      />
      <div className="flex w-full justify-end mt-10">
        <Button onClick={validate} title="Confirmer" disabled={label.length === 0} />
      </div>
    </div>
  );
};
