import { AxiosRequestConfig } from "axios";
import { AuthResponse, LoginPayload } from "../models/auth";
import { CalibrationImport, Domain, DomainData, NewDomainTemplate } from "../models/domain";
import { DomainDescription, DomainDescriptions } from "../models/domainReportDescription";
import { StoredFile } from "../models/file";
import { InvitationFilter } from "../models/invitation";
import { JobTitle } from "../models/jobTitle";
import { License, LicenseFilters, LicenseResponse, NewLicense, UpdateLicense } from "../models/license";
import { Model } from "../models/model";
import { OmnisearchResult } from "../models/omnisearch";
import { Paginated, PaginationParams } from "../models/paginated";
import {
  Participant,
  ParticipantAssessmentSummary,
  ParticipantProjectsSummary,
  ParticipantResponse,
  ParticipantResponseFilters,
} from "../models/particiapnt";
import {
  AddParticipantsPayload,
  InvitationResult,
  NewProject,
  Project,
  ProjectAssessmentsResponse,
  ProjectResponse,
  ProjectUpdate,
  QuickInvitePayload,
} from "../models/project";
import {
  ProjectParticipantFilters,
  ProjectParticipantReminderFilters,
  ProjectParticipantResponse,
} from "../models/projectParticipant";
import { NewQuestion, QuestionData, QuestionImport, QuestionResponse } from "../models/question";
import { ReportPayload, ReportResponse } from "../models/report";
import { SupportRequestBody } from "../models/support";
import { NewUser, UserFilters, UserResponse, UserUpdate } from "../models/user";
import {
  AssessmentResponse,
  AssessmentUpdate,
  AssessmentVersionResponse,
  NewAssessment,
  NewAssessmentVersionParams,
} from "./../models/assessment";
import { AssessmentResultFilter, AssessmentResultResponse, AssessmentResultStats } from "./../models/assessmentResult";
import { DomainTemplate } from "./../models/domain";
import {
  LicenseAssessment,
  LicenseAssessmentReport,
  LicenseAssessmentReportUpdate,
  LicenseAssessmentResponse,
  LicenseAssessmentUpdate,
  NewLicenseAssessment,
  NewLicenseAssessmentReport,
} from "./../models/licenseAssessment";

export enum Method {
  Get = "get",
  Post = "post",
  Patch = "patch",
  Delete = "delete",
}

export enum API {
  Haven,
  App,
}

export const URLS = {
  [API.Haven]: process.env.REACT_APP_HAVEN_URL,
  [API.App]: process.env.REACT_APP_API_URL,
};

export interface Route<T> {
  _phantomData?: T;
  api: API;
  path: string;
  method: Method;
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  auth?: boolean;
  file?: File;
  headers?: { Authorization?: string };
}

interface Routes {
  LOGIN: (data: LoginPayload) => Route<AuthResponse>;
  REFRESH_JWT: (token: string) => Route<AuthResponse>;
  FORGOT_PASSWORD: (email: string) => Route<{}>;
  RESET_PASSWORD: (token: string, data: { password: string }) => Route<{}>;
  FETCH_ASSESSMENT: (id: Uuid) => Route<AssessmentResponse>;
  CREATE_ASSESSMENT: (data: NewAssessment) => Route<AssessmentResponse>;
  FETCH_ASSESSMENTS: () => Route<AssessmentResponse[]>;
  FETCH_ASSESSMENT_VERSIONS: (id: Uuid) => Route<AssessmentVersionResponse[]>;
  FETCH_ASSESSMENT_RESULTS: (
    id: Uuid,
    params: AssessmentResultFilter & PaginationParams
  ) => Route<Paginated<AssessmentResultResponse>>;
  FETCH_ASSESSMENT_INVITATIONS: (id: Uuid, params: PaginationParams & InvitationFilter) => Route<Paginated<{}>>;
  FETCH_ASSESSMENT_INVITATIONS_IDS: (id: Uuid, params: { min?: number; max?: number }) => Route<Uuid[]>;
  FETCH_ASSESSMENT_VERSION_QUESTIONS: (id: Uuid) => Route<QuestionResponse[]>;
  FETCH_ASSESSMENT_VERSION_DOMAINS: (id: Uuid) => Route<Domain[]>;
  DOWNLOAD_ASSESSMENT_RESULTS: (id: Uuid, token: string) => Route<{}>;
  DOWNLOAD_PROJECT_RESULTS: (id: Uuid, token: string) => Route<{}>;
  DOWNLOAD_RESULTS: (id: Uuid, token: string) => Route<{}>;
  DOWNLOAD_REPORT: (params: ReportPayload) => Route<{}>;
  GENERATE_TMP_TOKEN: () => Route<{ token: string }>;
  IMPORT_QUESTIONS: (assessmentVersionId: Uuid, data: QuestionImport[]) => Route<QuestionResponse[]>;
  IMPORT_CALIBRATIONS: (assessmentVersionId: Uuid, data: CalibrationImport[]) => Route<Domain[]>;
  RELAUNCH_INVITATIONS: (assessmentId: Uuid, ids: Uuid[]) => Route<{}>;
  SEND_INVITATION_REMIDER: (id: Uuid) => Route<{}>;
  UPDATE_ASSESSMENT: (id: Uuid, fields: AssessmentUpdate) => Route<AssessmentResponse>;
  CREATE_NEW_ASSESSMENT_VERSION: (id: Uuid, data: NewAssessmentVersionParams) => Route<AssessmentVersionResponse>;
  PUBLISH_ASSESSMENT_VERSION: (id: Uuid) => Route<AssessmentVersionResponse>;
  GENERATE_PUBLIC_TOKEN: (id: Uuid) => Route<Project>;
  CREATE_QUESTION: (payload: NewQuestion) => Route<QuestionResponse>;
  UPDATE_QUESTION: (data: QuestionData & { id: Uuid }) => Route<QuestionResponse>;
  DELETE_PAGE: (assessmentVersionId: Uuid, page: number) => Route<{}>;
  UNASSIGN_QUESTION: (id: Uuid) => Route<{}>;
  REMOVE_QUESTION: (id: Uuid) => Route<{}>;
  UPDATE_DOMAIN: (id: Uuid, data: DomainData) => Route<Domain>;
  DELETE_DOMAIN: (id: Uuid) => Route<{}>;
  CREATE_DOMAIN: (payload: NewDomainTemplate) => Route<DomainTemplate>;
  CLEAR_DOMAIN_CALIBRATION: (id: Uuid) => Route<Domain>;
  FETCH_MODEL_DOMAINS: (id: Uuid) => Route<DomainTemplate[]>;
  IMPERSONATE: (id: Uuid) => Route<string>;
  FETCH_DOMAIN_DESCRIPTIONS: (report_id: Uuid) => Route<DomainDescriptions>;
  FETCH_MODELS: () => Route<Model[]>;
  FETCH_MODEL: (id: Uuid) => Route<Model>;
  FETCH_LICENSE_ASSESSMENTS: () => Route<LicenseAssessmentResponse[]>;
  FETCH_LICENSE_ASSESSMENT: (assessment_id: Uuid) => Route<LicenseAssessmentResponse>;
  DELETE_ASSESSMENT_RESULT: (id: Uuid) => Route<{}>;
  DELETE_ASSESSMENT: (id: Uuid) => Route<{}>;
  FETCH_USERS: (filters: UserFilters) => Route<Paginated<UserResponse>>;
  UPDATE_USER: (data: { id: Uuid } & UserUpdate) => Route<UserResponse>;
  CREATE_USER: (payload: NewUser) => Route<UserResponse>;
  FETCH_LICENSE: (id: Uuid) => Route<License>;
  FETCH_MY_LICENSE: () => Route<License>;
  FETCH_LICENSES: (filters: LicenseFilters) => Route<Paginated<LicenseResponse>>;
  CREATE_LICENSE: (payload: NewLicense) => Route<License>;
  UPDATE_LICENSE: (data: { id: Uuid } & UpdateLicense) => Route<License>;
  CREATE_LICENSE_ASSESSMENT: (payload: NewLicenseAssessment) => Route<LicenseAssessment>;
  UPDATE_LICENSE_ASSESSMENT: (id: Uuid, payload: LicenseAssessmentUpdate) => Route<LicenseAssessment>;
  FETCH_RESULT_STATS: () => Route<AssessmentResultStats[]>;
  CREATE_FILE: (file: File) => Route<StoredFile>;
  DELETE_FILE: (id: Uuid) => Route<{}>;
  CREATE_SUPPORT_REQUEST: (content: SupportRequestBody, file?: File) => Route<{}>;
  FETCH_LICENSE_ASSESSMENT_REPORTS_BY_ASSESSMENT: (licenseAssessmentId: Uuid) => Route<LicenseAssessmentReport[]>;
  FETCH_LICENSE_ASSESSMENT_REPORTS: () => Route<LicenseAssessmentReport[]>;
  FETCH_OWNED_LICENSE_ASSESSMENT_REPORTS: () => Route<LicenseAssessmentReport[]>;
  CREATE_LICENSE_ASSESSMENT_REPORT: (payload: NewLicenseAssessmentReport) => Route<LicenseAssessmentReport>;
  UPDATE_LICENSE_ASSESSMENT_REPORT: (
    id: Uuid,
    payload: LicenseAssessmentReportUpdate
  ) => Route<LicenseAssessmentReport>;
  DELETE_LICENSE_ASSESSMENT_REPORT: (id: Uuid) => Route<{}>;
  UPDATE_DOMAIN_DESCRIPTION: (report_id: Uuid, description: DomainDescription) => Route<DomainDescription>;
  FETCH_REPORTS: () => Route<ReportResponse[]>;
  FIND_REPORT: (id: Uuid) => Route<ReportResponse>;
  FETCH_PROJECTS: (params: PaginationParams) => Route<ProjectResponse[]>;
  FIND_PROJECT: (id: Uuid) => Route<Project>;
  UPDATE_PROJECT: (id: Uuid, params: ProjectUpdate) => Route<Project>;
  FETCH_PROJECT_ASSESSMENTS: (id: Uuid) => Route<ProjectAssessmentsResponse[]>;
  FETCH_PROJECT_PARTICIPANTS: (
    params: ProjectParticipantFilters & PaginationParams,
    projectId: Uuid
  ) => Route<Paginated<ProjectParticipantResponse>>;
  CREATE_PROJECT: (project: NewProject) => Route<Project>;
  INVITE: (projectId: Uuid, emails: AddParticipantsPayload) => Route<InvitationResult>;
  FETCH_PARTICIPANTS: (filters: PaginationParams & ParticipantResponseFilters) => Route<Paginated<ParticipantResponse>>;
  FIND_PARTICIPANT: (id: Uuid) => Route<Participant>;
  FIND_PARTICIPANT_JOB_TITLE: (id: Uuid) => Route<JobTitle>;
  GET_PARTICIPANT_ASSESSMENTS: (id: Uuid) => Route<ParticipantAssessmentSummary[]>;
  GET_PARTICIPANT_PROJECTS: (id: Uuid) => Route<ParticipantProjectsSummary[]>;
  SEND_BATCH_INVITATION_REMIDER: (projectId: Uuid, filters: ProjectParticipantReminderFilters) => Route<{}>;
  QUICK_INVITE: (payload: QuickInvitePayload) => Route<{}>;
  SEARCH: (input: string) => Route<OmnisearchResult[]>;
}

const ROUTES: Routes = {
  LOGIN: (data) => ({
    api: API.App,
    path: "/users/login/",
    method: Method.Post,
    auth: false,
    data,
  }),
  REFRESH_JWT: (token) => ({
    api: API.App,
    path: `/users/refresh/${token}/`,
    method: Method.Get,
    auth: false,
  }),
  FORGOT_PASSWORD: (email) => ({
    api: API.App,
    path: `/users/password/forgot/${email}/`,
    method: Method.Get,
    auth: false,
  }),
  RESET_PASSWORD: (token, data) => ({
    api: API.App,
    path: `/users/password/${token}/`,
    method: Method.Patch,
    auth: false,
    data,
  }),
  IMPERSONATE: (id: Uuid) => ({
    api: API.App,
    path: `/users/${id}/impersonate/`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_FILE: (file: File) => ({
    api: API.App,
    path: "/files",
    method: Method.Post,
    auth: true,
    file,
  }),
  DELETE_FILE: (id: Uuid) => ({
    api: API.App,
    path: `/files/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  SEARCH: (input: string) => ({
    api: API.App,
    path: `/search/`,
    method: Method.Get,
    params: { search: input },
    auth: true,
  }),
  FETCH_ASSESSMENT: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/${id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ASSESSMENTS: () => ({
    api: API.App,
    path: `/assessments`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_REPORTS: () => ({
    api: API.App,
    path: `/reports`,
    method: Method.Get,
    auth: true,
  }),
  FIND_REPORT: (id: Uuid) => ({
    api: API.App,
    path: `/reports/${id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_DOMAIN_DESCRIPTIONS: (report_id: Uuid) => ({
    api: API.App,
    path: `/reports/${report_id}/descriptions`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_DOMAIN_DESCRIPTION: (report_id: Uuid, data: DomainDescription) => ({
    api: API.App,
    path: `/reports/${report_id}/descriptions`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  FETCH_ASSESSMENT_VERSIONS: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/${id}/versions`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_PROJECT_PARTICIPANTS: (params: ProjectParticipantFilters & PaginationParams, projectId: Uuid) => ({
    api: API.App,
    path: `/projects/${projectId}/participants`,
    params,
    method: Method.Get,
    auth: true,
  }),
  INVITE: (projectId: Uuid, emails: AddParticipantsPayload) => ({
    api: API.App,
    path: `/projects/${projectId}/participants`,
    data: emails,
    method: Method.Post,
    auth: true,
  }),
  FETCH_PROJECT_ASSESSMENTS: (id: Uuid) => ({
    api: API.App,
    path: `/projects/${id}/assessments`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_PROJECTS: (params: PaginationParams) => ({
    api: API.App,
    path: `/projects`,
    params,
    method: Method.Get,
    auth: true,
  }),
  FETCH_PARTICIPANTS: (filters: PaginationParams & ParticipantResponseFilters) => ({
    api: API.App,
    path: `/participants`,
    params: filters,
    method: Method.Get,
    auth: true,
  }),
  FIND_PARTICIPANT: (id: Uuid) => ({
    api: API.App,
    path: `/participants/${id}`,
    method: Method.Get,
    auth: true,
  }),
  FIND_PARTICIPANT_JOB_TITLE: (id: Uuid) => ({
    api: API.App,
    path: `/participants/${id}/job_title`,
    method: Method.Get,
    auth: true,
  }),
  GET_PARTICIPANT_ASSESSMENTS: (id: Uuid) => ({
    api: API.App,
    path: `/participants/${id}/assessments`,
    method: Method.Get,
    auth: true,
  }),
  GET_PARTICIPANT_PROJECTS: (id: Uuid) => ({
    api: API.App,
    path: `/participants/${id}/projects`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_PROJECT: (project: NewProject) => ({
    api: API.App,
    path: `/projects`,
    method: Method.Post,
    data: project,
    auth: true,
  }),
  QUICK_INVITE: (payload: QuickInvitePayload) => ({
    api: API.App,
    path: `/projects/quick_invite`,
    method: Method.Post,
    data: payload,
    auth: true,
  }),
  FIND_PROJECT: (id: Uuid) => ({
    api: API.App,
    path: `/projects/${id}`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_PROJECT: (id: Uuid, data: ProjectUpdate) => ({
    api: API.App,
    path: `/projects/${id}`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  FETCH_LICENSE_ASSESSMENT: (assessment_id: Uuid) => ({
    api: API.App,
    path: `/assessments/${assessment_id}/license`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_LICENSE_ASSESSMENT_REPORTS_BY_ASSESSMENT: (licenseAssessmentId: Uuid) => ({
    api: API.App,
    path: `/license_assessments/${licenseAssessmentId}/reports`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_LICENSE_ASSESSMENT_REPORTS: () => ({
    api: API.App,
    path: `/license_assessment_reports`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_OWNED_LICENSE_ASSESSMENT_REPORTS: () => ({
    api: API.App,
    path: `/license_assessment_reports/owned`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_LICENSE_ASSESSMENT_REPORT: (data: NewLicenseAssessmentReport) => ({
    api: API.App,
    path: `/license_assessment_reports`,
    method: Method.Post,
    data,
    auth: true,
  }),
  UPDATE_LICENSE_ASSESSMENT_REPORT: (id: Uuid, data: LicenseAssessmentReportUpdate) => ({
    api: API.App,
    path: `/license_assessment_reports/${id}`,
    method: Method.Patch,
    data,
    auth: true,
  }),
  DELETE_LICENSE_ASSESSMENT_REPORT: (id: Uuid) => ({
    api: API.App,
    path: `/license_assessment_reports/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  CREATE_ASSESSMENT: (data: NewAssessment) => ({
    api: API.App,
    path: `/assessments`,
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_ASSESSMENT_RESULTS: (id: Uuid, params: AssessmentResultFilter & PaginationParams) => ({
    api: API.App,
    path: `/assessments/${id}/results`,
    method: Method.Get,
    params,
    auth: true,
  }),
  FETCH_RESULT_STATS: () => ({
    api: API.App,
    path: `/licenses/result_stats`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ASSESSMENT_INVITATIONS: (id: Uuid, params: PaginationParams & InvitationFilter) => ({
    api: API.App,
    path: `/assessments/${id}/invitations`,
    method: Method.Get,
    params,
    auth: true,
  }),
  FETCH_ASSESSMENT_INVITATIONS_IDS: (id: Uuid, params: { min?: number; max?: number }) => ({
    api: API.App,
    path: `/assessments/${id}/invitations/ids`,
    method: Method.Get,
    auth: true,
    params,
  }),
  FETCH_ASSESSMENT_VERSION_QUESTIONS: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/versions/${id}/questions`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ASSESSMENT_VERSION_DOMAINS: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/versions/${id}/domains`,
    method: Method.Get,
    auth: true,
  }),

  DOWNLOAD_ASSESSMENT_RESULTS: (id: Uuid, token: string) => ({
    api: API.App,
    path: `/score/assessment/${id}/${token}`,
    method: Method.Get,
  }),
  DOWNLOAD_PROJECT_RESULTS: (id: Uuid, token: string) => ({
    api: API.App,
    path: `/score/project/${id}/${token}`,
    method: Method.Get,
  }),
  DOWNLOAD_RESULTS: (id: Uuid, token: string) => ({
    api: API.App,
    path: `/score/results/${id}/${token}`,
    method: Method.Get,
  }),
  DOWNLOAD_REPORT: (params: ReportPayload) => ({
    api: API.App,
    path: `/score/report/`,
    params,
    method: Method.Get,
  }),
  GENERATE_TMP_TOKEN: () => ({
    api: API.App,
    path: `/meta/tmp_token`,
    method: Method.Get,
    auth: true,
  }),
  IMPORT_CALIBRATIONS: (assessmentVersionId: Uuid, data: CalibrationImport[]) => ({
    api: API.App,
    path: `/assessments/versions/${assessmentVersionId}/import_calibrations`,
    method: Method.Patch,
    auth: true,
    data,
  }),

  IMPORT_QUESTIONS: (assessmentVersionId: Uuid, data: QuestionImport[]) => ({
    api: API.App,
    path: `/assessments/versions/${assessmentVersionId}/import_questions`,
    method: Method.Post,
    auth: true,
    data,
  }),

  RELAUNCH_INVITATIONS: (assessmentId: Uuid, ids: Uuid[]) => ({
    api: API.App,
    path: `/assessments/${assessmentId}/invitations/resend`,
    method: Method.Post,
    auth: true,
    data: ids,
  }),
  SEND_INVITATION_REMIDER: (id: Uuid) => ({
    // send a reminder to an user that already begun the test
    api: API.App,
    path: `/projects/participants/${id}/send_reminder`,
    method: Method.Patch,
    auth: true,
  }),
  SEND_BATCH_INVITATION_REMIDER: (projectId: Uuid, filters: ProjectParticipantReminderFilters) => ({
    api: API.App,
    method: Method.Patch,
    path: `/projects/${projectId}/send_batch_reminder`,
    params: filters,
    auth: true,
  }),
  UPDATE_ASSESSMENT: (id: Uuid, fields: AssessmentUpdate) => ({
    api: API.App,
    path: `/assessments/${id}`,
    method: Method.Patch,
    auth: true,
    data: fields,
  }),
  CREATE_NEW_ASSESSMENT_VERSION: (id: Uuid, params: NewAssessmentVersionParams) => ({
    api: API.App,
    path: `/assessments/${id}/versions`,
    method: Method.Post,
    auth: true,
    data: params,
  }),
  PUBLISH_ASSESSMENT_VERSION: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/versions/${id}/publish`,
    method: Method.Post,
    auth: true,
  }),
  GENERATE_PUBLIC_TOKEN: (id: Uuid) => ({
    api: API.App,
    path: `/projects/${id}/generate_public_token`,
    method: Method.Patch,
    auth: true,
  }),
  CREATE_QUESTION: (data: NewQuestion) => ({
    api: API.App,
    path: `/questions`,
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_QUESTION: (data: QuestionData & { id: Uuid }) => ({
    api: API.App,
    path: `/questions/${data.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  DELETE_PAGE: (assessmentVersionId: Uuid, page: number) => ({
    api: API.App,
    path: `/assessment/versions/${assessmentVersionId}/delete_page/${page}`,
    method: Method.Delete,
    auth: true,
  }),
  UNASSIGN_QUESTION: (id: Uuid) => ({
    api: API.App,
    path: `/questions/${id}/unassign`,
    method: Method.Patch,
    auth: true,
  }),
  REMOVE_QUESTION: (id: Uuid) => ({
    api: API.App,
    path: `/questions/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_DOMAIN: (id: Uuid, data: DomainData) => ({
    api: API.App,
    path: `/domains/${id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CLEAR_DOMAIN_CALIBRATION: (id: Uuid) => ({
    api: API.App,
    path: `/domains/${id}/clear_calibration/`,
    method: Method.Patch,
    auth: true,
  }),
  DELETE_DOMAIN: (id: Uuid) => ({
    api: API.App,
    path: `/domain_templates/${id}`,
    method: Method.Delete,
    auth: true,
  }),

  CREATE_DOMAIN: (payload: NewDomainTemplate) => ({
    api: API.App,
    path: "/domain_templates",
    method: Method.Post,
    auth: true,
    data: payload,
  }),
  FETCH_MODEL_DOMAINS: (id: Uuid) => ({
    api: API.App,
    path: `/models/${id}/domains`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_MODELS: () => ({
    api: API.App,
    path: "/models",
    method: Method.Get,
    auth: true,
  }),
  FETCH_MODEL: (id: Uuid) => ({
    api: API.App,
    path: `/models/${id}`,
    method: Method.Get,
    auth: true,
  }),
  DELETE_ASSESSMENT_RESULT: (id: Uuid) => ({
    api: API.App,
    path: `/assessment_results/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_ASSESSMENT: (id: Uuid) => ({
    api: API.App,
    path: `/assessments/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  FETCH_USERS: (filters: UserFilters) => ({
    api: API.App,
    path: `/users`,
    method: Method.Get,
    auth: true,
    params: filters,
  }),
  UPDATE_USER: (data: { id: Uuid } & UserUpdate) => ({
    api: API.App,
    path: `/users/${data.id}/`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_USER: (payload: NewUser) => ({
    api: API.App,
    path: `/users`,
    method: Method.Post,
    auth: true,
    data: payload,
  }),
  FETCH_LICENSES: (filters: LicenseFilters) => ({
    api: API.App,
    path: `/licenses`,
    method: Method.Get,
    auth: true,
    params: filters,
  }),
  FETCH_LICENSE: (id: Uuid) => ({
    api: API.App,
    path: `/licenses/${id}/`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_MY_LICENSE: () => ({
    api: API.App,
    path: `/licenses/mine`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_LICENSE: (payload: NewLicense) => ({
    api: API.App,
    path: `/licenses`,
    method: Method.Post,
    auth: true,
    data: payload,
  }),
  FETCH_LICENSE_ASSESSMENTS: () => ({
    api: API.App,
    path: `/license_assessments`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_LICENSE_ASSESSMENT: (payload: NewLicenseAssessment) => ({
    api: API.App,
    path: `/license_assessments`,
    method: Method.Post,
    auth: true,
    data: payload,
  }),
  UPDATE_LICENSE_ASSESSMENT: (id: Uuid, payload: LicenseAssessmentUpdate) => ({
    api: API.App,
    path: `/license_assessments/${id}`,
    method: Method.Patch,
    auth: true,
    data: payload,
  }),
  UPDATE_LICENSE: (data: { id: Uuid } & UpdateLicense) => ({
    api: API.App,
    path: `/licenses/${data.id}/`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_SUPPORT_REQUEST: (data: SupportRequestBody, file?: File) => {
    let payload: any = data;
    if (!file) {
      payload = new FormData();
      let blob = new Blob([JSON.stringify(data)], { type: "application/json" });
      payload.append("data", blob, "");
    }

    return {
      api: API.App,
      path: `/support`,
      method: Method.Post,
      auth: true,
      data: payload,
      file,
    };
  },
};

export default ROUTES;
