import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import download from "../utils/download";
import { generateTmpToken } from "./assessment";

export enum ReportKind {
  BigFiveClient = "big_five_client",
  BigFiveCandidate = "big_five_candidate",
  BigFiveClientOnePager = "big_five_client_one_pager",
  BigFiveCandidateOnePager = "big_five_candidate_one_pager",
  CandidateCultureFit = "candidate_culture_fit",
  ClientCultureFit = "client_culture_fit",
  CompanyCultureFit = "company_culture_fit",
}

export interface ReportPayload {
  token: string;
  license_assessment_report_id: Uuid;
  project_id: Uuid;
  project_participant_id?: Uuid;
}

export interface ReportResponse {
  id: Uuid;
  model_id: Uuid;
  model_name: string;
  kind: ReportKind;
  created_at: string;
}

export const reportLabel = (report: ReportResponse) => {
  switch (report.kind) {
    case ReportKind.BigFiveCandidate:
      return "Rapport Candidat";
    case ReportKind.BigFiveClient:
      return "Rapport Client";
    case ReportKind.BigFiveClientOnePager:
      return "Rapport Client Résumé";
    case ReportKind.BigFiveCandidateOnePager:
      return "Rapport Candidat Résumé";
    case ReportKind.CompanyCultureFit:
      return "Rapport d'entreprise";
    case ReportKind.CandidateCultureFit:
      return "Rapport Culture Fit Candidat";
    case ReportKind.ClientCultureFit:
      return "Rapport Culture Fit Client";
  }
};

export const downloadReport = async (projectId: Uuid, licenseAssessmentReportId: Uuid, projectParticipantId?: Uuid) => {
  const { token } = await generateTmpToken();

  let payload: ReportPayload = {
    token,
    license_assessment_report_id: licenseAssessmentReportId,
    project_id: projectId,
    project_participant_id: projectParticipantId,
  };

  console.log(payload);

  let stringParams = Object.entries(payload)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");

  const url = `${httpClient.genUrl(ROUTES.DOWNLOAD_REPORT(payload))}?${stringParams}`;

  console.log(url);
  download(url);
};

export const fetchReports = () => httpClient.req(ROUTES.FETCH_REPORTS());
export const findReport = (id: Uuid) => httpClient.req(ROUTES.FIND_REPORT(id));
