import { QuestionResponse } from "../models/question";

export const getQuestionsPages = (questions: QuestionResponse[]): QuestionResponse[][] => {
  let max = 0;
  let res = questions.reduce((res: { [page: number]: QuestionResponse[] }, data) => {
    if (data.page) {
      res[data.page] = [...(res[data.page] || []), data];
      if (data.page > max) {
        max = data.page;
      }
    }
    return res;
  }, {});
  return Array.from(Array(max)).map((_, i) => {
    return res[i + 1] || [];
  });
};
