import { Button, Modal } from "@getprorecrutement/getpro-design";
import { useEffect, useState } from "react";
import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { fetchLicenses, LicenseResponse, LicenseFilters } from "../models/license";
import { Paginated } from "../models/paginated";
import { LicenseForm } from "../modules/licenseForm";
import { LicenseTable } from "../modules/licenses";
import { PageHeader } from "../modules/pageHeader";
import {
  createLicenseAssessment,
  LicenseAssessmentUpdate,
  NewLicenseAssessment,
  updateLicenseAssessment,
} from "../models/licenseAssessment";

export const LicensesPage = () => {
  const [licenses, setLicenses] = useState<Paginated<LicenseResponse>>();
  const [licenseForm, setLicenseForm] = useState<Partial<LicenseResponse>>();
  const [filters, setFilters] = useState<LicenseFilters>({ page: 1, per_page: 10 });

  useEffect(() => {
    fetchLicenses(filters).then(setLicenses);
  }, [filters]);

  const onLicenseAssessmentCreate = async (newLicenseAssessment: NewLicenseAssessment) => {
    await createLicenseAssessment(newLicenseAssessment);
    fetchLicenses(filters).then(setLicenses);
  };

  const onLiceseAssessmentUpdate = async (id: Uuid, licenseAssessmentUpdate: LicenseAssessmentUpdate) => {
    await updateLicenseAssessment(id, licenseAssessmentUpdate);
    fetchLicenses(filters).then(setLicenses);
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Licences"
        subTitle="Gestion des licences"
        rightSide={
          <Button
            title="Ajouter une licence"
            icon={<PlusCircleIcon />}
            onClick={() => setLicenseForm({})}
            dark
            kind="outline"
            size="small"
          />
        }
      />
      <div className="bg-white p-8 flex-grow">
        <div className="flex gap-8 flex-wrap justify-center bg-inherit w-full">
          <LicenseTable
            licenses={licenses}
            filters={filters}
            setFilters={setFilters}
            onEdit={setLicenseForm}
            onLicenseAssessmentCreate={onLicenseAssessmentCreate}
            onLiceseAssessmentUpdate={onLiceseAssessmentUpdate}
          />
        </div>
      </div>
      <Modal
        className="bg-white"
        show={!!licenseForm}
        onClose={() => setLicenseForm(undefined)}
        style={{ width: "fit-content" }}
      >
        <div className="border-border-lighter rounded-3xl bg-inherit w-[300px] md:w-[600px]">
          <div className="flex justify-between">
            <ArrowLeftIcon
              className="text-slate-400 cursor-pointer"
              onClick={() => setLicenseForm(undefined)}
              width={24}
              height={24}
            />
            <div className="-ml-6 text-xl text-content-darker font-semibold">
              {licenseForm?.id ? "Editer une licence" : "Créer une licence"}
            </div>
            <div />
          </div>
          <div className="mt-6 bg-inherit">
            <LicenseForm
              license={licenseForm}
              onFinish={() => {
                setFilters({ ...filters, page: 1 });
                setLicenseForm(undefined);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
