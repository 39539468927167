import {
  Button,
  ColorType,
  MoreActions,
  PopConfirm,
  Table,
  Tag,
  TagColor,
  Tooltip,
} from "@getprorecrutement/getpro-design";
import { ClockIcon, DownloadIcon, RefreshIcon, TrashIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { AssessmentResultFilter } from "../../models/assessmentResult";
import { generateTmpToken } from "../../models/assessment";
import httpClient from "../../services/httpClient";
import ROUTES from "../../services/routes";
import { FunctionComponent, useEffect, useState } from "react";
import download from "../../utils/download";
import { Paginated } from "../../models/paginated";
import store from "../../services/store";
import { ColumnProps } from "@getprorecrutement/getpro-design/dist/components/Table";
import { ProjectParticipantResponse, ProjectParticipantStatus } from "../../models/projectParticipant";
import { Project, ProjectAssessmentsResponse } from "../../models/project";
import { useNavigate } from "react-router-dom";
import { AssessmentReportTarget } from "../../models/licenseAssessment";
import { downloadReport, ReportPayload } from "../../models/report";

interface ParticipantsTableProps {
  participants?: Paginated<ProjectParticipantResponse>;
  filters?: AssessmentResultFilter;
  status?: ProjectParticipantStatus;
  project: Project;
  assessments: ProjectAssessmentsResponse[];
  loading?: boolean;
  onRelauch?: (id: Uuid) => void;
  onFilterChange?: (filter: AssessmentResultFilter) => void;
  onDelete?: (id: Uuid) => void;
  selectAll: () => void;
  select: (value: ProjectParticipantResponse) => void;
  selected: Uuid[];
}

export const ProjectParticipantsTable: FunctionComponent<ParticipantsTableProps> = ({
  participants,
  filters,
  status,
  assessments,
  loading,
  selected,
  project,
  selectAll,
  select,
  onDelete,
  onRelauch,
  onFilterChange,
}) => {
  const [emailSearch, setEmailSearch] = useState<string>();
  const [nameSearch, setNameSearch] = useState<string>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", (_) => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  const downloadResults = async (id: Uuid) => {
    const token = await generateTmpToken();
    const url = httpClient.genUrl(ROUTES.DOWNLOAD_RESULTS(id, token.token));
    download(url);
  };

  const remindTagColor = (sent_at?: string) => {
    const delay = sent_at ? dayjs().diff(dayjs.utc(sent_at), "days") : 0;
    return delay >= 15 ? TagColor.Error : delay >= 5 ? TagColor.Warning : TagColor.Secondary;
  };


  return (
    <Table
      selection={{
        selected: selected || [],
        selectKey: "id",
        onCheckAll: selectAll,
        onCheck: select,
      }}
      loading={loading}
      colorType={ColorType.Content}
      light
      filter={{
        filters: {
          finished_at: [filters?.state as string],
          email: emailSearch,
          first_name: nameSearch,
        },
        onFilterChange: (key, value) => {
          if (key === "email") {
            setEmailSearch(value);
            onFilterChange?.({ ...(filters || {}), email: value });
          } else {
            setNameSearch(value);
            onFilterChange?.({ ...(filters || {}), name: value });
          }
        },
      }}
      dataSource={{ data: participants?.data || [], total: participants?.total || 0 }}
      columns={[
        ...(status !== ProjectParticipantStatus.Invited
          ? [
            {
              title: "Nom",
              dataIndex: "first_name",
              filterBy: "text",
              key: "first_name",
              render: (_, result) => (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate(`/participants/${result.participant_id}`)}
                >
                  <div className="ml-4">
                    <div className="font-bold text-lg text-content-darker">
                      {result.first_name} {result.last_name}
                    </div>
                    <div className="text-slate-400 text-sm hidden md:block">
                      {dayjs.utc(result.birth_date).local().format("DD MMM YYYY")}
                    </div>
                  </div>
                </div>
              ),
            } as ColumnProps<ProjectParticipantResponse>,
          ]
          : []),
        ...(windowWidth > 682 // state + onresize
          ? [
            {
              title: "Email",
              filterBy: "text",
              dataIndex: "email",
              key: "email",
            } as ColumnProps<ProjectParticipantResponse>,
          ]
          : []),
        ...(status !== ProjectParticipantStatus.Pending
          ? [
            {
              title: status === ProjectParticipantStatus.Finished ? "Date de complétion" : "Date de création",
              dataIndex: status === ProjectParticipantStatus.Finished ? "finished_at" : "created_at",
              key: status === ProjectParticipantStatus.Finished ? "finished_at" : "created_at",
              render: (_, record) => (
                <Tag
                  value={dayjs(record.finished_at || record.started_at || record.created_at).format("D MMMM")}
                  icon={<ClockIcon width={14} />}
                  rounded
                  className="px-2 pr-3"
                  bold
                  light
                  bordered={typeof record.finished_at !== "undefined"}
                  type={TagColor.Default}
                />
              ),
            } as ColumnProps<ProjectParticipantResponse>,
          ]
          : []),
        ...(status === ProjectParticipantStatus.Pending
          ? [
            {
              title: "État",
              dataIndex: "finished_count",
              key: "finished_count",
              render: (val: number) => {
                let progress_width = Math.round((val / (assessments.length || 1)) * 100); // avoid 0 division
                return (
                  <div className="flex justify-start items-center gap-4">
                    <div className="w-24 bg-[#E4EDFA] relative h-[6px] rounded-full overflow-hidden">
                      <div className="bg-green-400 absolute h-full" style={{ width: `${progress_width}%` }}></div>
                    </div>
                    {val} sur {assessments.length}
                  </div>
                );
              },
            } as ColumnProps<ProjectParticipantResponse>,
          ]
          : []),
        ...(status === ProjectParticipantStatus.Invited
          ? [
            {
              title: "Envoyé",
              dataIndex: "invitation_sent_at",
              key: "invitation_sent_at",
              render: (value, record) => {
                return (
                  <Tag
                    icon={<ClockIcon width={14} />}
                    value={record.resend_invitation ? "Relance en cours..." : dayjs.utc(value || undefined).fromNow()}
                    rounded
                    bordered
                    type={record.resend_invitation ? TagColor.Default : remindTagColor(record.invitation_sent_at)}
                  />
                );
              },
            } as ColumnProps<ProjectParticipantResponse>,
          ]
          : []),
        {
          title: "",
          dataIndex: "finished_at",
          key: "actions",
          render: (val: string | undefined, record: ProjectParticipantResponse) =>
            val ? (
              <div className="flex justify-end">
                <MoreActions
                  className="md:hidden self-end"
                  actions={[
                    ...((store.state.ImpersonatedJWT || store.state.JWT)?.admin
                      ? [
                        {
                          action: () => downloadResults(record.id),
                          title: "Télécharger les résultats",
                        },
                      ]
                      : []),
                    ...(onDelete
                      ? [
                        {
                          action: () => onDelete(record.id),
                          title: "Supprimer les résultats",
                        },
                      ]
                      : []),
                  ]}
                />
                <div className="md:flex gap-2 justify-end text-slate-400 hidden">
                  <div className="w-8 h-8 flex items-center justify-center hover:bg-background-bright rounded-full cursor-pointer">
                    <Tooltip title="Télécharger un rapport">
                      <MoreActions
                        icon={<DownloadIcon width={20} />}
                        actions={assessments
                          .map((assessment) =>
                            assessment.reports.filter((r) => !r.target?.includes(AssessmentReportTarget.Global)).map((report) => {
                              return {
                                title: assessment.assessment_name + " " + report.name,
                                action: () => {
                                  downloadReport(project.id, report.id, record.id);
                                },
                              };
                            })
                          )
                          .flat()}
                      />
                    </Tooltip>
                  </div>

                  {onDelete && (
                    <div className="w-8 h-8 flex items-center justify-center hover:bg-background-bright rounded-full cursor-pointer">
                      <PopConfirm
                        colorType={ColorType.Content}
                        title="Êtes-vous sûr de vouloir supprimer ces résultats ?"
                        position="left"
                        onValidate={() => onDelete(record.id)}
                      >
                        <Tooltip title="Supprimer les résultats">
                          <TrashIcon height={20} width={20} />
                        </Tooltip>
                      </PopConfirm>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex justify-end gap-5">
                {status === ProjectParticipantStatus.Pending && (
                  <Tag
                    icon={<ClockIcon width={14} />}
                    value={
                      record.resend_invitation
                        ? "Relance en cours..."
                        : dayjs.utc(record.invitation_sent_at || undefined).fromNow()
                    }
                    rounded
                    bordered
                    type={record.resend_invitation ? TagColor.Default : remindTagColor(record.invitation_sent_at)}
                  />
                )}
                <Button
                  size="small"
                  light
                  // colorType={ColorType.Content}
                  title="Relancer"
                  icon={<RefreshIcon />}
                  kind="outline"
                  onClick={() => {
                    onRelauch?.(record.id);
                  }}
                />
              </div>
            ),
        },
      ]}
    />
  );
};
