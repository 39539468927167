import { Button, ColorType, PopConfirm, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useEffect, useState } from "react";
import { Calibration, Domain } from "../../models/domain";

export interface EditDomainCalibrationFormProps {
  domain?: Domain;
  onFinish?: (data: Calibration) => void;
  onClear?: () => void;
}

export const EditDomainCalibrationForm: FunctionComponent<EditDomainCalibrationFormProps> = ({
  domain,
  onFinish,
  onClear,
}) => {
  useEffect(() => {
    setCalibration(
      domain?.calibration || {
        mu: 0,
        sigma: 1,
      }
    );
  }, [domain]);
  const [calibration, setCalibration] = useState<Calibration>();

  const onSubmit = () => {
    if (!calibration) return;

    onFinish?.(calibration);
  };

  return (
    <div className="overflow-auto">
      <div className="text-xl font-bold text-content-dark text-center ">{domain?.name}</div>

      {calibration && (
        <div>
          <div className="flex items-center gap-3 justify-center mt-10 bg-white">
            <TextInput
              light
              type="number"
              disabled={!onFinish}
              value={calibration.mu.toString() || ""}
              onChange={({ target }) => {
                setCalibration({
                  ...calibration,
                  mu: parseFloat(target.value),
                });
              }}
              label="Mu"
              placeholder="mu"
            />
            <TextInput
              light
              type="number"
              disabled={!onFinish}
              value={calibration.sigma.toString() || ""}
              onChange={({ target }) => {
                setCalibration({
                  ...calibration,
                  sigma: parseFloat(target.value),
                });
              }}
              label="Sigma"
              placeholder="sigma"
            />
          </div>
          {onClear && (
            <PopConfirm
              title="Êtes vous sur de vouloir supprimer les variables d'étalonnage ?"
              colorType={ColorType.Content}
              onValidate={onClear}
            >
              <Button colorType={ColorType.Content} title="Supprimer les variables d'étalonnage" kind="light" />
            </PopConfirm>
          )}
          {onFinish && (
            <div className="flex justify-end mt-5">
              <Button colorType={ColorType.Content} title="Confirmer" onClick={onSubmit} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
