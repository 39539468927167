import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { ParticipantResponse } from "./particiapnt";
import { ProjectResponse } from "./project";

export interface OmnisearchResult {
  participant?: ParticipantResponse;
  project?: ProjectResponse;
}

export const search = (input: string) => httpClient.req(ROUTES.SEARCH(input));
