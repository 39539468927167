import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { LicenseAssessment } from "./licenseAssessment";
import { PaginationParams } from "./paginated";

export interface License extends NewLicense {
  id: Uuid;
  admin: boolean;
  created_at: string;
  updated_at: string;
  logo_file_id: string;
}

export interface LicenseResponse {
  id: Uuid;
  name: string;
  created_at: string;
  users_count: number;
  license_assessments: LicenseAssessment[];
  logo_file_id: string;
}

export interface NewLicense {
  name: string;
  logo_file_id: string;
}

export interface UpdateLicense {
  name?: string;
  logo_file_id: string;
}

export type LicenseFilters = {
  name?: string;
} & PaginationParams;

export const fetchLicenses = (filters: LicenseFilters) => httpClient.req(ROUTES.FETCH_LICENSES(filters));

export const fetchLicense = (id: Uuid) => httpClient.req(ROUTES.FETCH_LICENSE(id));
export const fetchMyLicense = () => httpClient.req(ROUTES.FETCH_MY_LICENSE());

export const createLicense = (payload: NewLicense) =>
  httpClient.req(ROUTES.CREATE_LICENSE(payload)).then((res) => {
    toast.success("Licence créé avec succès");
    return res;
  });

export const updateLicense = (data: { id: Uuid } & UpdateLicense) =>
  httpClient.req(ROUTES.UPDATE_LICENSE(data)).then((res) => {
    toast.success("Licence mises à jour avec succès");
  });
