import { Button, ColorType, Radio, Select, TextInput } from "@getprorecrutement/getpro-design";
import { useEffect, useState } from "react";
import { AssessmentVersionResponse } from "../../models/assessment";
import { Domain } from "../../models/domain";
import { createQuestion, QuestionResponse, updateQuestion } from "../../models/question";
import { getDomainsWithSubs } from "../../utils/domains";

export interface Props {
  assessmentVersion: AssessmentVersionResponse;
  domains: Domain[];
  questionsPages: QuestionResponse[][];
  fromQuestion?: QuestionResponse;
  onCreate?: () => void;
  onUpdate?: () => void;
}

interface DomainParams {
  parent: string;
  sub_domain?: { title: string; id: Uuid };
}

export const QuestionForm = ({
  assessmentVersion,
  domains,
  questionsPages,
  fromQuestion,
  onCreate,
  onUpdate,
}: Props) => {
  const [item, setItem] = useState<string>(fromQuestion?.title || "");
  const [orderedDomains, setOrderedDomains] = useState<DomainParams[]>();
  const [reversed, setReversed] = useState<boolean>(fromQuestion?.reversed || false);
  const [selectedPage, setSelectedPage] = useState<number | undefined>(fromQuestion?.page);
  const [selectedDomain, setSelectedDomain] = useState<DomainParams>();

  useEffect(() => {
    getOrderedDomains(domains);
  }, [domains]);

  useEffect(() => {
    if (fromQuestion) {
      const sub_domain = domains.find((val) => val.id === fromQuestion.domain_id);
      if (sub_domain && sub_domain.parent_id) {
        const parent = domains.find((val) => val.id === sub_domain.parent_id);
        parent &&
          setSelectedDomain({
            parent: parent.name,
            sub_domain: {
              title: sub_domain.name,
              id: sub_domain.id,
            },
          });
      }
    }
  }, [fromQuestion, domains]);

  const getOrderedDomains = (domains: Domain[]) => {
    let res: DomainParams[] = [];
    let domainsOrdered = getDomainsWithSubs(domains);
    domainsOrdered.forEach((val: any) => {
      res.push({ parent: val.domain.name });
      val.sub_domains.forEach((sub: Domain) => {
        res.push({ parent: val.domain.name, sub_domain: { title: sub.name, id: sub.id } });
      });
    });
    setOrderedDomains(res);
  };

  const update = async () => {
    if (fromQuestion && item && selectedDomain?.sub_domain && selectedPage) {
      try {
        await updateQuestion({
          id: fromQuestion.id,
          title: item,
          reversed,
          page: selectedPage,
          domain_id: selectedDomain.sub_domain.id,
        });
        onUpdate?.();
      } finally {
      }
    }
  };

  const create = async () => {
    if (item && selectedDomain?.sub_domain && selectedPage) {
      try {
        await createQuestion({
          title: item,
          reversed,
          page: selectedPage,
          domain_id: selectedDomain.sub_domain.id,
          assessment_version_id: assessmentVersion.id,
        });
        onCreate?.();
      } finally {
      }
    }
  };

  const formCompleted = item && selectedDomain && selectedPage ? true : false;

  const getPagesAvailable = () => {
    const pages: number[] = [];

    questionsPages.forEach((page, i) => {
      if (page.length < assessmentVersion.questions_per_page) {
        pages.push(i + 1);
      }
    });

    return pages;
  };

  return (
    <div className="border border-solid border-border-lighter rounded-xl p-8 bg-inherit w-full">
      <TextInput
        placeholder="Item"
        label="Item"
        type="text"
        value={item}
        onChange={(e) => setItem(e.currentTarget.value)}
        light
      />

      <div className="mt-6 bg-inherit">
        <Select
          type="single"
          label="Domaine"
          light
          rounded
          bordered
          options={orderedDomains?.filter((d) => !!d.sub_domain) || []}
          disabled={!orderedDomains || orderedDomains.length === 0}
          optionRender={(item) => `${item.parent} / ${item.sub_domain?.title}`}
          getKey={(item) => `${item.parent} / ${item.sub_domain?.id}`}
          onChange={(val) => setSelectedDomain(val)}
          value={selectedDomain}
        />
      </div>

      <div className="mt-6 bg-inherit flex justify-between items-center">
        <div className="text-content-darker text-sm font-semibold bg-inherit">Page</div>
        <div className="w-32 bg-inherit">
          <Select
            className="bg-inherit"
            type="single"
            value={selectedPage}
            options={getPagesAvailable()}
            getKey={(item) => item.toString()}
            optionRender={(item) => item.toString()}
            onChange={(val) => setSelectedPage(val)}
            bordered
            rounded
            disabled={getPagesAvailable().length === 0}
          />
        </div>
      </div>
      <div className="flex justify-between w-full items-center mt-6">
        <div className="text-content-darker text-sm font-semibold">Reversed ?</div>
        <Radio
          options={[
            { label: "Oui", value: "oui" },
            { label: "Non", value: "non" },
          ]}
          value={reversed ? "oui" : "non"}
          onChange={(val) => setReversed(val === "oui" ? true : false)}
          optionType="button"
          light
        />
      </div>
      <div className="mt-12 flex justify-end w-full">
        <Button
          title={fromQuestion ? "Enregistrer les modifications" : "Ajouter la question"}
          onClick={fromQuestion ? update : create}
          colorType={ColorType.Content}
          disabled={!formCompleted}
        />
      </div>
    </div>
  );
};
