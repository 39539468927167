import { FunctionComponent, useEffect, useState } from "react";
import { fetchReports, ReportResponse } from "../models/report";
import { PageHeader } from "../modules/pageHeader";
import { ReportCard } from "../modules/reportCard";

interface GroupedReports {
  model: string;
  reports: ReportResponse[];
}

export const ReportsPage: FunctionComponent<{}> = () => {
  const [reports, setReports] = useState<GroupedReports[]>();

  const groupReports = (reports: ReportResponse[]): GroupedReports[] => {
    return Object.values(
      reports.reduce((acc: any, next: ReportResponse) => {
        (acc[next.model_id] = acc[next.model_id] || []).push(next);
        return acc;
      }, {} as { [key: string]: ReportResponse[] })
    ).map((elem: any) => ({ model: elem[0].model_name, reports: elem }));
  };

  useEffect(() => {
    fetchReports().then((reports) => {
      setReports(groupReports(reports));
    });
  }, []);

  return (
    <div className="flex flex-col h-full">
      <PageHeader title="Rapports" subTitle="Gestion des rapports" />
      <div className="bg-white  flex-grow flex justify-center">
        <div className="p-8 max-w-[1600px] flex flex-col gap-8 w-full">
          {reports?.map((groupedReport) => {
            return (
              <div className="w-full" key={groupedReport.model}>
                <div className="w-full flex items-center">
                  <div className="text-2xl text-content-darker font-bold">{groupedReport.model}</div>
                  <div className="border-b border-border-lighter flex-grow mx-5"></div>
                </div>
                <div className="md:grid md:grid-cols-3 flex flex-col gap-2 mt-10 w-full min-h-[180px]">
                  {groupedReport.reports.map((report) => (
                    <ReportCard key={report.id} report={report} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
