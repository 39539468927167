import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { reportLabel, ReportResponse } from "../models/report";

export interface ReportCardProps {
  report: ReportResponse;
}

export const ReportCard: FunctionComponent<ReportCardProps> = ({ report }) => {
  const navigate = useNavigate();

  return (
    <div
      className="border rounded-3xl p-8 cursor-pointer"
      onClick={() => {
        navigate(`/reports/${report.id}`);
      }}
    >
      {reportLabel(report)}
    </div>
  );
};
