import { Button, ColorType, Textarea, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { BigFiveCandidateDescription, BigFiveCandidatetDomain } from "../../../../models/domainReportDescription";

export interface EditBigFiveDomainCandidateDescriptionsFormProps {
  domainDescription?: BigFiveCandidatetDomain;
  onFinish: (updateData: BigFiveCandidateDescription) => void;
}

export const EditBigFiveDomainCandidateDescriptionsForm: FunctionComponent<
  EditBigFiveDomainCandidateDescriptionsFormProps
> = ({ domainDescription, onFinish }) => {
  const [description, setDescription] = useState<BigFiveCandidateDescription>({
    natural_label: "",
    inverse_label: "",
    description: "",
    short_description: "",
    high_score_description: "",
    low_score_description: "",
  });

  useDeepCompareEffect(() => {
    if (domainDescription) {
      setDescription(domainDescription.descriptions);
    }
  }, [domainDescription]);

  const isFormValid = () => {
    if (
      !(
        description &&
        description.description.length > 0 &&
        description.natural_label.length > 0 &&
        description.inverse_label.length > 0
      )
    )
      return false;
    if (domainDescription?.parent_template_id) {
      return !!description.high_score_description && !!description.low_score_description;
    } else {
      return !!description.short_description;
    }
  };

  return (
    <div className="max-h-[80vh] max-w-3xl p-3 overflow-auto">
      <div className="font-bold text-xl text-content-darker text-center">
        Big five - Rapport candidat - {domainDescription?.name}
      </div>
      <div className="text-xs flex flex-col gap-10 bg-white">
        <div className="flex justify-between gap-5 bg-white mt-10">
          <TextInput
            light
            value={description?.natural_label}
            placeholder={""}
            label="Sens naturel"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, natural_label: target.value });
            }}
          />
          <TextInput
            light
            value={description?.inverse_label}
            placeholder={""}
            label="Sens inverse"
            type="text"
            onChange={({ target }) => {
              setDescription(description && { ...description, inverse_label: target.value });
            }}
          />
        </div>
        <Textarea
          light
          placeholder="Description"
          rows={5}
          label="Description"
          value={description?.description}
          onChange={(value) => {
            description &&
              setDescription({
                ...description,
                description: value,
              });
          }}
        />
        {!domainDescription?.parent_template_id && (
          <Textarea
            light
            placeholder="Synthèse"
            rows={5}
            label="Synthèse"
            value={description?.short_description}
            onChange={(value) => {
              description &&
                setDescription({
                  ...description,
                  short_description: value,
                });
            }}
          />
        )}

        {domainDescription?.parent_template_id && [
          <Textarea
            key={"low-score-description-text-area"}
            light
            placeholder="Description score haut"
            rows={5}
            label="Description score haut"
            value={description?.high_score_description}
            onChange={(value) => {
              description &&
                setDescription({
                  ...description,
                  high_score_description: value,
                });
            }}
          />,
          <Textarea
            key={"high-score-description-text-area"}
            light
            placeholder="Description score bas"
            rows={5}
            label="Description score bas"
            value={description?.low_score_description}
            onChange={(value) => {
              description &&
                setDescription({
                  ...description,
                  low_score_description: value,
                });
            }}
          />,
        ]}
      </div>
      <div className="flex justify-end w-full mt-5">
        <Button
          colorType={ColorType.Content}
          title="Enregistrer"
          disabled={!isFormValid()}
          onClick={() => description && onFinish(description)}
        />
      </div>
    </div>
  );
};
