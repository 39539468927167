import { Button, ColorType, FileInput, TextInput } from "@getprorecrutement/getpro-design";

import { XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createFile, deleteFile } from "../models/file";
import { createLicense, LicenseResponse, updateLicense } from "../models/license";
import { loadable } from "../services/httpClient";

export interface Props {
  license?: Partial<LicenseResponse>;
  onFinish?: () => void;
}

export const LicenseForm = ({ license, onFinish }: Props) => {
  const [name, setName] = useState<string | undefined>(license?.name);

  const [loading, setLoading] = useState(false);
  const [logoFileId, setLogoFileId] = useState(license?.logo_file_id);

  useEffect(() => {}, []);

  const update = async () => {
    if (name && logoFileId && license?.id) {
      await loadable(
        updateLicense({
          id: license.id,
          name,
          logo_file_id: logoFileId,
        }),
        setLoading
      );
      toast.success("Licence mis à jour avec succés");
      onFinish?.();
    }
  };

  const create = async () => {
    if (name && logoFileId) {
      await loadable(
        createLicense({
          name,
          logo_file_id: logoFileId,
        }),
        setLoading
      );
      toast.success("Licence ajouté avec succés");
      onFinish?.();
    }
  };

  const onFileUpload = async (file: File) => {
    const newFile = await loadable(createFile(file), setLoading);
    setLogoFileId(newFile.id);
  };

  const onFileDelete = async () => {
    if (!logoFileId) return;
    await loadable(deleteFile(logoFileId), setLoading);
    setLogoFileId(undefined);
  };

  const formCompleted = name && logoFileId ? true : false;

  return (
    <div className="bg-inherit">
      {logoFileId && (
        <div className="relative flex justify-center items-center flex-row">
          <div className="relative w-fit h-fit mb-10">
            <XIcon height={24} width={24} className="absolute -right-5 top-0 cursor-pointer" onClick={onFileDelete} />
            <img
              className="h-24 object-contain self-center block"
              src={`${process.env.REACT_APP_GOOGLE_STORAGE_URL}/${logoFileId}`}
              alt="logo"
            />
          </div>
        </div>
      )}
      {!logoFileId && (
        <div className="flex justify-center items-center mb-10">
          <FileInput
            title="Logo"
            accept="image/png"
            id="logo-input"
            className="w-fit h-fit rounded-full p-2 px-6"
            onChange={(v) => {
              let file = v?.item(0);
              file && onFileUpload(file);
            }}
          />
        </div>
      )}
      <div className="bg-inherit">
        <TextInput
          placeholder="Nom"
          label="Nom"
          type="text"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          light
        />
      </div>
      <div className="mt-6 flex justify-end w-full">
        <Button
          colorType={ColorType.Content}
          loading={loading}
          title={license?.id ? "Enregistrer les modifications" : "Créer la licence"}
          onClick={license?.id ? update : create}
          disabled={!formCompleted}
        />
      </div>
    </div>
  );
};
