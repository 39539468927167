import { ColorType, Draggable, MoreActions, PopConfirm } from "@getprorecrutement/getpro-design";
import { DotsVerticalIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { QuestionResponse } from "../../models/question";

interface Props {
  page: number;
  questions: QuestionResponse[];
  questionsPerPage: number;
  edition?: boolean;
  removePage: () => Promise<void>;
  removeQuestion: (id: Uuid) => void;
  onEditQuestion?: (question: QuestionResponse) => void;
  onQuestionDragStart?: (question: QuestionResponse) => void;
  onQuestionDragEnd?: () => void;
}

export const QuestionsPage = ({
  page,
  questions,
  questionsPerPage,
  edition = false,
  removePage,
  removeQuestion,
  onEditQuestion,
  onQuestionDragStart,
  onQuestionDragEnd,
}: Props) => {
  const [popConfirmState, setPopConfirmState] = useState<{ id: Uuid; show: boolean }[]>([]);

  const actions = [
    {
      action: removePage,
      title: "Supprimer la page",
      icon: <TrashIcon />,
      popConfirm: { show: true, title: "Êtes-vous sûr de vouloir supprimer cette page ?" },
    },
  ];

  useEffect(() => {
    setPopConfirmState(
      questions.map((q) => ({
        id: q.id,
        show: false,
      }))
    );
  }, [questions]);

  const questionClasses = (popConfirmVisible: boolean) =>
    classNames("px-4 py-2 rounded-3xl bg-background-bright", {
      "group-hover:rounded-r-none": edition && !popConfirmVisible,
      "rounded-r-none": popConfirmVisible,
    });

  const showPopConfirm = (id: Uuid) => popConfirmState.find((q) => q.id === id)?.show || false;

  const renderQuestion = (question: QuestionResponse) => {
    return (
      <div className="text-content-darker flex group ">
        <div className={questionClasses(showPopConfirm(question.id))} style={{ maxWidth: "calc(100% - 100px)" }}>
          {question.title}
        </div>
        {edition && (
          <div
            className={`px-4 py-2 items-center bg-background-bright rounded-r-3xl ${
              showPopConfirm(question.id) ? "flex" : "hidden group-hover:flex"
            }`}
          >
            <div className="ml-2 cursor-pointer" onClick={() => onEditQuestion?.(question)}>
              <PencilIcon width={16} />
            </div>
            <div className="ml-2 cursor-pointer">
              <PopConfirm
                colorType={ColorType.Content}
                title="Êtes-vous sûr de vouloir supprimer cette question ?"
                position="bottom"
                onValidate={() => removeQuestion(question.id)}
                onChange={(show) =>
                  setPopConfirmState((e) => [...e.filter((q) => q.id !== question.id), { id: question.id, show: show }])
                }
              >
                <TrashIcon width={16} />
              </PopConfirm>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-8 rounded-lg border border-solid h-full border-border-lighter">
      <div className="mb-8 flex justify-between items-center">
        <div className="text-content-darker text-lg font-bold">Page {page + 1}</div>
        {edition && <MoreActions actions={actions} icon={<DotsVerticalIcon className="h-5 w-5" />} />}
      </div>
      <div>
        {Array.from(Array(questionsPerPage)).map((_, i) => {
          let question = questions[i];
          return (
            <div key={i} className="flex my-4 items-center w-full">
              <div className="text-sm text-content-darker font-bold w-[25px]">{i + 1} -</div>
              {question ? (
                onQuestionDragStart && edition ? (
                  <Draggable
                    onDragStart={() => onQuestionDragStart?.(question)}
                    onDragEnd={() => {
                      onQuestionDragEnd?.();
                    }}
                    optionalRenderer={() => {
                      return (
                        <div className="bg-background-darker rounded-full p-2 text-white -rotate-6">
                          {question.title}
                        </div>
                      );
                    }}
                  >
                    {renderQuestion(question)}
                  </Draggable>
                ) : (
                  <div className="w-full">{renderQuestion(question)}</div>
                )
              ) : (
                <div className="border border-dashed border-border-lighter h-10 w-full rounded-3xl" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
