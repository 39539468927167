import { MoreActions } from "@getprorecrutement/getpro-design";
import { DownloadIcon, PencilIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { generateTmpToken } from "../models/assessment";
import { ProjectResponse } from "../models/project";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import store from "../services/store";
import download from "../utils/download";

interface Props {
  project: ProjectResponse;
  onEdit?: () => void;
}

export const ProjectCard = ({ project, onEdit }: Props) => {
  const navigate = useNavigate();

  const downloadProjectResults = async (project_id: Uuid) => {
    const token = await generateTmpToken();
    const url = httpClient.genUrlWithParams(ROUTES.DOWNLOAD_PROJECT_RESULTS(project_id, token.token));
    download(url);
  };

  return (
    <div
      id="project-card"
      className="border border-slate-300 rounded-3xl p-8 cursor-pointer relative"
      onClick={() => navigate(`/projects/${project.id}`)}
    >
      <div className="flex justify-between">
        <div className="text-xl text-content-dark font-semibold">{project.name}</div>
        <MoreActions
          actions={[
            {
              action: () => {
                onEdit?.();
              },
              title: "Éditer",
              icon: <PencilIcon />,
            },
            // {
            //   action: () => {},
            //   popConfirm: {
            //     show: true,
            //   },
            //   title: "Archiver",
            //   icon: <TrashIcon />,
            // },
          ].concat(
            store.state.JWT?.admin
              ? [
                  {
                    action: () => {
                      downloadProjectResults(project.id);
                    },
                    title: "Télécharger les résultats",
                    icon: <DownloadIcon />,
                  },
                ]
              : []
          )}
        />
      </div>

      <>
        <div className="flex items-center w-fit mt-3 gap-2">
          {project.assessments.map((assessment) => {
            return (
              <div
                key={project.id.concat(assessment.name)}
                className="bg-primary-lightest rounded-full text-content-darker text-xs font-bold px-2 py-1"
              >
                {assessment.name}
              </div>
            );
          })}
        </div>
        <div className="w-full relative my-4 h-2 rounded-full">
          <div
            className="h-2 absolute z-[2] bg-background-dark rounded-full"
            style={{ width: `calc(${(project.finished_count * 100) / project.total_count}%)` }}
          />
          <div
            className="h-2 absolute z-[1] bg-background-light rounded-full"
            style={{ width: `calc(${(project.started_count * 100) / project.total_count}%)` }}
          />
          <div className="h-2 absolute w-full bg-slate-200 rounded-full" />
        </div>
        <div className="flex justify-center gap-4 text-content-darker text-xs mb-8">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-background-dark mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.finished_count}</span>
            <span>Terminée(s)</span>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 bg-background-light mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.started_count - project.finished_count}</span>
            <span>En cours</span>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 bg-slate-200 mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.total_count}</span>
            <span>Envoyée(s)</span>
          </div>
        </div>
      </>
      <div className="flex items-center justify-between">
        <div className="text-sm text-slate-500">{dayjs.utc(project.created_at).local().format("D MMMM YYYY")}</div>
        {/* <div onClick={(e) => assessment.published && e.stopPropagation()}>
          {assessment.published ? (
            <Button
              title="Participants"
              size="small"
              icon={<ChartSquareBarIcon />}
              onClick={() => navigate(`/assessments/results/${assessment.id}`)}
            />
          ) : (
            <Button title="Continuer l'édition" size="small" icon={<CogIcon />} />
          )}
        </div> */}
      </div>
    </div>
  );
};
