import classNames from "classnames";

export type Breadcrumb = { title: string; to?: string };

interface Props {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  leftSide?: JSX.Element | JSX.Element[];
  rightSide?: JSX.Element | JSX.Element[];
}

export const PageHeader = ({ title, subTitle, leftSide, rightSide }: Props) => {
  const titleClasses = classNames("text-4xl h-10 text-center text-white transition w-max relative group w-full", {
    "opacity-0": !title,
    "opacity-100": !!title,
  });
  const subTitleClasses = classNames("text-content-medium text-center transition mt-3", {
    "opacity-0": !subTitle,
    "opacity-100": !!subTitle,
  });

  return (
    <div className="md:min-h-[180px] min-h-[220px] w-full flex justify-center">
      {/* Head of header */}

      {/* Main title / subtitle / actions */}
      <div className="flex flex-col md:flex-row justify-between items-center px-8 py-3 md:py-0 max-w-[1600px] w-full">
        <div className="md:w-1/3">{leftSide && leftSide}</div>
        <div className="md:w-1/3 flex flex-col items-center">
          <div className={titleClasses}>
            <div className="text-ellipsis">{title}</div>
          </div>
          <div className={subTitleClasses}>{subTitle}</div>
        </div>
        <div className="md:w-1/3 flex justify-end gap-4">{rightSide && rightSide}</div>
      </div>
    </div>
  );
};
