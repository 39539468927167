import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export enum SupportRequestKind {
  TechRequest = "tech_request",
  ScienceRequest = "science_request",
  Other = "other",
}

export interface SupportRequestBody {
  kind: SupportRequestKind;
  text: string;
}

export const createSupportRequest = (data: SupportRequestBody, file?: File) =>
  httpClient.req(ROUTES.CREATE_SUPPORT_REQUEST(data, file)).then((res) => {
    toast.success("Votre demande de support a bien été envoyée");
  });
