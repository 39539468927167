import { Button, ColorType, Radio, Select, Tag, TagColor } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import { AssessmentType, AssessmentVersionResponse, NewAssessmentVersionParams } from "../../models/assessment";

interface NewVersionFormProps {
  fromVersion?: AssessmentVersionResponse;
  onCancel: () => void;
  label?: string;
  onFinish: (newVersion: NewAssessmentVersionParams) => void;
}

export const NewVersionForm: FC<NewVersionFormProps> = ({ label, fromVersion, onCancel, onFinish }): JSX.Element => {
  const radioOptions = [
    {
      label: "Culture Fit",
      value: AssessmentType.CultureFit,
    },
    {
      label: "Ipsatif",
      value: AssessmentType.Ipsative,
    },
    {
      label: "Normatif",
      value: AssessmentType.Normative,
    },
  ];
  const [assessmentType, setAssessmentType] = useState<AssessmentType>(AssessmentType.Ipsative);
  const [questionsPerPage, setQuestionsPerPage] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (assessmentType === AssessmentType.Normative) {
      setQuestionsPerPage(1);
    } else if (assessmentType === AssessmentType.CultureFit) {
      setQuestionsPerPage(5);
    }
  }, [assessmentType]);

  useEffect(() => {
    setLoading(false);
  }, [fromVersion]);

  return (
    <div className="md:p-10 w-[300px] md:w-[500px] overflow-auto">
      {fromVersion && <div>
        <div className="flex items-center justify-between">
          <ArrowLeftIcon className="text-slate-400 cursor-pointer" height={24} width={24} onClick={onCancel} />
          <div className="font-semibold text-content-darker -ml-6 text-xl">Nouvelle version</div>
          <div></div>
        </div>
        <div className="w-full flex flex-col items-center justify-center mt-10">
          <div className="font-medium text-content-darker text-xl mb-1">{fromVersion.name}</div>
          <Tag type={TagColor.Success} rounded={true} size="small" value={`version ${(fromVersion.version || 0) + 1}`} />
        </div></div>}
      <div className=" border-primary-light border rounded-2xl mt-10 p-5 flex items-center justify-center flex-col">
        <div className="">
          <Radio
            options={radioOptions}
            value={assessmentType}
            onChange={(v) => setAssessmentType(v as AssessmentType)}
            light
            optionType="button"
          />
        </div>
        {assessmentType === AssessmentType.Ipsative && (
          <div className="w-full mt-10 bg-white rounded-3xl">
            <Select
              type="single"
              options={[2, 3, 4]}
              optionRender={(item) => item.toString()}
              getKey={(item) => `new-version-select-key-${item}`}
              onChange={(item) => setQuestionsPerPage(item)}
              value={questionsPerPage}
              size="large"
              bordered
              rounded
              label="Items par page"
            />
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-end mt-10">
        <Button
          title={label || "Créer la version"}
          colorType={ColorType.Content}
          loading={loading}
          onClick={() => {
            setLoading(true);
            onFinish({
              questions_per_page: questionsPerPage || 5,
              assessment_type: assessmentType,
            });
          }}
        />
      </div>
    </div>
  );
};
