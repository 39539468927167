import { Button, ColorType, Modal } from "@getprorecrutement/getpro-design";
import { PaperAirplaneIcon, PlusIcon, SupportIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { fetchMyLicense, License } from "../models/license";
import {
  AssessmentReportTarget,
  fetchLicenseAssessments,
  fetchLicenseOwnedAssessmentReports,
  LicenseAssessmentReport,
  LicenseAssessmentResponse,
} from "../models/licenseAssessment";
import { PaginationParams } from "../models/paginated";
import {
  createProject,
  fetchProjects,
  NewProject,
  ProjectResponse,
  ProjectUpdate,
  quickInvite,
  updateProject,
} from "../models/project";
import { createSupportRequest, SupportRequestBody } from "../models/support";
import { ProjectCard } from "../modules/projectCard";
import { ProjectForm } from "../modules/forms/projects/projectForm";
import { SupportForm } from "../modules/forms/supportForm";
import { PageHeader } from "../modules/pageHeader";
import store, { Stored } from "../services/store";
import { QuickInviteForm } from "../modules/forms/projects/quickInviteForm";
import toast from "react-hot-toast";

export const Home = () => {
  const [license, setLicense] = useState<License>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination] = useState<PaginationParams>({
    per_page: 100,
    page: 1,
  });
  const [projects, setProjects] = useState<ProjectResponse[]>();
  const [editingProject, setEditingProject] = useState<ProjectResponse>();
  const [reports, setReports] = useState<LicenseAssessmentReport[]>();
  const [showProjectForm, setShowProjectForm] = useState<boolean>(false);
  const [licenseAssessments, setLicenseAssessments] = useState<LicenseAssessmentResponse[]>();
  const [licenseId, setLicenseId] = useState<Uuid | undefined>(
    store.state.ImpersonatedJWT?.license_id || store.state.JWT?.license_id
  );
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [showQuickInvite, setShowQuickInvite] = useState<boolean>(false);
  console.log(store.state);
  useEffect(() => {
    store.listen(Stored.JWT, () => {
      setLicenseId(store.state.ImpersonatedJWT?.license_id || store.state.JWT?.license_id);
    });
    store.listen(Stored.RawImpersonatedJWT, () => {
      setLicenseId(store.state.ImpersonatedJWT?.license_id || store.state.JWT?.license_id);
    });
  }, []);

  const fetchInitialData = useCallback(() => {
    Promise.all([
      fetchLicenseOwnedAssessmentReports(),
      fetchLicenseAssessments(),
      fetchMyLicense(),
      fetchProjects(pagination),
    ])
      .then(([reports, assessments, license, projects]) => {
        setReports(reports);
        setLicenseAssessments(assessments);
        setLicense(license);
        setProjects(projects);
      })

      .finally(() => setLoading(false));
  }, [pagination]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData, licenseId]);

  useEffect(() => {
    if (editingProject) {
      setShowProjectForm(true);
    }
  }, [editingProject]);

  const onCreateSupportRequest = async (data: SupportRequestBody, file?: File) => {
    await createSupportRequest(data, file);
    setShowSupportModal(false);
  };

  const onCreateProject = async (project: NewProject) => {
    await createProject(project);
    setShowProjectForm(false);
    fetchInitialData();
  };

  const onUpdateProject = async (project: ProjectUpdate) => {
    if (!editingProject) return;
    await updateProject(editingProject.id, project);
    setShowProjectForm(false);
    setEditingProject(undefined);
    fetchInitialData();
  };

  return (
    <div className="h-full flex flex-col">
      <PageHeader
        title={
          license && (
            <img
              className="h-16 -mt-5 object-contain"
              src={`${process.env.REACT_APP_GOOGLE_STORAGE_URL}/${license?.logo_file_id}`}
              alt="logo"
            />
          )
        }
        subTitle={
          <div className="text-white font-bold text-3xl">{license?.name ? `Hello la team ${license?.name},` : ""}</div>
        }
      />
      <div className="bg-white flex justify-center w-full flex-grow">
        {!loading && (
          <div className="w-full max-w-[1600px] p-8">
            <div className="w-full">
              <div className="flex justify-end mb-5 gap-3">
                <Button
                  title="Invitation Rapide"
                  onClick={() => setShowQuickInvite(true)}
                  size="small"
                  icon={<PaperAirplaneIcon className="rotate-90" />}
                  colorType={ColorType.Content}
                  dark
                />
                <Button
                  title="Nouveau projet"
                  onClick={() => setShowProjectForm(true)}
                  size="small"
                  icon={<PlusIcon />}
                  colorType={ColorType.Content}
                  dark
                />
              </div>
              <div className="flex justify-between items-center gap-5  mb-5">
                <div className="font-bold text-content-darker text-2xl">Projets</div>
                <div className="w-full border-b border-[#D8DFE7]"></div>
                {/* <div className="flex items-end justify-center gap-5 text-content-border-content-regular">
                  <div className="flex justify-center items-center gap-3">
                    <SelectorIcon height={16} />
                    <div>Tri</div>
                  </div>
                  <div className="flex justify-center items-center gap-3">
                    <FilterIcon height={16} />
                    <div>Filtres</div>
                  </div>
                </div> */}
              </div>
              <div className="md:grid md:grid-cols-3 gap-5 flex flex-col">
                {projects?.map((project) => (
                  <ProjectCard key={project.id} project={project} onEdit={() => setEditingProject(project)} />
                ))}
              </div>
            </div>
            <div className="flex md:flex-row flex-col justify-end items-stretch w-full gap-10 mt-10">
              <div className="md:w-1/3 bg-background-light rounded-3xl h-full px-10 py-5 flex flex-col items-center max-w-sm">
                <SupportIcon height={72} width={72} className="my-5" strokeWidth={1} />
                <div className="text-xl font-bold">Vous avez un problème ou une question ?</div>
                <div className="mt-5">
                  N'hésitez pas à contacter notre équipe. Nous vous répondrons dans les meilleurs délais.
                </div>
                <Button
                  title="Contacter le support"
                  colorType={ColorType.Content}
                  className="mt-10"
                  onClick={() => setShowSupportModal(true)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal className="bg-white" show={showSupportModal} onClose={() => setShowSupportModal(false)}>
        <SupportForm onCancel={() => setShowSupportModal(false)} onFinish={onCreateSupportRequest} />
      </Modal>
      <Modal
        className="bg-white"
        show={showProjectForm}
        onClose={() => {
          setEditingProject(undefined);
          setShowProjectForm(false);
        }}
      >
        <ProjectForm
          project={editingProject}
          licenseAssessmentReports={reports || []}
          onUpdate={onUpdateProject}
          onCreate={onCreateProject}
          licenseAssessments={licenseAssessments || []}
        />
      </Modal>
      <Modal
        className="bg-white"
        show={showQuickInvite}
        onClose={() => {
          setShowQuickInvite(false);
        }}
      >
        <QuickInviteForm
          licenseAssessmentReports={reports?.filter((r) => r.target?.includes(AssessmentReportTarget.Candidate)) || []}
          onCreate={(e) => {
            quickInvite(e).then((res) => {
              toast.success("Invitation envoyée avec succès");
            });
          }}
          licenseAssessments={licenseAssessments || []}
        />
      </Modal>
    </div>
  );
};
