import { PopOver } from "@getprorecrutement/getpro-design";
import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AssessmentType, AssessmentVersionResponse } from "../../../models/assessment";

export interface VersionSelectorProps {
  assessmentVersions: AssessmentVersionResponse[];
  selectedVersion?: AssessmentVersionResponse;
  onChange: (assessmentVersion?: AssessmentVersionResponse) => void;
  selectable?: boolean;
}

export const VersionSelector: FunctionComponent<VersionSelectorProps> = ({
  assessmentVersions,
  selectedVersion,
  onChange,
}) => {
  const [lastVersion, setLastVersion] = useState<number>();

  useEffect(() => {
    const lastVersion = assessmentVersions.reduce((acc, next) => {
      return next.version > acc ? next.version : acc;
    }, 0);
    setLastVersion(lastVersion);
  }, [assessmentVersions]);

  const VersionRow = ({ assessmentVersion }: { assessmentVersion?: AssessmentVersionResponse }) => {
    const classes =
      "flex justify-center items-center font-bold gap-2 bg-background-dark border border-border-medium text-white px-4 py-1 rounded-2xl cursor-pointer my-2 w-fit";

    const dotClasses = classNames("h-2 w-2 rounded-full", {
      "bg-success-medium": assessmentVersion?.published,
      "bg-warning-medium": !assessmentVersion?.published && assessmentVersion?.version === lastVersion,
      "bg-background-lighter": !assessmentVersion?.published && assessmentVersion?.version !== lastVersion,
    });

    const [tagClasses, tagLabel] = useMemo(() => {
      switch (assessmentVersion?.assessment_type) {
        case AssessmentType.CultureFit:
          return ["bg-[#34367E] border-[#8B5CF6]", "Culture Fit"]
        case AssessmentType.Ipsative:
          return ["bg-[#34367E] border-[#8B5CF6]", "Ipsatif"]
        default:
          return ["bg-[#4B425B] border-[#FF9548]", "Normatif"]
      }
    }, [assessmentVersion])

    return (
      <div>
        {assessmentVersion ? (
          <div className="w-96 flex justify-center items-center gap-5">
            <div
              className={"flex justify-center items-center border font-bold gap-2 text-white px-4 py-1 rounded-2xl cursor-pointer my-2 w-fit ".concat(
                tagClasses
              )}
            >
              {tagLabel}
            </div>
            <div className={classes}>
              <div className={dotClasses}></div>
              <div>Version {assessmentVersion.version}</div>
            </div>
          </div>
        ) : (
          "Aucune version sélectionnée"
        )}
      </div>
    );
  };

  const optionalRenderer = (
    assessmentVersion: AssessmentVersionResponse,
    onChange: (assess: AssessmentVersionResponse) => void
  ): JSX.Element => {
    const tagClasses = classNames("px-2 border font-semibold h-fit w-fit rounded-full", {
      "text-success-medium border-success-medium": assessmentVersion?.published,
      "text-warning-medium border-warning-medium":
        !assessmentVersion?.published && assessmentVersion.version === lastVersion,
      "text-content-light border-border-lighter":
        !assessmentVersion.published && assessmentVersion.version !== lastVersion,
    });

    const radioClasses = classNames("h-6 w-6 border border-border-dark rounded-full flex items-center justify-center", {
      "bg-background-dark": assessmentVersion.id === selectedVersion?.id,
    });

    return (
      <div
        key={assessmentVersion.id}
        className="flex justify-between items-center cursor-pointer w-96"
        onClick={() => onChange(assessmentVersion)}
      >
        <div className="flex gap-4 items-center">
          <div className={radioClasses}>
            {assessmentVersion.id === selectedVersion?.id && (
              <CheckIcon height={18} width={18} className="text-white" />
            )}
          </div>
          <div className="text-left">
            <div className="text-primary-darker">
              Version {assessmentVersion.version} |{" "}
              {assessmentVersion.assessment_type === AssessmentType.Ipsative ? "Ipsatif" : "Normatif"}
            </div>
            <div className="text-content-regular">
              {dayjs.utc(assessmentVersion.created_at).local().format("DD MMM YYYY")}
            </div>
          </div>
        </div>

        <div className="w-1/3">
          <div className={tagClasses}>
            {assessmentVersion?.published ? "PUBLIC" : assessmentVersion.version === lastVersion ? "WIP" : "DISABLED"}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PopOver
      color="white"
      position="bottom"
      content={<div>{assessmentVersions.map((assess) => optionalRenderer(assess, onChange))}</div>}
    >
      <VersionRow assessmentVersion={selectedVersion} />
    </PopOver>

    // <Select
    //   options={assessmentVersions.sort((a, b) => a.version - b.version) || []}
    //   value={selectedVersion}
    //   onChange={onChange}
    //   rounded
    //   type="single"
    //   valueRenderer={renderValue}
    //   getKey={(assessmentVersion) => assessmentVersion.id}
    //   getLabel={(assessmentVersion) => {
    //     return optionalRenderer(assessmentVersion, 0);
    //   }}
    // />
  );
};
