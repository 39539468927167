import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { CalibrationImport } from "./domain";
import { QuestionImport } from "./question";

export interface AssessmentResponse {
  id: Uuid;
  name: string;
  label: string;
  model_id: Uuid;
  created_at: string;
  archived: boolean;
  versions_count: number;
  last_assessment_type: AssessmentType;
  model_name: string;
}

export enum AssessmentType {
  Normative = "normative",
  Ipsative = "ipsative",
  CultureFit = "culture_fit"
}

export interface AssessmentVersionResponse {
  id: Uuid;
  name: string;
  model_id: Uuid;
  created_at: string;
  version: number;
  published: boolean;
  assessment_type: AssessmentType;
  questions_per_page: number;
  assessment_id: Uuid;
  public_token: String | undefined;
  archived: boolean;
  started_count: number;
  finished_count: number;
  sent_count: number;
}

export interface NewAssessment {
  name: string;
  label: string;
  model_id: Uuid;
}

export interface AssessmentUpdate {
  label?: string;
  name?: string;
}

export interface NewAssessmentWithVersion {
  name: string;
  questions_per_page: number;
  assessment_type: AssessmentType;
  label: string;
}

export interface NewAssessmentVersionParams {
  questions_per_page: number;
  assessment_type: AssessmentType;
}

export interface GroupedAssessment {
  model_id: Uuid;
  model_name: string;
  assessments: AssessmentResponse[];
}

export interface ShortAssessmentResponse {
  id: Uuid;
  name: string;
}

export const fetchAssessments = () => httpClient.req(ROUTES.FETCH_ASSESSMENTS());

export const fetchAssessmentVersions = (id: Uuid) => httpClient.req(ROUTES.FETCH_ASSESSMENT_VERSIONS(id));

export const createNewAssessment = (data: NewAssessment) => httpClient.req(ROUTES.CREATE_ASSESSMENT(data));

export const fetchAssessment = (id: Uuid) => httpClient.req(ROUTES.FETCH_ASSESSMENT(id));

export const updateAssessment = (id: Uuid, updateFields: AssessmentUpdate) =>
  httpClient.req(ROUTES.UPDATE_ASSESSMENT(id, updateFields));

export const publishAssessmentVersion = (id: Uuid) => httpClient.req(ROUTES.PUBLISH_ASSESSMENT_VERSION(id));

export const createNewAssessmentVersion = (from_id: Uuid, newVersionParams: NewAssessmentVersionParams) =>
  httpClient.req(ROUTES.CREATE_NEW_ASSESSMENT_VERSION(from_id, newVersionParams));

export const generateTmpToken = () => httpClient.req(ROUTES.GENERATE_TMP_TOKEN());

export const downloadResults = (id: Uuid, token: string) => httpClient.req(ROUTES.DOWNLOAD_RESULTS(id, token));

export const downloadAssessmentResults = (id: Uuid, token: string) =>
  httpClient.req(ROUTES.DOWNLOAD_ASSESSMENT_RESULTS(id, token));

export const importQuestions = (assessmentVersionid: Uuid, questions: QuestionImport[]) =>
  httpClient.req(ROUTES.IMPORT_QUESTIONS(assessmentVersionid, questions));

export const importCalibrations = (assessmentVersionid: Uuid, calibrations: CalibrationImport[]) =>
  httpClient.req(ROUTES.IMPORT_CALIBRATIONS(assessmentVersionid, calibrations));

export const relaunchInvites = (id: Uuid, invitationIds: Uuid[]) =>
  httpClient.req(ROUTES.RELAUNCH_INVITATIONS(id, invitationIds));

export const deleteAssessment = (id: Uuid) => httpClient.req(ROUTES.DELETE_ASSESSMENT(id));
