import { Button, Modal, Radio, ColorType } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, DocumentAddIcon, ReplyIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AssessmentResponse,
  AssessmentVersionResponse,
  createNewAssessmentVersion,
  fetchAssessment,
  fetchAssessmentVersions,
  importCalibrations,
  importQuestions,
  NewAssessmentVersionParams,
  publishAssessmentVersion,
} from "../../models/assessment";
import { CalibrationImport, Domain, fetchAssessmentVersionDomains } from "../../models/domain";
import {
  deleteQuestion,
  fetchAssessmentVersionQuestions,
  QuestionImport,
  QuestionResponse,
} from "../../models/question";
import { DomainsInfo } from "../../modules/assessments/infos/domains";
import { QuestionInfoMenu } from "../../modules/assessments/infos/questionMenu";
import { QuestionsInfo } from "../../modules/assessments/infos/questions";
import { VersionSelector } from "../../modules/assessments/infos/versionSelector";
import { QuestionForm } from "../../modules/assessments/questionForm";
import { ImportCsvForm } from "../../modules/forms/importCsvForm";
import { NewVersionForm } from "../../modules/forms/newVersionForm";
import { Breadcrumb, PageHeader } from "../../modules/pageHeader";
import { DomainWithSubsFull, getDomainsWithSubsFull } from "../../utils/domains";
import { getQuestionsPages } from "../../utils/questions";

const fetchAssessmentDatas = async (
  id: Uuid
): Promise<{
  assessment: AssessmentResponse;
  assessmentVersions: AssessmentVersionResponse[];
}> => {
  const assessment = await fetchAssessment(id);
  const assessmentVersions = await fetchAssessmentVersions(assessment.id);
  return { assessment, assessmentVersions };
};

const fetchQuestionsAndDomains = (id: Uuid): Promise<{ questions: QuestionResponse[]; domains: Domain[] }> => {
  return Promise.all([fetchAssessmentVersionQuestions(id), fetchAssessmentVersionDomains(id)]).then(
    ([questions, domains]) => {
      return { questions, domains };
    }
  );
};

enum InfoPages {
  DOMAINS = "DOMAINS",
  QUESTIONS = "QUESTIONS",
}

export const AssessmentInfosPage = () => {
  const { id } = useParams();

  const [assessment, setAssessment] = useState<AssessmentResponse>();
  const [assessmentVersions, setAssessmentVersions] = useState<AssessmentVersionResponse[]>();
  const [selectedAssessmentVersion, setSelectedAssessmentVersion] = useState<AssessmentVersionResponse>();
  const [questions, setQuestions] = useState<QuestionResponse[]>();
  const [displayCsvImportModal, setDisplayCsvImportModal] = useState<boolean>(false);
  const [domains, setDomains] = useState<Domain[]>();
  const [questionsPages, setQuestionsPages] = useState<QuestionResponse[][]>();
  const [displayNewVersionModal, setDisplayNewVersionModal] = useState<boolean>(false);
  const [domainQuestions, setDomainQuestions] = useState<DomainWithSubsFull<Domain>[]>();
  const [currentPage, setCurrentPage] = useState<InfoPages>(InfoPages.QUESTIONS);
  const [editingQuestion, setEditingQuestion] = useState<QuestionResponse>();
  const [showCalibrationImport, setShowCalibrationImport] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchAssessmentDatas(id).then((res) => {
        setAssessment(res.assessment);
        setAssessmentVersions(res.assessmentVersions);
        setSelectedAssessmentVersion(res.assessmentVersions[res.assessmentVersions.length - 1]);
      });
    }
  }, [id]);

  const onUpdate = useCallback(() => {
    if (selectedAssessmentVersion) {
      fetchQuestionsAndDomains(selectedAssessmentVersion.id).then((res) => {
        setQuestions(res.questions);
        setDomains(res.domains);
        setDomainQuestions(getDomainsWithSubsFull(res.domains, res.questions));
        setQuestionsPages(getQuestionsPages(res.questions));
      });
    }
  }, [selectedAssessmentVersion]);

  useEffect(() => {
    onUpdate();
  }, [onUpdate]);

  const deleteUnassignedQuestion = (id: Uuid) => {
    if (
      selectedAssessmentVersion?.published === false &&
      assessment?.versions_count === selectedAssessmentVersion.version
    ) {
      deleteQuestion(id).then(onUpdate);
    }
  };

  const updateDomain = (domain: Domain) => {
    const updatedDomains = [...(domains || []).filter((d) => d.id !== domain.id), domain];
    setDomains(updatedDomains);
    setDomainQuestions(getDomainsWithSubsFull(updatedDomains, questions || []));
  };

  const updateQuestion = (question: QuestionResponse) => {
    const updatedQuestions = [...(questions || []).filter((q) => q.id !== question.id), question];
    setQuestions(updatedQuestions);
    setDomainQuestions(getDomainsWithSubsFull(domains || [], updatedQuestions));
    setQuestionsPages(getQuestionsPages(updatedQuestions));
  };

  const onQuestionDelete = (questionsIds: Uuid[]) => {
    const updatedQuestions = (questions || []).filter((q) => !questionsIds.includes(q.id));
    setQuestions(updatedQuestions);
    setDomainQuestions(getDomainsWithSubsFull(domains || [], updatedQuestions));
    setQuestionsPages(getQuestionsPages(updatedQuestions));
  };

  const breadcrumb: Breadcrumb[] = [{ title: "Évaluations", to: `/model/${assessment?.model_id}` }];
  if (assessment) breadcrumb.push({ title: assessment.name });

  const publish = async () => {
    if (!assessment || !assessmentVersions || !selectedAssessmentVersion || selectedAssessmentVersion.published) return;
    let updatedVersion = await publishAssessmentVersion(selectedAssessmentVersion.id);

    fetchAssessmentVersions(assessment.id).then(setAssessmentVersions);
    setSelectedAssessmentVersion(updatedVersion);
  };

  const rightSideHeaderButtons = () => {
    if (!selectedAssessmentVersion) return [];
    if (selectedAssessmentVersion.published && assessment?.versions_count === selectedAssessmentVersion.version) {
      return [
        <Button
          key="publish-assessment-btn"
          light
          colorType={ColorType.Content}
          size="small"
          title="Nouvelle version"
          onClick={() => setDisplayNewVersionModal(true)}
        />,
      ];
    } else if (!selectedAssessmentVersion.published) {
      return [
        <Button
          key="publish-assessment-btn"
          size="small"
          title="Publier l'évaluation"
          colorType={ColorType.Content}
          light
          onClick={publish}
        />,
      ];
    }
  };

  const onQuestionImport = async (questions: QuestionImport[]) => {
    if (!selectedAssessmentVersion) return;

    await importQuestions(selectedAssessmentVersion.id, questions);
    setDisplayCsvImportModal(false);
    onUpdate();
  };

  const onCalibrationImport = async (calibrations: CalibrationImport[]) => {
    if (!selectedAssessmentVersion) return;

    await importCalibrations(selectedAssessmentVersion.id, calibrations);
    setShowCalibrationImport(false);
    onUpdate();
  };

  const createNewVersion = async (newVersionParams: NewAssessmentVersionParams) => {
    if (!assessment) return;
    const newAssessmentVersion = await createNewAssessmentVersion(assessment.id, newVersionParams);
    setAssessmentVersions([...(assessmentVersions || []), newAssessmentVersion]);
    setSelectedAssessmentVersion(newAssessmentVersion);
    setDisplayNewVersionModal(false);
  };

  const displayPageContent = () => {
    if (!selectedAssessmentVersion) return;
    switch (currentPage) {
      case InfoPages.DOMAINS:
        return (
          <DomainsInfo
            edition={
              !selectedAssessmentVersion.published && assessment?.versions_count === selectedAssessmentVersion.version
            }
            assessmentVersion={selectedAssessmentVersion}
            domainQuestions={domainQuestions || []}
            onUpdate={updateDomain}
            setEditingQuestion={setEditingQuestion}
            deleteUnassignedQuestion={deleteUnassignedQuestion}
          />
        );
      case InfoPages.QUESTIONS:
        return (
          <QuestionsInfo
            edition={
              !selectedAssessmentVersion.published && assessment?.versions_count === selectedAssessmentVersion.version
            }
            questionsPages={questionsPages || []}
            assessmentVersion={selectedAssessmentVersion}
            onUpdate={updateQuestion}
            onDelete={onQuestionDelete}
            setEditingQuestion={setEditingQuestion}
            setQuestionsPages={setQuestionsPages}
          />
        );
    }
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={assessment?.name}
        subTitle={
          <VersionSelector
            assessmentVersions={assessmentVersions || []}
            selectedVersion={selectedAssessmentVersion}
            onChange={(value) => {
              setSelectedAssessmentVersion(value);
            }}
          />
        }
        leftSide={
          <Button
            title="Retour aux évaluations"
            colorType={ColorType.Primary}
            icon={<ReplyIcon />}
            onClick={() => navigate(`/assessments/`)}
            dark
            kind="outline"
            size="small"
          />
        }
        rightSide={rightSideHeaderButtons()}
      />
      <div className="flex flex-grow bg-white justify-center">
        <div className=" p-12 w-full max-w-[1600px]">
          <div className="mb-8 flex justify-between items-center">
            <Radio
              options={[
                { value: InfoPages.QUESTIONS, label: "Pages" },
                { value: InfoPages.DOMAINS, label: "Domaines" },
              ]}
              onChange={(val) => setCurrentPage(val as InfoPages)}
              optionType="button"
              light
              value={currentPage}
            />
            {selectedAssessmentVersion &&
              !selectedAssessmentVersion.published &&
              assessment?.versions_count === selectedAssessmentVersion.version && (
                <Button
                  kind="outline"
                  light
                  title="Importer les questions"
                  colorType={ColorType.Content}
                  onClick={() => setDisplayCsvImportModal(true)}
                  size="small"
                  icon={<DocumentAddIcon />}
                />
              )}
            {selectedAssessmentVersion &&
              !selectedAssessmentVersion.published &&
              assessment?.versions_count === selectedAssessmentVersion.version && (
                <Button
                  kind="outline"
                  light
                  title="Importer les valeurs d'étalonage"
                  colorType={ColorType.Content}
                  onClick={() => setShowCalibrationImport(true)}
                  size="small"
                  icon={<DocumentAddIcon />}
                />
              )}
          </div>
          {selectedAssessmentVersion && displayPageContent()}
        </div>
        {selectedAssessmentVersion &&
          !selectedAssessmentVersion.published &&
          assessment?.versions_count === selectedAssessmentVersion.version && (
            <QuestionInfoMenu
              editable={
                !selectedAssessmentVersion.published && assessment?.versions_count === selectedAssessmentVersion.version
              }
              assessmentVersion={selectedAssessmentVersion}
              domains={domains || []}
              questionsPages={questionsPages || []}
              domainQuestions={domainQuestions || []}
              onUpdate={onUpdate}
              onEditQuestion={setEditingQuestion}
              deleteUnassignedQuestion={deleteUnassignedQuestion}
            />
          )}

        {selectedAssessmentVersion && (
          <Modal
            className="bg-white"
            show={!!editingQuestion}
            onClose={() => setEditingQuestion(undefined)}
            style={{ width: "fit-content" }}
          >
            <div className="border-border-lighter rounded-3xl p-10 bg-inherit w-[600px]">
              <div className="flex justify-between">
                <ArrowLeftIcon
                  className="text-slate-400 cursor-pointer"
                  onClick={() => setEditingQuestion(undefined)}
                  width={24}
                  height={24}
                />
                <div className="-ml-6 text-xl text-content-darker font-semibold">Editer L'Item</div>
                <div />
              </div>
              <div className="p-5 bg-inherit">
                {editingQuestion && (
                  <QuestionForm
                    fromQuestion={editingQuestion}
                    assessmentVersion={selectedAssessmentVersion}
                    domains={domains || []}
                    questionsPages={questionsPages || []}
                    onUpdate={() => {
                      setEditingQuestion(undefined);
                      onUpdate();
                    }}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}
        {selectedAssessmentVersion && (
          <Modal className="bg-white" show={displayCsvImportModal} onClose={() => setDisplayCsvImportModal(false)}>
            <ImportCsvForm
              cols={{
                name: {
                  type: "string",
                  label: "Titre",
                },
                page: {
                  type: "integer",
                  label: "Page",
                },
                reversed: {
                  type: "boolean",
                  label: "Reversed",
                },
                domain_name: {
                  type: "string",
                  label: "Domaine",
                },
              }}
              customDeserialize={{
                reversed: (v): boolean => {
                  return v?.length > 0;
                },
              }}
              onFinish={onQuestionImport}
              onCancel={() => setDisplayCsvImportModal(false)}
            />
          </Modal>
        )}
        {selectedAssessmentVersion && (
          <Modal className="bg-white" show={displayNewVersionModal} onClose={() => setDisplayNewVersionModal(false)}>
            <NewVersionForm
              fromVersion={selectedAssessmentVersion}
              onCancel={() => setDisplayNewVersionModal(false)}
              onFinish={createNewVersion}
            />
          </Modal>
        )}
        <Modal className="bg-white" show={showCalibrationImport} onClose={() => setShowCalibrationImport(false)}>
          <ImportCsvForm
            onFinish={onCalibrationImport}
            cols={{
              mu: {
                label: "mu",
                type: "float",
              },
              sigma: {
                label: "sigma",
                type: "float",
              },
              domain_name: {
                label: "Domaine",
                type: "string",
              },
            }}
            onCancel={() => setShowCalibrationImport(false)}
          />
        </Modal>
      </div>
    </div>
  );
};
