import { Radio } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState } from "react";
import { AssessmentVersionResponse } from "../../../models/assessment";
import { Domain } from "../../../models/domain";
import { QuestionResponse } from "../../../models/question";
import { DomainWithSubsFull } from "../../../utils/domains";
import { DomainTree } from "../domain";
import { QuestionForm } from "../questionForm";

export interface QuestionInfoMenuProps {
  assessmentVersion: AssessmentVersionResponse;
  domainQuestions: DomainWithSubsFull<Domain>[];
  domains: Domain[];
  questionsPages: QuestionResponse[][];
  editable?: boolean;
  onUpdate: () => void;
  onEditQuestion: (question: QuestionResponse) => void;
  deleteUnassignedQuestion: (id: Uuid) => void;
}

enum PageMenu {
  NEW_QUESTION = "NEW_QUESTIONS",
  NOT_ASSIGNED_QUESTION = "NOT_ASSIGNED_QUESTIONS",
}

export const QuestionInfoMenu: FunctionComponent<QuestionInfoMenuProps> = ({
  assessmentVersion,
  domainQuestions,
  questionsPages,
  domains,
  editable,
  onUpdate,
  onEditQuestion,
  deleteUnassignedQuestion,
}) => {
  const [pageMenu, setPageMenu] = useState<PageMenu>(PageMenu.NEW_QUESTION);

  return (
    <div className="bg-slate-50 p-8 w-1/4">
      <div className="sticky w-full top-8 bg-inherit">
        <div className="flex items-center justify-center mb-5">
          <Radio
            optionType="button"
            light
            value={pageMenu}
            options={[
              {
                label: "Nouvelle question",
                value: PageMenu.NEW_QUESTION,
              },
              {
                label: "Non assignées",
                value: PageMenu.NOT_ASSIGNED_QUESTION,
              },
            ]}
            onChange={(value) => {
              setPageMenu(value as PageMenu);
            }}
          />
        </div>

        {pageMenu === PageMenu.NEW_QUESTION ? (
          <QuestionForm
            assessmentVersion={assessmentVersion}
            domains={domains || []}
            questionsPages={questionsPages || []}
            onCreate={() => onUpdate()}
          />
        ) : (
          <div className="p-5 border border-border-lighter rounded-xl">
            <div className="text-slate-400 text-sm font-medium">
              Les items ci-dessous n'apparaissent sur aucune page. Pour les assigner, glissez les items dans les pages
              souhaitées.
            </div>
            <div className="flex flex-col gap-5 mt-5 overflow-y-auto h-max max-h-[60vh]">
              {domainQuestions?.map((domain, i) => {
                let d = {
                  ...domain,
                  sub_domains: domain.sub_domains.map((sub_domain) => {
                    let sub = {
                      ...sub_domain,
                      questions: sub_domain.questions.filter((question) => question.page == null),
                    };
                    return sub;
                  }),
                };
                return (
                  <DomainTree
                    key={i}
                    edition={editable}
                    domain={d}
                    removeQuestion={deleteUnassignedQuestion}
                    onEditQuestion={onEditQuestion}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
