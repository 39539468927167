import { MoreActions, Tag, TagColor } from "@getprorecrutement/getpro-design";
import { DownloadIcon, PencilIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AssessmentResponse, AssessmentType, generateTmpToken } from "../../models/assessment";
import httpClient from "../../services/httpClient";
import ROUTES from "../../services/routes";
import download from "../../utils/download";

interface Props {
  assessment: AssessmentResponse;
  onCreateNewVersion: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const AssessmentCard = ({ assessment, onCreateNewVersion, onDelete, onEdit }: Props) => {
  const navigate = useNavigate();

  let classes = "border border-slate-300 rounded-xl p-8 cursor-pointer relative";

  const downloadProjectResults = async () => {
    const token = await generateTmpToken();
    const url = httpClient.genUrlWithParams(ROUTES.DOWNLOAD_ASSESSMENT_RESULTS(assessment.id, token.token));
    download(url);
  };



  const [tagClasses, tagLabel] = useMemo(() => {
    switch (assessment?.last_assessment_type) {
      case AssessmentType.CultureFit:
        return ["bg-[#34367E] text-white border-[#8B5CF6]", "Culture Fit"]
      case AssessmentType.Ipsative:
        return ["text-[#8B5CF6] bg-[#E8DEFD]", "Ipsatif"]
      default:
        return ["text-[#FB923C] bg-[#FFEDD5]", "Normatif"]
    }
  }, [assessment?.last_assessment_type])



  return (
    <div className={classes} onClick={() => navigate(`/assessments/${assessment.id}`)}>
      <div className="flex justify-between">
        <div className="text-xl text-content-dark">{assessment.name}</div>
        <MoreActions
          actions={[
            {
              action: downloadProjectResults,
              title: "Télécharger les résultats",
              icon: <DownloadIcon />,
            },
            {
              action: onEdit,
              title: "Éditer",
              icon: <PencilIcon />,
            },
          ]}
        />
      </div>
      <div className="mb-10 flex gap-2 mt-2 justify-start items-center">
        {assessment.archived && <Tag type={TagColor.Error} rounded={true} size="small" value="Archivée" />}
        <div className={"px-3 py-1 text-xs rounded-xl ".concat(tagClasses)}>
          {tagLabel}
        </div>
        <Tag type={TagColor.Success} rounded={true} size="small" value={`version ${assessment.versions_count}`} />
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm text-slate-500">{dayjs.utc(assessment.created_at).local().format("D MMMM YYYY")}</div>
      </div>
    </div>
  );
};
