import toast from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export interface Calibration {
  mu: number;
  sigma: number;
}

export interface GenderBasedDescription {
  male: string;
  female: string;
}

export interface CalibrationImport {
  mu: number;
  sigma: number;
  domain_name: string;
}

export interface Domain extends DomainBase {
  assessment_id: Uuid;
  calibration?: Calibration;
}

export interface DomainData {
  calibration?: Calibration;
}

export interface DomainTemplate extends DomainBase {
  model_id: Uuid;
}

export interface NewDomainTemplate {
  name: string;
  parent_id?: Uuid;
  model_id: Uuid;
}

export interface DomainBase {
  id: Uuid;
  name: string;
  label: string;
  parent_id?: Uuid;
  created_at: string;
}

export const fetchAssessmentVersionDomains = (id: Uuid) => httpClient.req(ROUTES.FETCH_ASSESSMENT_VERSION_DOMAINS(id));

export const fetchModelDomains = (id: Uuid) => httpClient.req(ROUTES.FETCH_MODEL_DOMAINS(id));

export const updateDomain = (id: Uuid, data: DomainData) =>
  httpClient.req(ROUTES.UPDATE_DOMAIN(id, data)).then((res) => {
    toast.success("Domaine mis à jour avec succes");
    return res;
  });

export const deleteDomain = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_DOMAIN(id)).then((res) => {
    toast.success("Domaine supprimé avec succès");
    return res;
  });

export const clearDomainCalibration = (id: Uuid) =>
  httpClient.req(ROUTES.CLEAR_DOMAIN_CALIBRATION(id)).then((res) => {
    toast.success("Paramettres d'étalonage supprimés avec succès");
    return res;
  });

export const createDomain = (payload: NewDomainTemplate) =>
  httpClient.req(ROUTES.CREATE_DOMAIN(payload)).then((res) => {
    toast.success("Domaine créé avec succès");
    return res;
  });
