import httpClient, { loadable } from "../services/httpClient";
import { LoginPayload } from "../models/auth";
import { TextInput, Button, ColorType } from "@getprorecrutement/getpro-design";
import ROUTES from "../services/routes";
import store from "../services/store";
import { FormEvent, useState } from "react";
import toast from "react-hot-toast";

export const LoginPage = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  const login = (e: FormEvent) => {
    e.preventDefault();
    const data = { email, password } as LoginPayload;
    loadable(
      httpClient.req(ROUTES.LOGIN(data)).then((d) => store.setCredentials(d)),
      setLoading
    );
  };

  const sendForgotPassword = (e: FormEvent) => {
    if (email) {
      e.preventDefault();
      loadable(
        httpClient
          .req(ROUTES.FORGOT_PASSWORD(email))
          .then(() => toast.success("Vous avez reçu un email afin de redéfinir votre mot de passe")),
        setLoading
      );
    }
  };

  return (
    <div className="flex items-center justify-end h-full p-32">
      <form onSubmit={forgotPassword ? sendForgotPassword : login}>
        <div className="w-[480px] px-8 py-12 flex flex-col bg-white rounded-2xl">
          <div className="text-2xl mb-8 font-bold text-center">
            {forgotPassword ? "Mot de passe oublié" : "Connexion"}
          </div>
          <div className="mb-8 bg-white">
            <TextInput
              label="Email"
              type="email"
              placeholder="Adresse e-mail"
              value={email}
              light
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          {!forgotPassword && (
            <div className="mb-8 bg-white">
              <TextInput
                label="Mot de passe"
                type="password"
                placeholder="Mot de passe"
                value={password}
                light
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>
          )}
          <div className="mb-2 flex items-center justify-between">
            <Button
              colorType={ColorType.Content}
              kind="light"
              onClick={() => setForgotPassword((v) => !v)}
              title={forgotPassword ? "Se connecter" : "Mot de passe oublié"}
            />
            <Button
              type="submit"
              colorType={ColorType.Content}
              light
              loading={loading}
              title={forgotPassword ? "Récupérer" : "Se connecter"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
