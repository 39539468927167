import { Button, ColorType, FileInput, Select } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import Papa from "papaparse";
import { useCallback, useEffect, useState } from "react";

interface CsvColumn {
  key: string;
  values: string[];
}

interface FieldMatch<T extends Column> {
  from: CsvColumn | undefined;
  to: T[keyof T];
}

type Column = { [key: string]: { type: "integer" | "float" | "string" | "boolean"; label: string } };

type TargetType<T extends Column> = {
  [key in keyof T]: T[key]["type"] extends "integer" | "float"
    ? number
    : T[key]["type"] extends "boolean"
    ? boolean
    : string;
};

interface ImportCsvFormProps<T extends Column> {
  customDeserialize?: Partial<{
    [key in keyof T]: (
      value: string
    ) => T[key]["type"] extends "integer" | "float" ? number : T[key]["type"] extends "boolean" ? boolean : string;
  }>;
  onFinish: (values: TargetType<T>[]) => void;
  cols: T;
  onCancel: () => void;
}

export const ImportCsvForm = <T extends Column>({
  customDeserialize,
  onCancel,
  cols,
  onFinish,
}: ImportCsvFormProps<T>) => {
  const [csvData, setCsvData] = useState<CsvColumn[]>();
  const [selectedFields, setSelectedFields] = useState<FieldMatch<T>[]>([]);
  const [subTitle, setSubTitle] = useState<string>();

  const initSelection = useCallback(() => {
    const labels: string[] = [];
    setSelectedFields(
      Object.values(cols).map((elem) => {
        labels.push(elem.label.toLocaleLowerCase());
        return {
          to: elem as T[keyof T],
          from: undefined,
        };
      })
    );
    let last = labels.pop();
    setSubTitle(`${labels.length + 1} colonnes avec ${labels.join(", ")} et ${last}`);
  }, [cols]);

  useEffect(() => {
    initSelection();
  }, [initSelection]);

  const isFormValid = () => {
    return selectedFields.reduce((acc, next: FieldMatch<T>) => {
      return acc && next.from !== undefined;
    }, true);
  };

  const deserialize = (stringValue: string, type: "integer" | "string" | "boolean" | "float", key: keyof T) => {
    if (customDeserialize?.[key]) {
      return customDeserialize?.[key]?.(stringValue);
    }
    switch (type) {
      case "string":
        return stringValue;
      case "integer":
        return parseInt(stringValue, 10);
      case "float":
        return parseFloat(stringValue?.replace(",", "."));
      case "boolean":
        return stringValue === "true";
    }
  };

  const formatImportRequest = () => {
    if (!isFormValid()) return;

    let size = 0;

    let csvMap = selectedFields.reduce((acc: any, next: FieldMatch<T>) => {
      acc[next.to.label] = next.from?.values;
      size = next.from?.values.length || 0;
      return acc;
    }, {});

    let res: TargetType<T>[] = [];
    for (let i = 0; i < size; i++) {
      let entries = Object.entries(cols).map(([key, value]) => {
        let stringValue: string = csvMap[value.label][i];

        return [key as keyof T, deserialize(stringValue, value.type, key)];
      });
      res.push(Object.fromEntries(entries));
    }

    onFinish(
      res.filter((e) =>
        Object.values(e).reduce((acc, next) => {
          // remove row with incorect
          return acc && next !== "" && next !== null && next !== undefined;
        }, true)
      )
    );
  };

  const onInputFile = async (files?: FileList) => {
    let file = files?.[0];
    if (file && file.type === "text/csv") {
      let buffer = await file.arrayBuffer();
      let decoder = new TextDecoder("utf-8");
      let lines = decoder.decode(buffer);
      let content = Papa.parse(lines.trim(), {
        header: true,
      });

      let collumns = content.data.reduce((acc: any, next: any) => {
        Object.entries(next).forEach(([key, value]) => {
          (acc[key] = acc[key] || []).push(value);
        });
        return acc;
      }, {});

      setCsvData(
        Object.entries(collumns as { [key: string]: string[] }).map(([key, values]: [string, string[]]) => {
          return {
            key: key,
            values: values,
          };
        })
      );
    }
  };

  const renderCsvParsing = (csvData: CsvColumn[]) => {
    return (
      <div className="">
        <div className="rounded-3xl border-solid border-slate-300 border py-3 flex flex-col justify-center ">
          <div className="flex justify-between items-center border-b border-solid border-slate-300 p-5 text-slate-500">
            <div className="w-5/12 bg-white ">Colonne CSV</div>
            <ArrowRightIcon className="text-content-medium" height={24} width={24} />
            <div className="w-5/12">Correspondance modèle</div>
          </div>
          <div className="max-h-[500px] overflow-auto">
            {selectedFields.map(({ to }, index) => {
              return (
                <div key={`select_list_item_${index}`} className="flex justify-between items-center p-7">
                  <div className="w-5/12 bg-white">
                    <Select
                      type="single"
                      bordered
                      rounded
                      value={selectedFields.find((e) => e.to === to)?.from}
                      getKey={(item) => "select_item" + item.key}
                      options={csvData}
                      onChange={(item) => {
                        let fields = [...selectedFields];
                        let match = fields.find((e) => e.to === to) as FieldMatch<T>;
                        match.from = item;
                        setSelectedFields(fields);
                      }}
                      optionRender={(item) => item.key}
                    />
                  </div>
                  <ArrowRightIcon className="text-content-medium" height={24} width={24} />
                  <div className="w-5/12">{to.label}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full flex justify-end mt-5">
          <Button
            colorType={ColorType.Content}
            title="Confirmer"
            disabled={!isFormValid()}
            onClick={formatImportRequest}
          />
        </div>
      </div>
    );
  };

  const askCsvFile = () => {
    return (
      <FileInput
        subTitle={subTitle}
        className="w-full mt-10"
        title="Importer le fichier CSV"
        id="csv_file_input"
        onChange={onInputFile}
      />
    );
  };

  return (
    <div className="rounded-3xl h-full w-full p-5 flex justify-between flex-col max-h-[80%] overflow-auto">
      <div className="flex justify-between items-center m-5">
        <ArrowLeftIcon
          className="cursor-pointer"
          height={24}
          width={24}
          onClick={() => {
            setCsvData([]);
            initSelection();
            onCancel();
          }}
        />
        <div className="-ml-6 font-bold">Import CSV</div>
        <div></div>
      </div>
      {csvData && csvData.length > 0 ? renderCsvParsing(csvData) : askCsvFile()}
    </div>
  );
};
