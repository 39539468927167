import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { LicenseAssessmentReport, LicenseAssessmentResponse } from "../../../models/licenseAssessment";
import { NewProject, ProjectResponse, ProjectUpdate } from "../../../models/project";
import { ProjectAssessmentMeta, AssessmentMetaKind } from "./projectAssessment";
import { ProjectConfiguration } from "./projectConfiguration";

export interface ProjectFormProps {
  // may want to embed reports in LicenseAssessment
  licenseAssessments: LicenseAssessmentResponse[];
  licenseAssessmentReports: LicenseAssessmentReport[];
  project?: ProjectResponse;
  onCreate: (project: NewProject) => void;
  onUpdate: (project: ProjectUpdate) => void;
}

export const ProjectForm: FunctionComponent<ProjectFormProps> = ({
  licenseAssessmentReports,
  licenseAssessments,
  project,
  onCreate,
  onUpdate,
}) => {
  const [input, setInput] = useState<string>("");
  const [selectedAssessments, setSelectedAssessments] = useState<LicenseAssessmentResponse[]>();
  const [selectedReports, setSelectedReports] = useState<LicenseAssessmentReport[]>();
  const [assessMeta, setAssessMeta] = useState<{ [key: Uuid]: ProjectAssessmentMeta | undefined }>({});

  useEffect(() => {
    if (project) {
      const project_assessment_ids = project.assessments.map((a) => a.id);
      const project_report_ids = project.assessments.map((a) => a.report_ids).flat();

      setInput(project.name);
      setSelectedAssessments(
        licenseAssessments.filter((assess) => project_assessment_ids.includes(assess.assessment_id))
      );
      setSelectedReports(licenseAssessmentReports.filter((report) => project_report_ids.includes(report.id)));
    }
  }, [project, licenseAssessments, licenseAssessmentReports]);

  const selectedModel = useMemo(
    () => (selectedAssessments?.length ? selectedAssessments[0].model_name : undefined),
    [selectedAssessments]
  );

  const groupedAssessments = useMemo(() => {
    return licenseAssessments.reduce((acc, next) => {
      (acc[next.model_name] = acc[next.model_name] || []).push(next);
      return acc;
    }, {} as { [key: string]: LicenseAssessmentResponse[] });
  }, [licenseAssessments]);

  const isValid = () => {
    if (!project) {
      for (let assess of selectedAssessments || []) {
        if (assess.model_name === "Culture fit") {
          const meta = assessMeta[assess.id];
          if (typeof meta?.kind === "undefined" || (meta?.kind === AssessmentMetaKind.Company && !meta?.company_name) || (meta?.kind === AssessmentMetaKind.CompanyWithTeam && (!meta.company_name || !meta.reference_team_name))) return false;
        }
      }
    }

    return input.length > 0 && selectedAssessments && selectedAssessments?.length > 0;
  };

  const onFinish = () => {
    if (!isValid() || !selectedAssessments || !input) return;

    let groupedReportsIds = (selectedReports || []).reduce((acc, next) => {
      (acc[next.license_assessment_id] = acc[next.license_assessment_id] || []).push(next.id);
      return acc;
    }, {} as { [key: Uuid]: Uuid[] });

    let projectAssessments = selectedAssessments.map((assessment) => ({
      assessment_id: assessment.assessment_id,
      report_ids: groupedReportsIds[assessment.id] || [],
      company_name: assessMeta[assessment.id]?.company_name
        ? assessMeta[assessment.id]?.reference_team_name
          ? `l'équipe ${assessMeta[assessment.id]?.reference_team_name} de ${assessMeta[assessment.id]?.company_name}`
          : assessMeta[assessment.id]?.company_name
        : undefined,
    }));

    let newProject = {
      name: input,
      assessments: projectAssessments,
    };
    onCreate(newProject);
  };

  const onUpdateDone = () => {
    if (!input) return;
    onUpdate?.({
      name: input,
    });
  };

  return (
    <div className="max-w-xl overflow-auto">
      <div className="flex items-center justify-center w-full">
        <div className="text-content-darker font-semibold text-xl">Nouveau Projet</div>
      </div>
      <TextInput
        type="text"
        className="bg-white mt-10 p-5"
        placeholder={"Nouveau Projet"}
        label={"Nom"}
        value={input}
        onChange={(ev) => setInput(ev.target.value)}
      />
      <div className={project && "group relative"}>
        <div className={project && "opacity-30 pointer-events-none"}>
          <ProjectConfiguration
            groupedAssessments={groupedAssessments}
            selectedModel={selectedModel}
            licenseAssessmentReports={licenseAssessmentReports || []}
            selectedAssessments={selectedAssessments || []}
            onMetaDataChange={setAssessMeta}
            projectAssessmentMeta={assessMeta}
            selectedReports={selectedReports || []}
            onSelectedAssessmentsChange={setSelectedAssessments}
            onSelectedReportsChange={setSelectedReports}
          />
        </div>

        <div className="hidden group-hover:flex absolute w-full h-full justify-center items-center top-0">
          <div className="rounded-2xl bg-background-darker text-white p-3 text-xs">
            Vous ne pouvez pas modifier les évaluations liées à un projet déjà créé
          </div>
        </div>
      </div>
      <div className="justify-end flex mt-10">
        <Button
          title={project ? "Mettre a jour le projet" : "Créer le projet"}
          disabled={!isValid()}
          onClick={project ? onUpdateDone : onFinish}
        />
      </div>
    </div>
  );
};
