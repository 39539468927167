import { Switch, TextInput, Checkbox } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState, useEffect } from "react";
import { LicenseAssessmentReport, LicenseAssessmentResponse } from "../../../models/licenseAssessment";

export enum AssessmentMetaKind {
  Company,
  CompanyWithTeam,
}

export interface ProjectAssessmentMeta {
  reference_team_name?: string;
  company_name?: string;
  kind?: AssessmentMetaKind;
}

export interface AssessmentLineProps {
  selectedAssessments?: LicenseAssessmentResponse[];
  selectedReports?: LicenseAssessmentReport[];
  reports: LicenseAssessmentReport[];
  projectAssessmentMeta?: ProjectAssessmentMeta;
  onMetaDataChange?: (value: ProjectAssessmentMeta) => void;
  assessment: LicenseAssessmentResponse;
  onSelectedChange: (value: boolean) => void;
  onSelectedReportChange: (report: LicenseAssessmentReport, value: boolean) => void;
}

export const AssessmentLine: FunctionComponent<AssessmentLineProps> = ({
  selectedAssessments,
  selectedReports,
  reports,
  assessment,
  projectAssessmentMeta,
  onSelectedChange,
  onMetaDataChange,
  onSelectedReportChange,
}) => {
  const assessmentReports = reports.filter((r) => r.license_assessment_id === assessment.id);
  const selected = selectedAssessments?.includes(assessment) || false;

  return (
    <div className="w-full mb-1">
      <div className="flex justify-between text-xl font-bold text-content-darker w-full">
        <div>{assessment.assessment_label}</div>
        <Switch className="w-fit" value={selected} onChange={onSelectedChange} />
      </div>
      {selected && (
        <div className="my-3 flex flex-col gap-1">
          {assessment.model_name === "Culture fit" && (
            <div className="flex items-start gap-5 flex-col bg-white p-4 border border-solid rounded-lg mb-2">
              <div className="flex items-center gap-4 bg-inherit">
                <Checkbox value={projectAssessmentMeta?.kind === AssessmentMetaKind.Company} onChange={() => projectAssessmentMeta?.kind !== AssessmentMetaKind.Company && onMetaDataChange?.({ kind: AssessmentMetaKind.Company })} />
                <div>L'entreprise</div>
                <TextInput
                  // label="Nom de l'entreprise"
                  placeholder="Getpro"
                  size="small"
                  disabled={projectAssessmentMeta?.kind !== AssessmentMetaKind.Company}
                  value={projectAssessmentMeta?.kind === AssessmentMetaKind.Company ? projectAssessmentMeta?.company_name || "" : ""}
                  onChange={({ target }) => onMetaDataChange?.({ ...projectAssessmentMeta, company_name: target.value })}
                  type="text"
                />
              </div>
              <div className="flex items-center gap-4 bg-inherit">
                <Checkbox value={projectAssessmentMeta?.kind === AssessmentMetaKind.CompanyWithTeam} onChange={() => projectAssessmentMeta?.kind !== AssessmentMetaKind.CompanyWithTeam && onMetaDataChange?.({ kind: AssessmentMetaKind.CompanyWithTeam })} />
                <div>L'équipe</div>
                <TextInput
                  // label="Nom de l'équipe"
                  placeholder="Tech"
                  size="small"
                  disabled={projectAssessmentMeta?.kind !== AssessmentMetaKind.CompanyWithTeam}
                  value={projectAssessmentMeta?.reference_team_name || ""}
                  onChange={({ target }) =>
                    onMetaDataChange?.({ ...projectAssessmentMeta, reference_team_name: target.value })
                  }
                  type="text"
                />
                <div>de</div>
                <TextInput
                  // label="Nom de l'entreprise"
                  placeholder="Getpro"
                  size="small"
                  disabled={projectAssessmentMeta?.kind !== AssessmentMetaKind.CompanyWithTeam}
                  value={projectAssessmentMeta?.kind === AssessmentMetaKind.CompanyWithTeam ? projectAssessmentMeta?.company_name || "" : ""}
                  onChange={({ target }) => onMetaDataChange?.({ ...projectAssessmentMeta, company_name: target.value })}
                  type="text"
                />
              </div>
            </div>
          )}
          {assessmentReports.map((report) => {
            const selected = selectedReports?.includes(report) || false;
            return (
              <div className="flex justify-between w-full">
                <div className="ml-5">{report.name}</div>
                <Switch className="w-fit" value={selected} onChange={(v) => onSelectedReportChange(report, v)} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
