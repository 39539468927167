import { Button, ColorType, Radio, Textarea } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, CheckIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { InvitationResult, sendInvite } from "../../models/project";
import { verifyMail } from "../../utils/string";

interface MailInviteProps {
  projectId: Uuid;
  isCultureFit?: boolean;
  onClose: () => void;
}

export const InvitationForm: FC<MailInviteProps> = ({ projectId, isCultureFit, onClose }) => {
  const [mailInput, setMailInput] = useState<string>("");
  const [invitationResult, setInvitationResult] = useState<InvitationResult | null>(null);


  const [selectedPopulation, setSelectedPopulation] = useState<"candidate" | "reference" | undefined>(isCultureFit ? undefined : "candidate")

  const close = () => {
    setMailInput("");
    setInvitationResult(null);
    onClose();
  };

  const sendInvitations = () => {
    const mailList = mailInput.trim().replaceAll("\n", ",").replaceAll(" ", "").split(",");
    for (const mail of mailList) {
      if (!verifyMail(mail)) {
        toast.error(`L'adresse email ${mail} n'est pas valide`);
        return;
      }
    }


    let payload = {
      emails: mailList,
      reference_participants: selectedPopulation === "reference"
    };

    sendInvite(projectId, payload).then((res: InvitationResult) => {
      setMailInput("");
      setInvitationResult(res);
    });
  };

  const renderInviationResult = (): JSX.Element => {
    return (
      <div className="bg-white p-10 rounded-3xl w-[500px]">
        <div>
          <div className="flex justify-between items-start">
            <ArrowLeftIcon width={24} height={24} className="text-slate-400 cursor-pointer" onClick={close} />
            <div className="flex items-center flex-col -ml-6">
              <div className="p-3 rounded-full border-border-lighter border w-fit">
                <CheckIcon height={40} width={40} />
              </div>
              <div className="text-content-darker font-semibold text-xl text-center mt-5">
                {invitationResult?.sent} invitation(s) envoyée(s)
              </div>
            </div>
            <div></div>
          </div>
          {invitationResult && invitationResult.not_sent.length > 0 && (
            <div className="border border-red-500 rounded-xl overflow-hidden mt-10">
              <div className="text-red-500 flex flex-col items-center justify-center py-3 border-bottom border-red-500 bg-red-200">
                <div className="flex items-center">
                  <ExclamationCircleIcon height={18} width={18} className="mr-3" /> {invitationResult.not_sent.length}{" "}
                  doublons non envoyés
                </div>
              </div>
              <div className="text-red-600 px-5 py-3 max-h-60 overflow-auto">
                {invitationResult.not_sent.join(", ")}
              </div>
            </div>
          )}
          <div className="flex justify-end mt-10">
            <Button colorType={ColorType.Content} title="Fermer" onClick={close} />
          </div>
        </div>
      </div>
    );
  };

  if (invitationResult) {
    return renderInviationResult();
  }

  const isValid = () => {
    if (selectedPopulation === undefined) return false;

    const emails = mailInput.split(",");

    return emails.find((email) => verifyMail(email.trim())) !== undefined;
  }

  return (
    <div className="overflow-y-auto">
      <div className="rounded-3xl h-full w-full p-5 flex justify-between flex-col items-center max-w-xl">
        <div className="flex justify-between items-center m-5 w-full">
          <ArrowLeftIcon className="cursor-pointer" height={24} width={24} onClick={close} />
          <div className="-ml-6 font-bold text-xl">Envoyer des invitations</div>
          <div></div>
        </div>
        <div className="mt-10 w-3/5 text-slate-500 text-center">
          Ajoutez les emails dans la zone de texte ci-dessous. Chaque email doit être séparé par une virgule
        </div>
        {isCultureFit && <Radio className="mt-5"
          options={[
            { value: "reference", label: "Population Référence" },
            { value: "candidate", label: "Candidats" },
          ]}
          onChange={(s) => s && setSelectedPopulation(s as "reference" | "candidate")}
          optionType="button"
          light
          value={selectedPopulation}
        />}
        <div className="mt-10 w-full bg-white">
          <Textarea
            label="Emails"
            light
            placeholder="Liste des emails"
            value={mailInput}
            onChange={(value) => setMailInput(value)}
            rows={5}
          />
        </div>
        <div className="w-full flex justify-end mt-10">
          <Button title={"Envoyer"} colorType={ColorType.Content} disabled={!isValid()} onClick={sendInvitations} />
        </div>
      </div>
    </div>
  );
};
