import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";
import { AssessmentType } from "./assessment";
import { LicenseAssessmentReport } from "./licenseAssessment";
import { PaginationParams } from "./paginated";

export interface ProjectResponseAssessment {
  name: string;
  id: Uuid;
  report_ids: Uuid[];
}

export interface ProjectResponse {
  id: Uuid;
  name: string;
  created_at: string;
  total_count: number;
  started_count: number;
  finished_count: number;
  assessments: ProjectResponseAssessment[];
  total: number;
}

export interface Project {
  id: Uuid;
  name: string;
  license_id: Uuid;
  created_at: string;
  updated_at: string;
  archived: boolean;
  public_token?: string;
}

export interface NewProject {
  name: string;
  assessments: {
    assessment_id: string;
    report_ids: string[];
    company_name?: string;
  }[];
}

export interface QuickInvitePayload {
  project_data: NewProject;
  email: string;
}

export interface ProjectAssessmentsResponse {
  assessment_name: string;
  reports: LicenseAssessmentReport[];
  assessment_type: AssessmentType;
}

export interface ProjectUpdate {
  name: string;
}

export interface InvitationResult {
  sent: number;
  not_sent: string[];
}

export interface AddParticipantsPayload {
  emails: string[];
  reference_participants: boolean;
}

export const fetchProjects = (params: PaginationParams) => httpClient.req(ROUTES.FETCH_PROJECTS(params));
export const updateProject = (id: Uuid, params: ProjectUpdate) => httpClient.req(ROUTES.UPDATE_PROJECT(id, params));
export const fetchProjectAssessments = (id: Uuid) => httpClient.req(ROUTES.FETCH_PROJECT_ASSESSMENTS(id));
export const createProject = (project: NewProject) => httpClient.req(ROUTES.CREATE_PROJECT(project));
export const findProject = (id: Uuid) => httpClient.req(ROUTES.FIND_PROJECT(id));
export const sendInvite = (id: Uuid, payload: AddParticipantsPayload) => httpClient.req(ROUTES.INVITE(id, payload));
export const generatePublicToken = (id: Uuid) => httpClient.req(ROUTES.GENERATE_PUBLIC_TOKEN(id));
export const quickInvite = (payload: QuickInvitePayload) => httpClient.req(ROUTES.QUICK_INVITE(payload));
