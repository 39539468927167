import httpClient from "../services/httpClient";
import ROUTES from "../services/routes";

export enum LicenseAssessmentStatus {
  Active = "active",
  Cancelled = "cancelled",
}

export interface LicenseAssessment {
  id: Uuid;
  status: LicenseAssessmentStatus;
  assessment_id: Uuid;
  public_token?: string;
  license_id: Uuid;
  created_at: string;
}

export interface LicenseAssessmentResponse {
  id: Uuid;
  model_id: Uuid;
  model_name: string;
  assessment_id: Uuid;
  created_at: string;
  assessment_label: string;
  started_count: number;
}

export interface NewLicenseAssessment {
  status: LicenseAssessmentStatus;
  assessment_id: Uuid;
  public_token?: string;
  license_id: Uuid;
}

export interface LicenseAssessmentUpdate {
  status?: LicenseAssessmentStatus;
  public_token?: string;
}

export enum AssessmentReportTarget {
  Client = "client",
  Candidate = "candidate",
  Global = "global"
}

export interface LicenseAssessmentReport {
  id: Uuid;
  license_assessment_id: Uuid;
  report_id: Uuid;
  target?: AssessmentReportTarget[];
  assessment_id: Uuid;
  name: string;
  created_at: string;
}

export interface LicenseAssessmentReportUpdate {
  target?: AssessmentReportTarget[];
}

export interface NewLicenseAssessmentReport {
  license_assessment_id: Uuid;
  assessment_id: Uuid;
  report_id: Uuid;
  target?: AssessmentReportTarget[];
  name: string;
}

export const createLicenseAssessment = (payload: NewLicenseAssessment) =>
  httpClient.req(ROUTES.CREATE_LICENSE_ASSESSMENT(payload));
export const updateLicenseAssessment = (id: Uuid, payload: LicenseAssessmentUpdate) =>
  httpClient.req(ROUTES.UPDATE_LICENSE_ASSESSMENT(id, payload));
export const fetchLicenseAssessments = () => httpClient.req(ROUTES.FETCH_LICENSE_ASSESSMENTS());
export const fetchLicenseAssessment = (assessment_id: Uuid) =>
  httpClient.req(ROUTES.FETCH_LICENSE_ASSESSMENT(assessment_id));

export const fetchLicenseAssessmentReportsByAssessment = (licenseAssessmentId: Uuid) =>
  httpClient.req(ROUTES.FETCH_LICENSE_ASSESSMENT_REPORTS_BY_ASSESSMENT(licenseAssessmentId));
export const fetchLicenseAssessmentReports = () => httpClient.req(ROUTES.FETCH_LICENSE_ASSESSMENT_REPORTS());
export const fetchLicenseOwnedAssessmentReports = () => httpClient.req(ROUTES.FETCH_OWNED_LICENSE_ASSESSMENT_REPORTS());
export const updateLicenseAssessmentReport = (id: Uuid, data: LicenseAssessmentReportUpdate) =>
  httpClient.req(ROUTES.UPDATE_LICENSE_ASSESSMENT_REPORT(id, data));
export const createLicenseAssessmentReport = (data: NewLicenseAssessmentReport) =>
  httpClient.req(ROUTES.CREATE_LICENSE_ASSESSMENT_REPORT(data));
export const deleteLicenseAssessmentReport = (id: Uuid) => httpClient.req(ROUTES.DELETE_LICENSE_ASSESSMENT_REPORT(id));
