import { FunctionComponent, useState } from "react";
import { QuestionResponse } from "../../../models/question";
import { AssessmentVersionResponse } from "../../../models/assessment";
import { DroppableZone } from "@getprorecrutement/getpro-design";
import { QuestionsPage } from "../page";
import { unassignQuestion, deletePage, updateQuestion } from "../../../models/question";
import { PlusCircleIcon } from "@heroicons/react/outline";

// TODO INVESTIGATE POOR PERFORMENCES ON LOAD

export interface QuestionsInfoProps {
  questionsPages: QuestionResponse[][];
  assessmentVersion: AssessmentVersionResponse;
  edition?: boolean;
  onUpdate: (question: QuestionResponse) => void;
  onDelete: (question_ids: Uuid[]) => void;
  setEditingQuestion: (question: QuestionResponse) => void;
  setQuestionsPages: (questionsPages: QuestionResponse[][]) => void;
}

export const QuestionsInfo: FunctionComponent<QuestionsInfoProps> = ({
  questionsPages,
  assessmentVersion,
  edition = false,
  onUpdate,
  onDelete,
  setEditingQuestion,
  setQuestionsPages,
}) => {
  const [draggedQuestion, setDraggedQuestion] = useState<QuestionResponse>();

  const removePage = async (page: number) => {
    if (questionsPages) {
      const questions = questionsPages[page];
      if (questions) {
        const to_delete = questions.map((q) => q.id);
        await deletePage(assessmentVersion.id, page);
        onDelete(to_delete);
      }
    }
  };

  const removePageFromQuestion = async (id: Uuid) => {
    await unassignQuestion(id);
    onDelete([id]);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-8 flex-wrap justify-center">
      {questionsPages?.map((page, i) => (
        <DroppableZone
          onDrop={async () => {
            if (
              draggedQuestion &&
              draggedQuestion.page !== i + 1 &&
              page.length < (assessmentVersion?.questions_per_page || 0)
            ) {
              const updatedQuestion = await updateQuestion({
                page: i + 1,
                id: draggedQuestion.id,
              });
              setDraggedQuestion(undefined);
              onUpdate(updatedQuestion);
            }
          }}
          key={i}
        >
          <QuestionsPage
            page={i}
            questions={page}
            edition={edition}
            questionsPerPage={assessmentVersion?.questions_per_page || 0}
            removePage={() => removePage(i)}
            onQuestionDragStart={(question) => {
              setDraggedQuestion(question);
            }}
            onQuestionDragEnd={() => {
              setDraggedQuestion(undefined);
            }}
            removeQuestion={(id) => removePageFromQuestion(id)}
            onEditQuestion={(question) => setEditingQuestion(question)}
          />
        </DroppableZone>
      ))}
      {!assessmentVersion.published && (
        <div
          className="p-8 rounded-lg border border-dashed border-border-lighter flex items-center justify-center cursor-pointer min-h-[300px]"
          onClick={() => setQuestionsPages([...questionsPages, []])}
        >
          <div className="text-content-lighter">
            <div className="flex justify-center mb-2">
              <PlusCircleIcon width={34} height={34} />
            </div>
            <div className="text-base font-semibold">Ajouter une page</div>
          </div>
        </div>
      )}
    </div>
  );
};
