import { Modal, TextInput, Tooltip } from "@getprorecrutement/getpro-design";
import { FolderIcon, SearchIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OmnisearchResult, search as omnisearch } from "../models/omnisearch";
import { ParticipantResponse } from "../models/particiapnt";
import { ProjectResponse } from "../models/project";
import store, { Stored } from "../services/store";

const useDebounce = (value: string | undefined, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, value]);

  return debouncedValue;
};

export const Omnisearch: FunctionComponent = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const navigate = useNavigate();

  const onNavigate = (url: string) => {
    setOpened(false);
    navigate(url);
  };

  return (
    <div>
      <div
        className="rounded-3xl  border px-5 items-center py-2 flex justify-start gap-2 text-content-dark bg-white cursor-pointer"
        onClick={() => {
          setOpened(true);
        }}
      >
        <SearchIcon height={19} width={19} />
      </div>

      <Modal
        className="min-w-[800px] min-h-[90vh] overflow-auto h-full"
        show={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <OmnisearchForm onNavigate={onNavigate} />
      </Modal>
    </div>
  );
};

export const OmnisearchProjectItem: FunctionComponent<{
  project: ProjectResponse;
  onNavigate: (url: string) => void;
}> = ({ project, onNavigate }) => {
  return (
    <div
      className="flex border items-center justify-between rounded-3xl py-3 px-6 cursor-pointer"
      onClick={() => onNavigate(`/projects/${project.id}`)}
    >
      <div className="w-2/5">
        <div className="text-lg font-bold text-content-darker">{project.name}</div>
        <div className="flex gap-2 mt-1">
          {project.assessments.slice(0, 2).map((a) => (
            <div
              key={`${project.id}-${a.id}`}
              className="truncate py-1 px-2 bg-background-light rounded-full text-content-darker text-xs font-semibold"
              title={a.name}
            >
              {a.name}
            </div>
          ))}
        </div>
      </div>
      <div className="w-1/2">
        <div className="w-full relative mb-2 h-1.5 rounded-full">
          <div
            className="h-1.5 absolute z-[2] bg-background-dark rounded-full"
            style={{ width: `calc(${(project.finished_count * 100) / project.total_count}%)` }}
          />
          <div
            className="h-1.5 absolute z-[1] bg-background-light rounded-full"
            style={{ width: `calc(${(project.started_count * 100) / project.total_count}%)` }}
          />
          <div className="h-1.5 absolute w-full bg-slate-200 rounded-full" />
        </div>
        <div className="flex justify-center gap-4 text-content-darker text-xs">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-background-dark mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.finished_count}</span>
            <span>Terminée(s)</span>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 bg-background-light mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.started_count - project.finished_count}</span>
            <span>En cours</span>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 bg-slate-200 mr-2 rounded-full" />
            <span className="font-bold mr-2">{project.total_count}</span>
            <span>Envoyée(s)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OmnisearchParticipantItem: FunctionComponent<{
  participant: ParticipantResponse;
  onNavigate: (url: string) => void;
}> = ({ participant, onNavigate }) => {
  const fullName = participant.first_name ? `${participant.first_name} ${participant.last_name}` : "-";
  const filteredProjects = participant.projects.filter((p) => !p.hidden);
  return (
    <div
      className="flex items-center px-6 cursor-pointer"
      onClick={() => onNavigate(`/participants/${participant.id}`)}
    >
      <div className="w-2/5">
        <div className="text-lg font-semibold text-content-darker truncate" title={fullName}>
          {fullName}
        </div>
        <div>{participant.email}</div>
      </div>
      <div className="w-1/5 flex justify-center">
        {filteredProjects.length > 0 ? (
          <Tooltip
            position="bottom"
            dark
            customRenderer={() => (
              <div className="flex flex-col min-w-[200px] gap-3 max-w-min">
                {filteredProjects.map((p) => {
                  const classes = classNames("font-semibold text-right", {
                    "text-emerald-400": !!p.finished_at,
                    "text-blue-400": !!p.started_at && !p.finished_at,
                    "text-content-lighter": !p.started_at && !p.finished_at,
                  });
                  return (
                    <div className="flex gap-2 justify-between items-center" key={`${participant.id}-${p.id}`}>
                      <div className="w-3/5 truncate text-white font-semibold" title={p.name}>
                        {p.name}
                      </div>
                      <div className="w-2/5">
                        <div className={classes}>
                          {p.finished_at ? "Terminé" : p.started_at ? "En cours" : "Invité"}
                        </div>
                        <div className="text-content-regular mt-1">
                          {dayjs(p.finished_at || p.started_at || p.invited_at).format("DD MMMM YYYY")}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          >
            <div className="border rounded-lg flex px-2 py-1 gap-2">
              <FolderIcon height={24} width={24} fill="#8bccf0" className="text-primary-medium" />
              <div className="whitespace-nowrap">{`${filteredProjects.length} projet${
                filteredProjects.length > 1 ? "s" : ""
              }`}</div>
            </div>
          </Tooltip>
        ) : (
          <div></div>
        )}
      </div>

      <div className="flex gap-2 w-2/5">
        {participant.assessments?.slice(0, 2).map((assessment) => {
          const classes = classNames("rounded-full px-3 py-1 text-sm font-bold truncate max-w-[150px]", {
            "text-blue-500 bg-blue-100": !!assessment.started_at && !assessment.finished_at,
            "text-green-500 bg-green-100": !!assessment.started_at && !!assessment.finished_at,
          });

          return (
            <div key={`${participant.id}-${assessment.id}`} title={assessment.name} className={classes}>
              {assessment.name}
            </div>
          );
        })}
        {participant.assessments?.length > 2 && <div>...</div>}
      </div>
    </div>
  );
};

export const OmnisearchForm: FunctionComponent<{ onNavigate: (url: string) => void }> = ({ onNavigate }) => {
  const [input, setInput] = useState<string>("");
  const search = useDebounce(input, 100);
  const [projects, setProjects] = useState<ProjectResponse[]>();
  const [participants, setParticipants] = useState<ParticipantResponse[]>();
  const [history, setHistory] = useState<OmnisearchResult[] | undefined>(store.state.OmniSearchHistory);

  useEffect(() => {
    if (search) {
      omnisearch(search).then((results) => {
        const { projects, participants } = results.reduce((acc, res) => {
          if (res.participant) {
            (acc.participants = acc.participants || []).push(res.participant);
          } else if (res.project) {
            (acc.projects = acc.projects || []).push(res.project);
          }
          return acc;
        }, {} as { projects: ProjectResponse[]; participants: ParticipantResponse[] });
        setParticipants(participants);
        setProjects(projects);
      });
    } else {
      setParticipants(undefined);
      setProjects(undefined);
    }
  }, [search]);

  useEffect(() => store.listen(Stored.OmniSearchHistory, setHistory), []);

  const onClick = (url: string, item: OmnisearchResult) => {
    store.pushOmnisearchHistory(item);
    onNavigate(url);
  };

  return (
    <div className="h-full overflow-auto">
      <div className="flex justify-start items-center w-full">
        <SearchIcon className="text-content-medium w-fit" height={24} width={24} />
        <div className="w-full">
          <TextInput
            autoFocus={true}
            type="text"
            value={input}
            tabIndex={0}
            bordered={false}
            className="w-full"
            onChange={({ target }) => setInput(target.value)}
            placeholder="Recherche"
          />
        </div>
      </div>
      {participants?.length && (
        <div className="flex flex-col gap-3 mt-5">
          <div className="text-content-regular text-xs ml-6">Participants</div>
          {participants.map((p) => (
            <OmnisearchParticipantItem
              key={p.id}
              onNavigate={(url) => onClick(url, { participant: p })}
              participant={p}
            />
          ))}
        </div>
      )}
      {projects?.length && (
        <div className="flex flex-col gap-3 mt-5">
          <div className="text-content-regular text-xs ml-6">Projets</div>
          {projects.map((p) => (
            <OmnisearchProjectItem key={p.id} onNavigate={(url) => onClick(url, { project: p })} project={p} />
          ))}
        </div>
      )}
      {!search && history && (
        <div className="flex flex-col gap-3 mt-5">
          <div className="text-content-regular text-xs ml-6">Historique de recherche</div>
          {history.map((h) => {
            const onNavigate = (url: string) => onClick(url, h);
            return h.participant ? (
              <OmnisearchParticipantItem key={h.participant.id} onNavigate={onNavigate} participant={h.participant} />
            ) : (
              <OmnisearchProjectItem key={h.project!.id} onNavigate={onNavigate} project={h.project!} />
            );
          })}
        </div>
      )}
    </div>
  );
};
