import { Button, ColorType, FileInput, Select, Textarea } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon, XCircleIcon } from "@heroicons/react/outline";
import { FunctionComponent, useEffect, useState } from "react";
import { SupportRequestBody, SupportRequestKind } from "../../models/support";

export interface SupportFormProps {
  onCancel: () => void;
  onFinish: (content: SupportRequestBody, file?: File) => void;
}

export const SupportForm: FunctionComponent<SupportFormProps> = ({ onCancel, onFinish }) => {
  const [textInput, setTextInput] = useState<string>();
  const [file, setFile] = useState<FileList>();
  const [loading, setLoading] = useState<boolean>(false);
  const [kind, setKind] = useState<SupportRequestKind>();

  useEffect(() => {
    setLoading(false);
  }, []);

  const _onFinish = () => {
    if (!(textInput && textInput.length > 0 && kind !== undefined)) return;
    let content = { text: textInput, kind };
    setLoading(true);
    onFinish(content, file?.item(0) || undefined);
  };

  const label = (kind: SupportRequestKind): string => {
    switch (kind) {
      case SupportRequestKind.Other:
        return "Autre";
      case SupportRequestKind.ScienceRequest:
        return "Compréhension des rapports";
      case SupportRequestKind.TechRequest:
        return "Problème technique";
    }
  };

  const isFormValid = () => {
    return textInput && textInput.length > 0 && kind !== undefined;
  };

  return (
    <div className="px-5 max-w-xl overflow-auto">
      <div className="flex justify-between w-full ">
        <ArrowLeftIcon height={24} width={24} onClick={onCancel} />
        <div className="text-xl text-primary-darker font-bold">Contacter le support</div>
        <div></div>
      </div>
      <div className="my-10 text-content-dark">
        <div className="text-center">
          Si vous rencontrez un problème avec la solution ou avec la compréhension des résultats, n'hésitez pas à
          contacter notre équipe. Nous vous répondrons dans les meilleurs délais.
        </div>
        <div className="text-center mt-5">
          Si cela concerne un bug technique, merci de nous joindre une capture d'écran.
        </div>
      </div>
      <Select
        className="my-5 bg-white"
        light
        bordered
        rounded
        label="Type de requête"
        type="single"
        value={kind}
        options={[SupportRequestKind.TechRequest, SupportRequestKind.ScienceRequest, SupportRequestKind.Other]}
        getKey={(e) => e as string}
        onChange={setKind}
        optionRender={label}
      />
      <Textarea placeholder="Écrivez votre message" rows={5} light onChange={setTextInput} />

      {file === undefined ? (
        <FileInput
          title="Importer le fichier"
          id="support_attachment"
          className="h-[80px] flex-row justify-center gap-5 mt-5"
          value={file}
          onChange={(f) => setFile(f || undefined)}
        />
      ) : (
        <div className="flex w-full justify-center gap-3 items-center mt-5">
          <XCircleIcon height={20} width={20} className="cursor-pointer" onClick={() => setFile(undefined)} />
          <div>{file.item(0)?.name}</div>
        </div>
      )}
      <div className="w-full flex justify-end mt-5">
        <Button
          disabled={!isFormValid()}
          loading={loading}
          colorType={ColorType.Content}
          title="Envoyer"
          onClick={_onFinish}
        />
      </div>
    </div>
  );
};
