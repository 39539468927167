import { Button, ColorType, Radio, Select, Tag, TagColor, TextInput } from "@getprorecrutement/getpro-design";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import { AssessmentType, NewAssessmentWithVersion } from "../../models/assessment";
import { NewVersionForm } from "./newVersionForm";

interface NewAssessmentFormProps {
  onCancel: () => void;
  modelId?: Uuid; // only used to refresh loading (could find a better way)
  onFinish: (newAssessmentData: NewAssessmentWithVersion) => void;
}

const NewAssessmentForm: FC<NewAssessmentFormProps> = ({ onCancel, onFinish, modelId }): JSX.Element => {
  const [assessmentName, setAssessmentName] = useState<string>();
  const [assessmentLabel, setAssessmentLabel] = useState<string>();

  return (
    <div className="p-10 overflow-auto" style={{ width: 500 }}>
      <div className="flex items-center justify-between">
        <ArrowLeftIcon className="text-slate-400 cursor-pointer" height={24} width={24} onClick={onCancel} />
        <div className="font-semibold text-content-darker -ml-6 text-xl">Nouvelle évaluation</div>
        <div></div>
      </div>
      <div className="w-full flex flex-col items-center justify-center mt-10 bg-white">
        <div className="flex items-center justify-center bg-inherit">
          <TextInput
            light
            type="text"
            value={assessmentName}
            inputClassName="rounded-r-none"
            label="Nom de l'évaluation"
            placeholder="Nom de l'évaluation"
            onChange={({ target }) => {
              setAssessmentName(target.value);
            }}
          />
          <TextInput
            light
            type="text"
            value={assessmentLabel}
            label="Label de l'évaluation"
            placeholder="Label de l'évaluation"
            onChange={({ target }) => {
              setAssessmentLabel(target.value);
            }}
            inputClassName="rounded-l-none"
          />
        </div>

        <Tag type={TagColor.Success} rounded={true} size="small" value="version 1" className="mt-10" />
        <NewVersionForm onCancel={onCancel} label="Créer l'évaluation" onFinish={(data) => {
          if (!assessmentName || !assessmentLabel) return
          console.log(assessmentLabel, assessmentName)
          onFinish({

            label: assessmentLabel,
            name: assessmentName,
            ...data

          })
        }} />

      </div>
    </div>
  );
};

export default NewAssessmentForm;
